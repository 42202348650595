import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";

import { allRoutes } from "routes";
import { errorAlert, getImageUrl } from "utils/helpers";
import { DRAFT, PUBLISHED, SOMETHING_WENT_WRONG } from "utils/constants";
import { getEventDetailsAPI } from "api/event";
import ManageEventForm from "components/forms/ManageEventForm";
import ManageInterestForm from "components/forms/ManageInterestForm";
import ManageFAQForm from "components/forms/ManageFAQForm";
import {
  updateEventAction,
  updateEventFormModeAction,
} from "stores/actions/eventAction";

const UpdateEvent = () => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.eventReducer.event);
  const formMode = useSelector((state) => state.eventReducer.formMode);

  // states
  const [loading, setLoading] = useState(true);

  // variables
  const eventId = location?.state?.eventId;

  useEffect(() => {
    getEventDetails(eventId);
    return () => {
      // remove event in redux
      dispatch(updateEventAction(null));
      dispatch(updateEventFormModeAction(""));
    };
  }, [eventId]);

  const getEventDetails = async (eventId) => {
    try {
      if (!eventId) {
        // no event id, go to event page
        navigate(allRoutes.events.index.path);
      }

      setLoading(true);

      // get event details
      const getEventRes = await getEventDetailsAPI(eventId);
      if (getEventRes.code !== 200) {
        throw new Error("Error while getting event details.");
      }

      let eventDetails = getEventRes.results?.event;
      eventDetails.maxAge =
        eventDetails.maxAge >= 80 ? 80 : eventDetails.maxAge;
      eventDetails.startDate = eventDetails.startDate
        ? moment(eventDetails.startDate).toDate()
        : null;
      eventDetails.endDate = eventDetails.endDate
        ? moment(eventDetails.endDate).toDate()
        : null;
      eventDetails.locationAddress = {
        description: eventDetails.locationAddress,
        geoPoint: eventDetails.geoPoint,
      };

      // handle cover image url
      if (!_.isEmpty(eventDetails.coverImage)) {
        const url = (await getImageUrl(eventDetails.coverImage.imagePath))
          .imageUrl;
        eventDetails.coverImage.url = url;
      }

      dispatch(updateEventAction(eventDetails));

      if (_.isEmpty(formMode)) {
        let mode = "";
        // determine form mode by event status
        if (eventDetails.status) {
          mode = PUBLISHED;
        } else {
          mode = DRAFT;
        }
        dispatch(updateEventFormModeAction(mode));
      }

      setLoading(false);
    } catch (error) {
      console.log("getEventDetails ~ error", error);
      setLoading(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row clearfix pb-50">
      {/* event details form */}
      <div className={`col-lg-6 col-md-12 ${loading ? "dimmer active" : ""}`}>
        {event ? <ManageEventForm formTitle="Update Event Form" /> : null}
      </div>

      {/* list of interests form */}
      {eventId && event?.startDate ? (
        <div className="col-lg-3 col-md-6">
          <ManageInterestForm />
        </div>
      ) : null}

      {/* event FAQ */}
      {formMode === PUBLISHED ? (
        <div className="col-lg-3 col-md-12">
          <ManageFAQForm />
        </div>
      ) : null}
    </div>
  );
};

export default UpdateEvent;
