import _ from "lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getAuth } from "firebase/auth";

import store from "stores";
import config from "config";
import {
  db,
  getFirestoreDoc,
  getFirestoreMultipleDocsByPagination,
} from "lib/firebase";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
  SOMETHING_WENT_WRONG,
} from "utils/constants";
import {
  capitalizeEachWord,
  errorAlert,
  adjustEventChatData,
  getEventMessageDetails,
  convertToMomentInstance,
} from "utils/helpers";
import { addEventChatMessageAction } from "stores/actions/eventChatAction";
import ChatMessageCard from "components/cards/ChatMessageCard";
import Loader from "components/Loader";
import { seenEventChatMessageAPI, sendEventChatMessageAPI } from "api/event";
import DefaultEventCover from "assets/images/default-callout-cover.png";

const auth = getAuth();
const LIMIT_DOCS = 25;

const EventChatBox = (props) => {
  // hooks
  const dispatch = useDispatch();
  const selectedChat = useSelector(
    (state) => state.eventReducer?.selectedChat || null
  );

  // refs
  const chatListenerRef = useRef(null);
  const selectedChatRef = useRef(null);
  const messagesStartRef = useRef(null);

  // states
  const [loading, setLoading] = useState(false);
  const [loadingChatMessage, setLoadingChatMessage] = useState(false);
  const [loadingPreviousChatMessages, setLoadingPreviousChatMessages] =
    useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [subscribeListener, setSubscribeListener] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [sendingMessages, setSendingMessages] = useState([]);
  const [textMessage, setTextMessage] = useState("");
  const [isListEnded, setIsListEnded] = useState(false);

  // variables
  const disabledSendButton = !textMessage?.trim();
  const user = auth.currentUser;

  useEffect(() => {
    return () => {
      console.log("UNSUBSCRIBED CHAT MESSAGE !!!!!!");
      if (typeof chatListenerRef.current === "function") {
        chatListenerRef.current();
      }
    };
  }, []);

  const loadInitialChatMessages = useCallback(async () => {
    try {
      if (!selectedChatRef.current) {
        // no selected chat, skip
        return;
      }

      setLoadingChatMessage(true);

      const currentSelectedChat = selectedChatRef.current;

      // get event chat document
      const getEventMessageRes = await getEventMessageDetails(
        currentSelectedChat
      );

      if (!getEventMessageRes.status) {
        throw new Error(SOMETHING_WENT_WRONG);
      }

      const { eventMessage } = getEventMessageRes;

      // update user details and event details to the state
      setUserDetails(eventMessage?.user);
      setEventDetails(eventMessage?.event);

      // check if event chat messages already stored in redux store
      let chatMessages =
        store.getState()?.eventChatReducer?.[currentSelectedChat];

      if (_.isEmpty(chatMessages)) {
        // chat messages not found, get event chat messge from db
        const getEventChatMessageRes =
          await getFirestoreMultipleDocsByPagination(
            `${config.COLLECTION_EVENT_MESSAGE}/${currentSelectedChat}/messages`,
            [],
            { order: ["createdAt desc"], limitDocs: LIMIT_DOCS }
          );

        if (getEventChatMessageRes === ERROR_GETTING_DOCUMENT) {
          throw new Error("Error while getting chat messages.");
        }

        if (getEventChatMessageRes !== NO_MATCHING_DOCUMENTS) {
          // add user details to the event chat messages
          chatMessages = await Promise.all(
            getEventChatMessageRes.map(async (eventChat) => {
              try {
                // get additional data
                const getAdditionalDataRes = await adjustEventChatData(
                  eventChat
                );

                if (!getAdditionalDataRes.status) {
                  throw new Error(getAdditionalDataRes.message);
                }

                return getAdditionalDataRes.eventChat;
              } catch (error) {
                console.log("getEventChatMessageRes.map ~ error:", error);
                // something went wrong, return original eventMessage data
                return {
                  ...eventChat,
                  createdAt: eventChat.createdAt.toDate(),
                };
              }
            })
          );

          // update chat messages in redux store
          dispatch(
            addEventChatMessageAction({
              [currentSelectedChat]: chatMessages,
            })
          );
        }
      }

      if (chatMessages.length < LIMIT_DOCS) {
        // end of chats
        setIsListEnded(true);
      } else {
        setIsListEnded(false);
      }

      if (typeof chatListenerRef.current === "function") {
        console.log("UNSUBSCRIBED CHAT MESSAGE @@@@@@");
        chatListenerRef.current();
        chatListenerRef.current = null;
        setSubscribing(false);
      }

      setLoadingChatMessage(false);
      setSubscribeListener(true);
      setChatMessages(chatMessages);
    } catch (error) {
      console.log("loadInitialChatList ~ error", error);
      setLoadingChatMessage(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  });

  useEffect(() => {
    if (selectedChatRef.current !== selectedChat) {
      setChatMessages([]);
      setSendingMessages([]);
      setSubscribeListener(false);

      // update current selected chat to ref
      selectedChatRef.current = selectedChat;

      // load event chat messages
      loadInitialChatMessages();
    }
  }, [selectedChat, loadInitialChatMessages]);

  useEffect(() => {
    // subscribing to listener to get real time chat message update
    if (subscribeListener === true && subscribing === false) {
      let lastChatTime;
      if (!_.isEmpty(chatMessages)) {
        lastChatTime = Timestamp.fromDate(chatMessages[0]?.createdAt);
      }

      if (!lastChatTime) {
        lastChatTime = Timestamp.fromDate(moment().toDate());
      }

      const currentSelectedChat = selectedChatRef.current;

      const q = query(
        collection(
          db,
          `${config.COLLECTION_EVENT_MESSAGE}/${currentSelectedChat}/messages`
        ),
        where("createdAt", ">", lastChatTime),
        orderBy("createdAt", "desc")
      );

      chatListenerRef.current = onSnapshot(
        q,
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(async ({ doc }) => {
            // get the chat data
            let incomingChatMessage = { ...doc.data(), id: doc.id };

            // send read event chat API
            if (!incomingChatMessage.seenBy?.includes(user.uid)) {
              seenEventChatMessageAPI(currentSelectedChat);
            }

            let allChatMessages = chatMessages;

            // check if the chat messages already contains the doc
            let chatMessageIndex = _.findIndex(allChatMessages, {
              id: incomingChatMessage.id,
            });

            if (chatMessageIndex === -1) {
              // add user details to the event chat messages
              try {
                // get additional data
                const getAdditionalDataRes = await adjustEventChatData(
                  incomingChatMessage
                );

                if (!getAdditionalDataRes.status) {
                  throw new Error(getAdditionalDataRes.message);
                }

                incomingChatMessage = getAdditionalDataRes.eventChat;
              } catch (error) {
                console.log("chatMessage subscribeListener ~ error:", error);
                // something went wrong, return original eventChat data
                incomingChatMessage.createdAt =
                  incomingChatMessage.createdAt?.toDate?.();
              }

              allChatMessages.push(incomingChatMessage);

              allChatMessages = _.orderBy(allChatMessages, "createdAt", "desc");

              setChatMessages(allChatMessages);
              // check if incoming chat is in sending message, remove the message
              _.remove(sendingMessages, {
                message: incomingChatMessage.message,
              });
              setSendingMessages([...sendingMessages]);

              setTimeout(() => {
                scrollToStartOfChat();
              }, 100);

              // update chat messages in redux store
              dispatch(
                addEventChatMessageAction({
                  [currentSelectedChat]: allChatMessages,
                })
              );
            }
          });
        },
        (error) => {
          console.log("chatMessage subscribeListener ~ error", error.message);
        }
      );

      console.log("SUBSCRIBED CHAT MESSAGE");
      setSubscribing(true);
    }
  }, [subscribeListener, subscribing]);

  const loadPreviousChatMessages = async () => {
    try {
      if (
        !selectedChatRef.current ||
        isListEnded ||
        loadingPreviousChatMessages
      ) {
        return;
      }

      console.log("LOAD PREVIOUS CHAT MESSAGES");

      setLoadingPreviousChatMessages(true);

      // get last document
      const startDocId = chatMessages[chatMessages.length - 1]?.id;

      if (!startDocId) {
        // end of list
        setLoadingPreviousChatMessages(false);
        setIsListEnded(true);
        return;
      }

      // get next doc cursor
      const startAfterDoc = await getFirestoreDoc(
        `${config.COLLECTION_EVENT_MESSAGE}/${selectedChatRef.current}/messages`,
        startDocId,
        { cursor: true }
      );

      if (startAfterDoc === ERROR_GETTING_DOCUMENT) {
        throw new Error(`Error while getting chat message next document.`);
      }

      if (startAfterDoc === NO_MATCHING_DOCUMENTS) {
        throw new Error(`Chat message next document not found.`);
      }

      // chat messages not found, get event chat messge from db
      const getEventChatMessageRes = await getFirestoreMultipleDocsByPagination(
        `${config.COLLECTION_EVENT_MESSAGE}/${selectedChatRef.current}/messages`,
        [],
        { startAfterDoc, order: ["createdAt desc"], limitDocs: LIMIT_DOCS }
      );

      if (getEventChatMessageRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting chat messages.");
      }

      let allChatMessages = chatMessages;

      if (getEventChatMessageRes !== NO_MATCHING_DOCUMENTS) {
        await Promise.all(
          getEventChatMessageRes.map(async (eventChat) => {
            try {
              // check if the chat messages already contains the doc
              let chatMessageIndex = _.findIndex(allChatMessages, {
                id: eventChat.id,
              });

              if (chatMessageIndex === -1) {
                // add user details to the event chat messages
                try {
                  // get additional data
                  const getAdditionalDataRes = await adjustEventChatData(
                    eventChat
                  );

                  if (!getAdditionalDataRes.status) {
                    throw new Error(getAdditionalDataRes.message);
                  }

                  eventChat = getAdditionalDataRes.eventChat;
                } catch (error) {
                  console.log("getEventChatMessageRes.map ~ error:", error);
                  // something went wrong, return original eventChat data
                  eventChat.createdAt = eventChat.createdAt?.toDate?.();
                }

                allChatMessages.push(eventChat);
              }
            } catch (error) {
              console.log("getEventChatMessageRes.map ~ error:", error);
              // something went wrong, return original eventMessage data
              return {
                ...eventChat,
                createdAt: eventChat.createdAt.toDate(),
              };
            }
          })
        );

        allChatMessages = _.orderBy(allChatMessages, "createdAt", "desc");

        setChatMessages(allChatMessages);

        // update chat messages in redux store
        dispatch(
          addEventChatMessageAction({
            [selectedChatRef.current]: allChatMessages,
          })
        );
      } else {
        // end of list
        setIsListEnded(true);
      }

      setLoadingPreviousChatMessages(false);
    } catch (error) {
      console.log("loadPreviousChatMessages ~ error", error);
      setLoadingPreviousChatMessages(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const renderChatMessage = (chatMessage, index) => {
    switch (chatMessage.type) {
      case "message":
        // determine the chat belonging
        const fromUser = chatMessage.from === userDetails?.id;

        let grouped = false;
        // check if the message already group to the previous message
        if (index > 0) {
          if (chatMessage.from === chatMessages[index - 1]?.from) {
            grouped = true;
          }
        }

        return (
          <ChatMessageCard
            key={chatMessage.id}
            message={chatMessage.message}
            align={fromUser ? "left" : "right"}
            user={fromUser && !grouped ? userDetails : null}
            date={chatMessage.createdAt}
          />
        );

      default:
        return null;
    }
  };

  const formatEventDate = (startDate, endDate) => {
    let day = "";
    let time = "";

    let momentStartDate = convertToMomentInstance(startDate);
    let momentEndDate = endDate ? convertToMomentInstance(endDate) : null;

    if (!momentEndDate) {
      return momentStartDate.format("dddd, Do MMMM @ h:mma");
    }

    if (momentStartDate.isSame(momentEndDate, "day")) {
      day = momentStartDate.format("dddd, Do MMMM");
    } else if (momentStartDate.isSame(momentEndDate, "month")) {
      day = `${momentStartDate.format("Do")} - ${momentEndDate.format(
        "Do MMMM"
      )}`;
    } else {
      day = `${momentStartDate.format("Do MMMM")} - ${momentEndDate.format(
        "Do MMMM"
      )}`;
    }

    time = `${momentStartDate.format("h:mma")} - ${momentEndDate.format(
      "h:mma"
    )}`;

    return `${day} @ ${time}`;
  };

  const onTextMessageChange = (event) => {
    setTextMessage(event.target.value);
  };

  const onSendClick = async (event) => {
    try {
      event.preventDefault();

      const message = textMessage?.trim();
      if (message && selectedChatRef.current) {
        // temporary id
        const chatMessageId = uuidv4();

        setTextMessage("");
        setSendingMessages([
          {
            id: chatMessageId,
            from: user.uid,
            message,
            type: "message",
            createdAt: moment().toDate(),
            seenBy: [user.uid],
          },
          ...sendingMessages,
        ]);

        // scroll to the latest chat message
        setTimeout(() => {
          scrollToStartOfChat();
        }, 100);

        // send message to user
        const sendMessageRes = await sendEventChatMessageAPI(
          selectedChatRef.current,
          message
        );

        if (sendMessageRes.code !== 200) {
          throw new Error(sendMessageRes.message);
        }
      }
    } catch (error) {
      console.log("onSendClick ~ error", error);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const onChatBoxScroll = (event) => {
    const { target } = event;

    if (
      !isListEnded &&
      target.scrollHeight + target.scrollTop - 1 <= target.clientHeight
    ) {
      // load previous chat messages
      loadPreviousChatMessages();
    }
  };

  const scrollToStartOfChat = () => {
    messagesStartRef.current?.scrollIntoView?.({ behavior: "smooth" });
  };

  if (!selectedChat) {
    return null;
  }

  return (
    <div
      className={`card dimmer ${loading || loadingChatMessage ? "active" : ""}`}
      style={styles.mainContainer}
    >
      {/* chat header */}
      <div className="dimmer-content" style={styles.headerContainer}>
        {/* chat title */}
        <span style={styles.headerTitle}>
          {userDetails?.firstName ||
          userDetails?.lastName ||
          eventDetails?.title
            ? capitalizeEachWord(
                `${userDetails?.firstName || ""} ${
                  userDetails?.lastName || ""
                } · ${eventDetails?.title || ""}`
              )
            : ""}
        </span>

        {/* chat right section */}
        <span>
          {/* info icon */}
          {!_.isEmpty(eventDetails) ? (
            <div className="dropdown">
              <i
                className="icon-info"
                data-toggle="dropdown"
                style={styles.headerIcon}
                title="About Event"
              />

              {/* info dropdown */}
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-0">
                <div style={styles.eventContainer}>
                  {/* <div className="text-center mb-10">Event Details</div> */}

                  {/* event cover image */}
                  <img
                    style={styles.eventCoverImage}
                    src={eventDetails?.coverImage?.url || DefaultEventCover}
                    alt="Event cover"
                  />

                  {/* event content */}
                  <div style={styles.eventContentContainer}>
                    {/* event title */}
                    <div style={styles.eventTitle}>{eventDetails.title}</div>

                    {/* event subtitle */}
                    <div
                      style={styles.eventSubTitle}
                    >{`${convertToMomentInstance(eventDetails.startDate).format(
                      "h:mma"
                    )} @ ${eventDetails.locationName}`}</div>

                    {/* event date */}
                    <div style={styles.eventDetailsContainer}>
                      <i
                        className="icon-clock"
                        style={styles.eventDetailsIcon}
                      />
                      <div>
                        {formatEventDate(
                          eventDetails.startDate,
                          eventDetails.endDate
                        )}
                      </div>
                    </div>

                    {/* event location */}
                    <div style={styles.eventDetailsContainer}>
                      <i className="icon-map" style={styles.eventDetailsIcon} />
                      <div>{eventDetails.locationAddress}</div>
                    </div>

                    {/* event cost */}
                    {eventDetails.cost !== "" ? (
                      <div style={styles.eventDetailsContainer}>
                        <i
                          className="icon-wallet"
                          style={styles.eventDetailsIcon}
                        />
                        <div>${eventDetails.cost?.toFixed?.(2)}</div>
                      </div>
                    ) : null}

                    {/* event maximum attendees */}
                    <div style={styles.eventDetailsContainer}>
                      <i
                        className="icon-users"
                        style={styles.eventDetailsIcon}
                      />
                      <div>{eventDetails.maxAttendees} People Maximum</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </span>
      </div>

      {/* chat messages */}
      {!loadingChatMessage ? (
        <div
          className="dimmer-content"
          style={styles.chatBoxContainer}
          onScroll={onChatBoxScroll}
        >
          {/* bottom element used for scroll to bottom */}
          <div ref={messagesStartRef} />

          {/* sending messages */}
          {!_.isEmpty(sendingMessages)
            ? sendingMessages.map(renderChatMessage)
            : null}

          {/* all chat messages */}
          {_.isArray(chatMessages) ? chatMessages.map(renderChatMessage) : null}

          {/* loading previous chat mask */}
          {loadingPreviousChatMessages ? (
            <div className="text-center mb-30">
              <Loader className="text-primary spinner-border-sm" />
            </div>
          ) : null}

          {/* end of chat text */}
          {/* {isListEnded ? (
            <div style={styles.endOfChatText}>End of chat</div>
          ) : null} */}
        </div>
      ) : null}

      {/* loading mask */}
      {loadingChatMessage ? (
        <div style={styles.loadingChatMessageContainer}>
          <Loader className="text-primary mb-10" />
          Loading...
        </div>
      ) : null}

      {/* chat input box */}
      <form
        className="dimmer-content"
        style={styles.inputContainer}
        onSubmit={onSendClick}
      >
        {/* chat typing input */}
        <input
          style={styles.input}
          type="text"
          placeholder="Type a message..."
          value={textMessage}
          onChange={onTextMessageChange}
        />

        {/* send button */}
        <i
          className={`icon-cursor ${disabledSendButton ? "" : "hover"}`}
          style={{
            ...styles.sendIcon,
            cursor: disabledSendButton ? "inherit" : "pointer",
            background: !disabledSendButton
              ? "#f68d2e"
              : "rgba(0, 0, 0, 0.125)",
          }}
          title="Send message"
          onClick={onSendClick}
        />
      </form>
    </div>
  );
};

export default EventChatBox;

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
  },
  headerTitle: {
    fontSize: "1rem",
    fontWeight: 600,
    userSelect: "none",
  },
  headerIcon: {
    color: "#f68d2e",
    cursor: "pointer",
  },
  eventCoverImage: {
    width: "100%",
    aspectRatio: 3 / 1,
    userSelect: "none",
    objectFit: "cover",
  },
  eventContainer: {
    width: 300,
  },
  eventContentContainer: {
    padding: "1rem",
    paddingBottom: "0.5rem",
    userSelect: "none",
  },
  eventTitle: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "1.2rem",
  },
  eventSubTitle: {
    fontSize: "0.9rem",
    marginBottom: "1rem",
  },
  eventDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
  },
  eventDetailsIcon: {
    marginRight: "0.5rem",
    marginTop: 4,
    width: "1rem",
  },
  chatBoxContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    flexGrow: 1,
    padding: "1rem",
    overflowY: "scroll",
  },
  loadingChatMessageContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  endOfChatText: {
    textAlign: "center",
    color: "grey",
    margin: "1rem 0",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0 1rem",
    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
  },
  input: {
    flexGrow: 1,
    border: "none",
    outline: "none",
    resize: "none",
  },
  sendIcon: {
    display: "flex",
    margin: "1rem 0 1rem 1rem",
    fontSize: "1rem",
    color: "#ffffff",
    cursor: "pointer",
    height: "2.5rem",
    justifyContent: "center",
    alignItems: "center",
    aspectRatio: "1",
    borderRadius: "2rem",
    transform: "rotateY(0deg) rotate(45deg)",
    userSelect: "none",
  },
};
