import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { allRoutes } from "routes";
import {
  capitalizeEachWord,
  errorAlert,
  getAllInterests,
  getImageUrl,
  getUserProfileDetails,
} from "utils/helpers";
import { GOING, INTERESTED } from "utils/constants";
import defaultCalloutCover from "assets/images/default-callout-cover.png";
import defaultProfileImage from "assets/images/default-user-profile.png";
import moment from "moment/moment";
import UserProfileCard from "components/cards/UserProfileCard";

const TAB_ITMES = {
  all: { id: 0, title: "All" },
  going: { id: 1, title: "Going" },
  interested: { id: 2, title: "Interested" },
};

const CalloutDetails = () => {
  // hooks
  const navigate = useNavigate();
  const callout = useSelector((state) => state.calloutReducer?.selectedCallout);
  const indexedInterests = useSelector(
    (state) => state.interestReducer?.indexedInterests
  );

  // states
  const [loading, setLoading] = useState(true);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [goingParticipants, setGoingParticipants] = useState([]);
  const [interestedParticipants, setInterestedParticipants] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [activeTab, setActiveTab] = useState(TAB_ITMES.all.id);

  // variables
  const allParticipantCount =
    goingParticipants.length + interestedParticipants.length;
  const calloutDate = callout?.date ? moment(callout?.date) : null;
  const createdBy = _.find(goingParticipants, {
    id: callout?.creator?.userId,
  });

  useEffect(() => {
    initializeCalloutDetails();
  }, []);

  const initializeCalloutDetails = async () => {
    try {
      if (_.isEmpty(callout)) {
        // callout not found, redirect to manage callout page
        return navigate(allRoutes.callouts.index.path);
      }

      const getCoverImageUrlRes = await getImageUrl(
        callout?.coverImage?.imagePath
      );
      if (!getCoverImageUrlRes.status) {
        throw new Error(getCoverImageUrlRes.message);
      }

      setCoverImageUrl(getCoverImageUrlRes.imageUrl);

      // get all interests in redux
      await getAllInterests();

      let tempGoingParticipants = [];
      let tempInterestedParticipants = [];

      // get participants profile details
      await Promise.all(
        callout.participants.map(async (participant) => {
          const getUserRes = await getUserProfileDetails(participant.userId);
          if (getUserRes.status) {
            if (participant.type === GOING) {
              tempGoingParticipants.push({ ...getUserRes.user, type: GOING });
            } else if (participant.type === INTERESTED) {
              tempInterestedParticipants.push({
                ...getUserRes.user,
                type: INTERESTED,
              });
            }
          }
        })
      );

      tempGoingParticipants = _.orderBy(tempGoingParticipants, ["firstName"]);
      tempInterestedParticipants = _.orderBy(tempInterestedParticipants, [
        "firstName",
      ]);

      setGoingParticipants(tempGoingParticipants);
      setInterestedParticipants(tempInterestedParticipants);
      setParticipants([
        ...tempGoingParticipants,
        ...tempInterestedParticipants,
      ]);

      setLoading(false);
    } catch (error) {
      console.log("initializeCalloutDetails ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const renderStatus = () => {
    if (callout.status) {
      return (
        <span className="text-success">
          <b>Active</b>
        </span>
      );
    } else if (callout.isDraft) {
      return (
        <span className="text-info">
          <b>Draft</b>
        </span>
      );
    } else {
      return (
        <span className="text-danger">
          <b>Inactive</b>
        </span>
      );
    }
  };

  const onTabClick = (index) => {
    setActiveTab(index);

    if (TAB_ITMES.all.id === index) {
      setParticipants([...goingParticipants, ...interestedParticipants]);
    } else if (TAB_ITMES.going.id === index) {
      setParticipants(goingParticipants);
    } else {
      setParticipants(interestedParticipants);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row clearfix pb-50">
      {/* callout details */}
      <div className="col-xl-4 col-lg-6 col-md-12 col-12">
        <div className="card" style={{ overflow: "hidden" }}>
          <div style={styles.imageContainer}>
            {/* cover image */}
            <img
              src={coverImageUrl || defaultCalloutCover}
              style={styles.coverImage}
              alt="User Cover"
            />
          </div>

          <div className="card-body">
            {/* callout title */}
            <h4>
              <b>{callout.title}</b>
            </h4>

            {/* time & location */}
            <div className="mb-20">
              {calloutDate ? calloutDate.format("h:mma") : null}
              {" @ "}
              {callout.locationName}
            </div>

            {/* created by */}
            {createdBy ? (
              <div style={styles.creatorProfileContainer}>
                <img
                  style={styles.creatorProfileImage}
                  src={createdBy?.profileImage?.url || defaultProfileImage}
                  alt="Creator"
                />

                <div>
                  {/* name */}
                  <div>
                    Created by
                    <b>{` ${capitalizeEachWord(
                      createdBy?.firstName
                    )} ${capitalizeEachWord(createdBy?.lastName)}`}</b>
                  </div>

                  {/* email */}
                  <small className="text-mute">{createdBy.email}</small>
                </div>
              </div>
            ) : null}

            {/* description */}
            {callout.description ? (
              <>
                <small className="text-muted">Description: </small>
                <p>{callout.description}</p>
              </>
            ) : null}

            {/* date */}
            {calloutDate ? (
              <>
                <small className="text-muted">
                  <i className="icon-clock"></i> Date:
                </small>
                <p>{calloutDate.format("dddd, Do MMMM @ h:mm:a")}</p>
              </>
            ) : null}

            {/* location */}
            {callout.locationAddress ? (
              <>
                <small className="text-muted">
                  <i className="icon-pointer"></i> Location:
                </small>
                <p>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${callout?.geoPoint?.latitude},${callout?.geoPoint?.longitude}`}
                    target="_blank"
                  >
                    <u>{callout.locationAddress}</u>
                  </a>
                </p>
              </>
            ) : null}

            {/* age range */}
            {callout.minAge && callout.maxAge ? (
              <>
                <small className="text-muted">
                  <i className="icon-emoticon-smile"></i> Age range:
                </small>
                <p>
                  {`${callout.minAge} - ${
                    callout.maxAge >= 80 ? "80+" : callout.maxAge
                  } years old`}
                </p>
              </>
            ) : null}

            {/* attendee */}
            {callout.minAttendees && callout.maxAttendees ? (
              <>
                <small className="text-muted">
                  <i className="icon-users"></i> Attendees:
                </small>
                <p>
                  {`${callout.minAttendees} - ${callout.maxAttendees} people maxmimum`}
                </p>
              </>
            ) : null}

            {/* target audience */}
            <small className="text-muted">
              <i className="icon-target"></i> Target:
            </small>
            <p>{callout.verifiedOnly ? "Verified member only" : "Everyone"}</p>

            {/* interests */}
            {callout.interestIds?.length > 0 ? (
              <>
                <small className="text-muted">
                  <i className="icon-support"></i> Interests:
                </small>
                <p>
                  {callout.interestIds?.map((interestId) => {
                    return (
                      <span
                        key={interestId}
                        className="tag tag-success mr-1 mb-1"
                      >
                        {indexedInterests[interestId]}
                      </span>
                    );
                  })}
                </p>
              </>
            ) : null}

            {/* callout status */}
            <small className="text-muted">Status:</small>
            <p className="text-capitalize">{renderStatus()}</p>
          </div>
        </div>
      </div>

      {/* participants */}
      <div className="col-xl-4 col-lg-6 col-md-12 col-12">
        <div className="card">
          {/* title */}
          <div className="card-header">
            <h3 className="card-title">Attendees</h3>
          </div>

          <div className="card-body">
            <div
              className="d-flex justify-content-between align-items-center"
              style={styles.tabContainer}
            >
              {/* tab */}
              <ul className="nav nav-tabs page-header-tab">
                {_.map(TAB_ITMES, (item) => (
                  <li key={item.id} className="nav-item">
                    <span
                      className={`nav-link ${
                        activeTab === item.id ? "active" : ""
                      }`}
                      data-toggle="tab"
                      onClick={() => onTabClick(item.id)}
                    >
                      {item.title}
                      {TAB_ITMES.all.id === item.id
                        ? ` (${allParticipantCount})`
                        : TAB_ITMES.going.id === item.id
                        ? ` (${goingParticipants.length})`
                        : ` (${interestedParticipants.length})`}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            {/* list of participants */}
            {participants.length > 0 ? (
              participants.map((participant) => {
                return (
                  <UserProfileCard
                    containerStyle={styles.participantContainer}
                    user={participant}
                  />
                );
              })
            ) : (
              <div className="text-center text-muted">No attendees</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalloutDetails;

const styles = {
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  coverImage: {
    width: "100%",
    aspectRatio: "3/1",
    backgroundColor: "#E2E2E2",
    objectFit: "cover",
  },
  creatorProfileContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "2rem 0",
  },
  creatorProfileImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: "1rem",
  },
  tabContainer: {
    marginTop: "-1rem",
    borderTop: "1px solid #e8e9e9",
  },
  participantContainer: {
    margin: "0 -20px",
    padding: "0.5rem 20px",
  },
};
