import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NotificationCard from "components/cards/NotificationCard";
import Loader from "components/Loader";
import { errorAlert, seenNotifications } from "utils/helpers";
import {
  ADMIN,
  ERROR_GETTING_DOCUMENT,
  NOTIFICATIONS_LIMIT_DOCS,
  NO_MATCHING_DOCUMENTS,
  SOMETHING_WENT_WRONG,
} from "utils/constants";
import { updateNotificationReducerAction } from "stores/actions/notificationAction";
import {
  getFirestoreDoc,
  getFirestoreMultipleDocsByPagination,
} from "lib/firebase";
import config from "config";

const Notification = (props) => {
  // hooks
  const dispatch = useDispatch();

  // states
  const [loadingMore, setLoadingMore] = useState(false);

  // variables
  const { loadingNotifications, allNotifications, isNotificationListEnded } =
    useSelector((state) => state.notificationReducer);

  useEffect(() => {
    // mark notification as seen if any
    seenNotifications();
  }, []);

  const onLoadMoreNotificationClick = async () => {
    try {
      if (loadingMore || isNotificationListEnded) {
        return;
      }

      setLoadingMore(true);

      // get last document
      const startDocId = allNotifications[allNotifications.length - 1]?.id;

      if (!startDocId) {
        // end of list
        setLoadingMore(false);
        dispatch(
          updateNotificationReducerAction({ isNotificationListEnded: true })
        );
        return;
      }

      // get next doc cursor
      const startAfterDoc = await getFirestoreDoc(
        `${config.COLLECTION_NOTIFICATION}`,
        startDocId,
        { cursor: true }
      );

      let isListEnded = false;

      if (startAfterDoc === ERROR_GETTING_DOCUMENT) {
        throw new Error(`Error while getting notification next document.`);
      }

      if (startAfterDoc === NO_MATCHING_DOCUMENTS) {
        throw new Error(`Notification next document not found.`);
      }

      const getNotificationRes = await getFirestoreMultipleDocsByPagination(
        `${config.COLLECTION_NOTIFICATION}`,
        [
          ["role", "==", ADMIN],
          ["isDeleted", "==", false],
          ["status", "==", true],
        ],
        {
          startAfterDoc,
          order: ["createdAt desc"],
          limitDocs: NOTIFICATIONS_LIMIT_DOCS,
        }
      );

      if (getNotificationRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting notifications.");
      }

      let notifications = allNotifications;

      if (getNotificationRes !== NO_MATCHING_DOCUMENTS) {
        _.forEach(getNotificationRes, (notification) => {
          notification.createdAt = notification.createdAt?.toDate?.();
          notification.updatedAt = notification.updatedAt?.toDate?.();

          // check if the notifications already contains the doc
          let notificationIndex = _.findIndex(notifications, {
            id: notification.id,
          });

          if (notificationIndex === -1) {
            notifications.push(notification);
          } else {
            notifications[notificationIndex] = notification;
          }
        });

        notifications = _.orderBy(notifications, "createdAt", "desc");
      } else {
        isListEnded = true;
      }

      dispatch(
        updateNotificationReducerAction({
          isNotificationListEnded: isListEnded,
          allNotifications: notifications,
        })
      );

      setLoadingMore(false);
    } catch (error) {
      console.log("onLoadMoreNotificationClick ~ error", error);
      setLoadingMore(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  return (
    <div className="row clearfix pb-50">
      <div
        className="col-lg-6 offset-lg-3 col-md-12"
        style={styles.mainContainer}
      >
        <div style={styles.notificationListContainer}>
          {/* loading mask */}
          {loadingNotifications ? (
            <div style={styles.loadingNotificationContainer}>
              <Loader className="text-primary mb-10" />
              Loading...
            </div>
          ) : null}

          {!loadingNotifications ? (
            <div style={styles.notificationListInnerContainer}>
              {/* list of notifications */}
              <ul className="list-unstyled feeds_widget">
                {allNotifications.map((notification) => {
                  return (
                    <NotificationCard
                      key={`${notification.id}:${notification.createdAt}`}
                      notification={notification}
                      className="mb-10"
                    />
                  );
                })}
              </ul>

              {isNotificationListEnded ? (
                <div style={styles.endOfListText}>End of notifications</div>
              ) : loadingMore ? (
                <div className="text-center">
                  <Loader className="spinner-border-sm text-primary" />
                </div>
              ) : (
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    style={styles.loadMoreText}
                    onClick={onLoadMoreNotificationClick}
                  >
                    Load more notifications
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Notification;

const styles = {
  mainContainer: {
    maxHeight: "calc(100vh - 130px)",
  },
  notificationListContainer: {
    overflow: "hidden",
    height: "100%",
  },
  loadingNotificationContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
  notificationListInnerContainer: {
    flexGrow: 1,
    overflow: "auto",
  },
  endOfListText: {
    textAlign: "center",
    color: "grey",
    margin: "1rem 0",
  },
  loadMoreText: {
    textAlign: "center",
    margin: "0.5rem 0",
  },
};
