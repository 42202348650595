import moment from "moment";

const initialState = {
  chatClock: moment().minutes(), // will update every minute, use for the component that requires to rerendering every minutes
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CHAT_CLOCK":
      return {
        ...state,
        chatClock: moment().minutes(),
      };

    default:
      return state;
  }
};

export default appReducer;
