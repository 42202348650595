import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";

import ChatCard from "components/cards/ChatCard";
import Loader from "components/Loader";
import store from "stores";
import {
  db,
  getFirestoreDoc,
  getFirestoreMultipleDocsByPagination,
} from "lib/firebase";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
  SOMETHING_WENT_WRONG,
} from "utils/constants";
import {
  errorAlert,
  getEventLastChatMessage,
  getEventMessageAdditionalData,
} from "utils/helpers";
import {
  updateAllEventChatListAction,
  updateSelectedChatEventAction,
} from "stores/actions/eventAction";
import config from "config";
import { seenEventChatMessageAPI } from "api/event";

const LIMIT_DOCS = 15;

const EventChatList = (props) => {
  // hooks
  const dispatch = useDispatch();
  const selectedChat = useSelector(
    (state) => state.eventReducer?.selectedChat || null
  );

  // states
  const [loading, setLoading] = useState(false);
  const [loadingMoreChatList, setLoadingMoreChatList] = useState(false);
  const [subscribeListener, setSubscribeListener] = useState(false);
  const [subscribing, setSubscribing] = useState(false);
  const [allEventChatList, setAllEventChatList] = useState([]);
  const [isListEnded, setIsListEnded] = useState(false);

  useEffect(() => {
    loadInitialChatList();
  }, []);

  useEffect(() => {
    // subscribing to listener to get real time chat list update
    let unsubscribe;

    if (subscribeListener === true && subscribing === false) {
      // determine the time to subscribe the listener
      let lastMessageTime;
      if (!_.isEmpty(allEventChatList)) {
        lastMessageTime = Timestamp.fromDate(
          allEventChatList?.[0]?.lastMessageTime
        );
      }

      if (!lastMessageTime) {
        lastMessageTime = Timestamp.fromDate(moment().toDate());
      }

      const q = query(
        collection(db, config.COLLECTION_EVENT_MESSAGE),
        where("status", "==", true),
        where("isExpired", "==", false),
        where("isAdminArchived", "==", false),
        where("lastMessageTime", ">", lastMessageTime),
        orderBy("lastMessageTime", "desc")
      );
      unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            const { doc } = change;
            // get the event message data
            let incomingEventMessage = { ...doc.data(), id: doc.id };
            const isChatSelected =
              store.getState().eventReducer?.selectedChat ===
              incomingEventMessage.id;

            let allEventMessages = allEventChatList;

            // check if the chat list already contains the doc
            let eventMessageIndex = _.findIndex(allEventMessages, {
              id: incomingEventMessage.id,
            });

            if (eventMessageIndex === -1) {
              // get new event message, get user details
              try {
                // get additional data
                const getAdditionalDataRes =
                  await getEventMessageAdditionalData({
                    userId: incomingEventMessage.userId,
                    eventId: incomingEventMessage.eventId,
                    eventMessageId: incomingEventMessage.id,
                  });

                if (!getAdditionalDataRes.status) {
                  throw new Error(getAdditionalDataRes.message);
                }

                // add user details to event message
                incomingEventMessage.user = getAdditionalDataRes.user;
                incomingEventMessage.event = getAdditionalDataRes.event;
                incomingEventMessage.lastMessageTime =
                  incomingEventMessage.lastMessageTime.toDate();

                if (getAdditionalDataRes.lastChat) {
                  incomingEventMessage.viewed =
                    isChatSelected ||
                    determineViewedStatus(
                      getAdditionalDataRes.lastChat?.seenBy,
                      incomingEventMessage.userId
                    );
                }
              } catch (error) {
                console.log("getEventChatListRes.map ~ error:", error);
                // something went wrong, return original eventMessage data
                incomingEventMessage.lastMessageTime =
                  incomingEventMessage.lastMessageTime.toDate();
              }

              allEventMessages.push(incomingEventMessage);
            } else {
              // get last chat message
              const getLastChatRes = await getEventLastChatMessage(
                incomingEventMessage.id
              );

              if (getLastChatRes.status) {
                incomingEventMessage.viewed =
                  isChatSelected ||
                  determineViewedStatus(
                    getLastChatRes.chatMessage?.seenBy,
                    incomingEventMessage.userId
                  );
              } else {
                console.log(
                  "Error get last chat message",
                  getLastChatRes.message
                );
              }

              // event message exist, update event message data
              allEventMessages[eventMessageIndex] = {
                ...allEventMessages[eventMessageIndex],
                ...incomingEventMessage,
                lastMessageTime: incomingEventMessage.lastMessageTime.toDate(),
              };
            }

            allEventMessages = _.orderBy(
              allEventMessages,
              "lastMessageTime",
              "desc"
            );

            setAllEventChatList(allEventMessages);

            // update all chat list in redux store
            dispatch(updateAllEventChatListAction(allEventMessages));
          });
        },
        (error) => {
          console.log("subscribeListener ~ error", error.message);
        }
      );

      setSubscribing(true);

      // select the latest chat if none of them selected
      if (!selectedChat && allEventChatList.length > 0) {
        onChatCardClick(allEventChatList[0]);
      }
    }

    return () => {
      console.log("UNSUBSCRIBED");
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [subscribeListener]);

  const loadInitialChatList = async () => {
    try {
      setLoading(true);

      // check if event chat list already stored in redux store
      let allEventChatList = store.getState()?.eventReducer?.allEventChatList;

      if (_.isEmpty(allEventChatList)) {
        // get event chat list
        const getEventChatListRes = await getFirestoreMultipleDocsByPagination(
          config.COLLECTION_EVENT_MESSAGE,
          [
            ["status", "==", true],
            ["isExpired", "==", false],
            ["isAdminArchived", "==", false],
          ],
          { order: ["lastMessageTime desc"], limitDocs: LIMIT_DOCS }
        );

        if (getEventChatListRes === ERROR_GETTING_DOCUMENT) {
          throw new Error("Error while getting chats.");
        }

        if (getEventChatListRes !== NO_MATCHING_DOCUMENTS) {
          // add user details to the event chat lists
          allEventChatList = await Promise.all(
            getEventChatListRes.map(async (eventMessage) => {
              try {
                // get additional data
                const getAdditionalDataRes =
                  await getEventMessageAdditionalData({
                    userId: eventMessage.userId,
                    eventId: eventMessage.eventId,
                    eventMessageId: eventMessage.id,
                  });

                if (!getAdditionalDataRes.status) {
                  throw new Error(getAdditionalDataRes.message);
                }

                // add user details to event message
                eventMessage.user = getAdditionalDataRes.user;
                eventMessage.event = getAdditionalDataRes.event;

                if (getAdditionalDataRes.lastChat) {
                  eventMessage.viewed = determineViewedStatus(
                    getAdditionalDataRes.lastChat?.seenBy,
                    eventMessage.userId
                  );
                }

                return {
                  ...eventMessage,
                  lastMessageTime: eventMessage.lastMessageTime.toDate(),
                };
              } catch (error) {
                console.log("getEventChatListRes.map ~ error:", error);
                // something went wrong, return original eventMessage data
                return {
                  ...eventMessage,
                  lastMessageTime: eventMessage.lastMessageTime.toDate(),
                };
              }
            })
          );

          // update all chat list in redux store
          dispatch(updateAllEventChatListAction(allEventChatList));
        }
      }

      if (allEventChatList.length < LIMIT_DOCS) {
        // end of documents
        setIsListEnded(true);
      }

      setLoading(false);
      setSubscribeListener(true);
      setAllEventChatList(allEventChatList);
    } catch (error) {
      console.log("loadInitialChatList ~ error", error);
      setLoading(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const loadMoreChatList = async () => {
    try {
      if (!isListEnded && !loadingMoreChatList) {
        setLoadingMoreChatList(true);

        // get last document
        const startDocId = allEventChatList[allEventChatList.length - 1]?.id;

        if (!startDocId) {
          // end of list
          setLoadingMoreChatList(false);
          setIsListEnded(true);
          return;
        }

        // get next doc cursor
        const startAfterDoc = await getFirestoreDoc(
          config.COLLECTION_EVENT_MESSAGE,
          startDocId,
          { cursor: true }
        );

        if (startAfterDoc === ERROR_GETTING_DOCUMENT) {
          throw new Error(`Error while getting next document.`);
        }

        if (startAfterDoc === NO_MATCHING_DOCUMENTS) {
          throw new Error(`Next document not found.`);
        }

        // get event chat list
        const getEventChatListRes = await getFirestoreMultipleDocsByPagination(
          config.COLLECTION_EVENT_MESSAGE,
          [
            ["status", "==", true],
            ["isExpired", "==", false],
            ["isAdminArchived", "==", false],
          ],
          {
            startAfterDoc,
            order: ["lastMessageTime desc"],
            limitDocs: LIMIT_DOCS,
          }
        );

        if (getEventChatListRes === ERROR_GETTING_DOCUMENT) {
          throw new Error("Error while getting chats.");
        }

        let allEventMessages = allEventChatList;

        if (getEventChatListRes !== NO_MATCHING_DOCUMENTS) {
          // add user details to the event chat lists
          await Promise.all(
            getEventChatListRes.map(async (eventMessage) => {
              try {
                // check if the chat list already contains the doc
                let eventMessageIndex = _.findIndex(allEventMessages, {
                  id: eventMessage.id,
                });

                if (eventMessageIndex === -1) {
                  // get new event message, get user details
                  try {
                    // get additional data
                    const getAdditionalDataRes =
                      await getEventMessageAdditionalData({
                        userId: eventMessage.userId,
                        eventId: eventMessage.eventId,
                        eventMessageId: eventMessage.id,
                      });

                    if (!getAdditionalDataRes.status) {
                      throw new Error(getAdditionalDataRes.message);
                    }

                    // add user details to event message
                    eventMessage.user = getAdditionalDataRes.user;
                    eventMessage.event = getAdditionalDataRes.event;
                    eventMessage.lastMessageTime =
                      eventMessage.lastMessageTime?.toDate();

                    if (getAdditionalDataRes.lastChat) {
                      eventMessage.viewed = determineViewedStatus(
                        getAdditionalDataRes.lastChat?.seenBy,
                        eventMessage.userId
                      );
                    }
                  } catch (error) {
                    console.log("getEventChatListRes.map ~ error:", error);
                    // something went wrong, return original eventMessage data
                    eventMessage.lastMessageTime =
                      eventMessage.lastMessageTime?.toDate();
                  }

                  allEventMessages.push(eventMessage);
                } else {
                  // get last chat message
                  const getLastChatRes = await getEventLastChatMessage(
                    eventMessage.id
                  );

                  if (getLastChatRes.status) {
                    eventMessage.viewed = determineViewedStatus(
                      getLastChatRes.chatMessage?.seenBy,
                      eventMessage.userId
                    );
                  } else {
                    console.log(
                      "Error get last chat message",
                      getLastChatRes.message
                    );
                  }

                  // event message exist, update event message data
                  allEventMessages[eventMessageIndex] = {
                    ...allEventMessages[eventMessageIndex],
                    ...eventMessage,
                    lastMessageTime: eventMessage.lastMessageTime?.toDate(),
                  };
                }
              } catch (error) {
                console.log("getEventChatListRes.map ~ error:", error);
              }
            })
          );

          allEventMessages = _.orderBy(
            allEventMessages,
            "lastMessageTime",
            "desc"
          );

          setAllEventChatList(allEventMessages);

          // update all chat list in redux store
          dispatch(updateAllEventChatListAction(allEventMessages));
        } else {
          // end of list
          setIsListEnded(true);
        }

        setLoadingMoreChatList(false);
      }
    } catch (error) {
      console.log("loadMoreChatList ~ error", error);
      setLoadingMoreChatList(false);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const determineViewedStatus = (seenBy, userId) => {
    if (seenBy.length > 0) {
      return seenBy?.length > 1 || !seenBy.includes(userId);
    }
    return true;
  };

  const onChatCardClick = async (eventMessage) => {
    const eventMessageId = eventMessage.id;

    dispatch(updateSelectedChatEventAction(eventMessageId));

    // update seen chat message status by admin
    if (!eventMessage.viewed) {
      seenEventChatMessageAPI(eventMessageId);
    }

    // update viewed status locally
    const eventMessageIndex = _.findIndex(allEventChatList, {
      id: eventMessageId,
    });

    if (eventMessageIndex !== -1) {
      allEventChatList[eventMessageIndex].viewed = true;
      setAllEventChatList([...allEventChatList]);
    }
  };

  return (
    <div className="card" style={styles.sideChatContainer}>
      <div style={styles.chatListContainer}>
        {/* list of chat messages */}
        {allEventChatList.map((eventMessage) => {
          return (
            <ChatCard
              key={`${eventMessage.id}:${eventMessage.lastMessageTime}`}
              selected={selectedChat === eventMessage.id}
              user={eventMessage.user}
              event={eventMessage.event}
              eventMessage={eventMessage}
              onClick={() => onChatCardClick(eventMessage)}
            />
          );
        })}

        {isListEnded ? (
          <div style={styles.endOfListText}>End of event messages</div>
        ) : null}
      </div>

      {/* list footer */}
      {!loading ? (
        <div style={styles.chatListFooterContainer}>
          {!isListEnded ? (
            <button
              className="btn btn-outline-primary"
              style={styles.loadMoreButton}
              disabled={loadingMoreChatList}
              onClick={loadMoreChatList}
            >
              {loadingMoreChatList ? (
                <Loader className="mr-10 spinner-border-sm" />
              ) : null}
              Load more
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default EventChatList;

const styles = {
  sideChatContainer: {
    overflow: "hidden",
    height: "100%",
  },
  chatListContainer: {
    flexGrow: 1,
    overflow: "auto",
  },
  chatListFooterContainer: {
    width: "100%",
    padding: "1rem",
    textAlign: "center",
  },
  endOfListText: {
    textAlign: "center",
    color: "grey",
    margin: "1rem 0",
  },
  loadMoreButton: {
    width: "100%",
  },
};
