import React from "react";

const CollapseCard = (props) => {
  // variables
  const { title, active, expanded, onClick, children } = props;

  return (
    <div className="mb-2">
      <div
        className="collapsed-card-container hover"
        style={{
          ...styles.mainContainer,
          backgroundColor: active ? "#f68d2e" : "#e8e9e9",
        }}
        onClick={onClick}
      >
        {/* card title */}
        <span
          className={active ? "text-white" : "text-black"}
          style={styles.title}
        >
          {title}
        </span>

        {/* triangle icon */}
        <i
          className={`fa fa-caret-${expanded ? "left" : "down"}`}
          style={styles.icon}
        />
      </div>

      {/* card content */}
      {expanded ? <div style={styles.contentContainer}>{children}</div> : null}
    </div>
  );
};

export default CollapseCard;

const styles = {
  mainContainer: {
    display: "flex",
    padding: "0.375rem 0.75rem",
    backgroundColor: "#f68d2e",
    borderRadius: "0.5rem",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "0.9rem",
  },
  icon: {
    color: "white",
    fontSize: "1.5rem",
  },
  contentContainer: {
    margin: "0 0.5rem",
    borderRadius: "0 0 0.5rem 0.5rem",
    padding: "0.375rem 0.75rem",
    backgroundColor: "#F2F2F2",
  },
};
