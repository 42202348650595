import _ from "lodash";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import Multiselect from "multiselect-react-dropdown";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  capitalizeEachWord,
  convertHtmlToDraft,
  errorAlert,
  getAllAdminUsers,
} from "utils/helpers";
import {
  ACCEPTED_IMAGE_TYPES,
  DATE_PICKER_FORMAT,
  LIBRARY_IMAGE,
  IMAGE_SIZE_LIMIT,
  LOCAL_IMAGE,
  SOMETHING_WENT_WRONG,
  EVENT_VOLUNTEER_TYPE,
  EVENT_GENERAL_TYPE,
  EDITOR_STYLE,
  EDITOR_LABELS,
  TOOLBAR,
  DRAFT,
  NEW,
} from "utils/constants";
import ImageUpload from "components/ImageUpload";
import ImageLibraryModal from "components/modals/ImageLibraryModal";
import AutoCompleteAddress from "components/inputFields/AutoCompleteAddress";
import DualSlider from "components/DualSlider";
import { manageEventDetailsAPI } from "api/event";
import { useDispatch, useSelector } from "react-redux";
import { updateEventAction } from "stores/actions/eventAction";

const INITIAL_STATES = {
  loading: false,
  formModified: false,
  eventId: "",
  image: null,
  title: "",
  type: EVENT_GENERAL_TYPE,
  assignedUserIds: [],
  locationName: "",
  locationAddress: null,
  startDate: null,
  endDate: null,
  cost: "",
  minAge: 20,
  maxAge: 35,
  maxAttendees: "",
  description: "",
  basicDescription: EditorState.createEmpty(),
  premiumDescription: EditorState.createEmpty(),
  showImageLibraryModal: false,
  errors: {},
};

const ManageEventForm = (props) => {
  // hooks
  const dispatch = useDispatch();
  const resetEventForm = useSelector(
    (state) => state.formReducer?.resetEventForm
  );
  const event = useSelector((state) => state.eventReducer.event);
  const formMode = useSelector((state) => state.eventReducer.formMode);

  // states
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(INITIAL_STATES.loading);
  const [formModified, setFormModified] = useState(INITIAL_STATES.formModified);
  const [eventId, setEventId] = useState(INITIAL_STATES.eventId);
  const [image, setImage] = useState(INITIAL_STATES.image);
  const [title, setTitle] = useState(INITIAL_STATES.title);
  const [type, setType] = useState(INITIAL_STATES.type);
  const [allAdminUsers, setAllAdminUsers] = useState(
    INITIAL_STATES.assignedUserIds
  );
  const [assignedUserIds, setAssignedUserIds] = useState(
    INITIAL_STATES.assignedUserIds
  );
  const [locationName, setLocationName] = useState(INITIAL_STATES.locationName);
  const [locationAddress, setLocationAddress] = useState(
    INITIAL_STATES.locationAddress
  );
  const [startDate, setStartDate] = useState(INITIAL_STATES.startDate);
  const [endDate, setEndDate] = useState(INITIAL_STATES.endDate);
  const [cost, setCost] = useState(INITIAL_STATES.cost);
  const [minAge, setMinAge] = useState(INITIAL_STATES.minAge);
  const [maxAge, setMaxAge] = useState(INITIAL_STATES.maxAge);
  const [maxAttendees, setMaxAttendees] = useState(INITIAL_STATES.maxAttendees);
  const [description, setDescription] = useState(INITIAL_STATES.description);
  const [basicDescription, setBasicDescription] = useState(
    INITIAL_STATES.basicDescription
  );
  const [premiumDescription, setPremiumDescription] = useState(
    INITIAL_STATES.premiumDescription
  );
  const [showImageLibraryModal, setShowImageLibraryModal] = useState(
    INITIAL_STATES.showImageLibraryModal
  );
  const [errors, setErrors] = useState(INITIAL_STATES.errors);

  // variables
  const disabled = loading;
  const {
    formDisabled = false,
    formTitle = "Event Form",
    onSuccess = () => {},
  } = props;
  const minStartDate = moment().startOf("D").add({ day: 1 }).toDate();
  const minEndDate = moment().isBefore(moment(startDate || minStartDate))
    ? startDate || minStartDate
    : moment().startOf("D").add({ day: 1 }).toDate();
  const disabledStartDate = !!startDate && moment(startDate).isBefore(moment());

  useEffect(() => {
    loadAdminUsers();

    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (formModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    loadInitialInformation();
  }, [event?.id]);

  // reset state to initial states
  useEffect(() => {
    if (initialLoading) {
      // skip reseting the states
      setInitialLoading(false);
    } else {
      resetForm();
    }
  }, [resetEventForm]);

  const loadInitialInformation = async () => {
    try {
      if (formMode !== NEW) {
        if (!_.isEmpty(event) && event.id && event.title) {
          // update information to state
          setEventId(event.id);
          setImage(event.coverImage);
          setTitle(event.title);
          setType(event.type);
          setLocationName(event.locationName);
          setLocationAddress(event.locationAddress);
          setStartDate(event.startDate);
          setEndDate(event.endDate);
          setCost(event.cost);
          setMinAge(parseInt(event.minAge));
          setMaxAge(event.maxAge === "80+" ? 80 : parseInt(event.maxAge));
          setMaxAttendees(event.maxAttendees);
          setDescription(event.description);
          setBasicDescription(convertHtmlToDraft(event.basicDescription || ""));
          setPremiumDescription(
            convertHtmlToDraft(event.premiumDescription || "")
          );

          if (!_.isEmpty(event.assignedUserIds)) {
            const getAdminUserRes = await getAllAdminUsers();
            if (getAdminUserRes.status) {
              let assignedUserIds = [];
              _.forEach(getAdminUserRes.allAdminUsers, (adminUser) => {
                if (event.assignedUserIds.includes(adminUser.id)) {
                  assignedUserIds.push({
                    id: adminUser.id,
                    name: capitalizeEachWord(
                      `${adminUser.firstName} ${adminUser.lastName}`
                    ),
                  });
                }
              });
              setAssignedUserIds(assignedUserIds);
            }
          }
        } else {
          resetForm();
        }
      }
    } catch (error) {
      console.log("ManageEventForm ~ loadInitialInformation ~ error", error);
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const loadAdminUsers = async () => {
    try {
      const getAdminUserRes = await getAllAdminUsers();

      if (!getAdminUserRes.status) {
        throw new Error(getAdminUserRes.message);
      }

      if (!_.isEmpty(getAdminUserRes.allAdminUsers)) {
        setAllAdminUsers(
          getAdminUserRes.allAdminUsers.map((user) => ({
            id: user.id,
            name: capitalizeEachWord(`${user.firstName} ${user.lastName}`),
          }))
        );
      } else {
        setAllAdminUsers(getAdminUserRes.allAdminUsers);
      }
    } catch (error) {
      console.log(
        "ManageEloadAdminUsersentForm ~ loadAdminUsers ~ error",
        error
      );
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  const resetForm = () => {
    setLoading(INITIAL_STATES.loading);
    setEventId(INITIAL_STATES.eventId);
    setImage(INITIAL_STATES.coverImage);
    setTitle(INITIAL_STATES.title);
    setType(INITIAL_STATES.type);
    setAssignedUserIds(INITIAL_STATES.assignedUserIds);
    setLocationName(INITIAL_STATES.locationName);
    setLocationAddress(INITIAL_STATES.locationAddress);
    setStartDate(INITIAL_STATES.startDate);
    setEndDate(INITIAL_STATES.endDate);
    setCost(INITIAL_STATES.cost);
    setMinAge(INITIAL_STATES.minAge);
    setMaxAge(INITIAL_STATES.maxAge);
    setMaxAttendees(INITIAL_STATES.maxAttendees);
    setDescription(INITIAL_STATES.description);
    setBasicDescription(INITIAL_STATES.basicDescription);
    setPremiumDescription(INITIAL_STATES.premiumDescription);
    setShowImageLibraryModal(INITIAL_STATES.showImageLibraryModal);
    setErrors(INITIAL_STATES.errors);
  };

  const onTextChange = (setState) => (e) => {
    // form has been modified
    !formModified && setFormModified(true);

    const { name, value } = e.target;
    // remove error prompt if any
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    // update state value
    setState(value);
  };

  const onEditorStateChange = (name, setState) => (e) => {
    // remove error prompt if any
    const plainText = e.getCurrentContent().getPlainText();
    const hasText = !!(plainText?.trim && plainText.trim());
    if (errors[name] && hasText) {
      setErrors({ ...errors, [name]: null });
    }

    // form has been modified
    if (hasText) {
      !formModified && setFormModified(true);
    }

    setState && setState(e);
  };

  const onStartDateClick = () => {
    if (!startDate) {
      // set default start date
      setStartDate(
        moment()
          .add({ day: 1 })
          .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
          .toDate()
      );
    }
  };

  const onEndDateClick = () => {
    if (!endDate) {
      // set default end date
      setEndDate(moment(startDate).add({ hour: 2 }).toDate());
    }
  };

  const onStartDateChange = (date) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.startDate) {
      setErrors({ ...errors, startDate: null });
    }
    // update update value
    setStartDate(date);

    if (!date) {
      setEndDate(null);
    }
  };

  const onEndDateChange = (date) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.endDate) {
      setErrors({ ...errors, endDate: null });
    }

    // update update value
    if (!startDate) {
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const filterStartTime = (time) => {
    if (!endDate) {
      return true;
    }
    const startTime = new Date(time);
    const endTime = moment(endDate, DATE_PICKER_FORMAT).toDate();
    return startTime.getTime() < endTime.getTime();
  };

  const filterEndTime = (time) => {
    const startTime = moment(startDate, DATE_PICKER_FORMAT).toDate();
    const endTime = new Date(time);
    return startTime.getTime() < endTime.getTime();
  };

  const onNumberChange = (setState) => (e) => {
    try {
      // form has been modified
      !formModified && setFormModified(true);

      const { name } = e.target;
      // remove error prompt if any
      if (errors[name]) {
        setErrors({ ...errors, [name]: null });
      }
      // update update value
      const value = _.parseInt(e.target.value);
      if (value !== "NaN") {
        setState(value);
      }
    } catch (error) {
      console.log("onNumberChange ~ error", error);
    }
  };

  const onAgeChange = ({ minValue, maxValue }) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.age) {
      setErrors({ ...errors, age: null });
    }

    setMinAge(minValue);
    setMaxAge(maxValue);
  };

  const onImageChange = (event) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.image) {
      setErrors({ ...errors, image: null });
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // validate image type
      if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
        return errorAlert("Please choose only JPG or PNG image type.");
      }

      // validate file size
      const imageSize = (file.size / 1024 / 1024).toFixed(4); // convert to MB
      if (imageSize > IMAGE_SIZE_LIMIT) {
        return errorAlert(
          `Image file size cannot be bigger than ${IMAGE_SIZE_LIMIT}MB.`
        );
      }

      event.target.value = null; // reset the input file state

      file.url = URL.createObjectURL(file);
      file.imageSource = LOCAL_IMAGE;
      setImage(file);
    }
  };

  const onImageLibraryConfirm = (selectedImage) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.image) {
      setErrors({ ...errors, image: null });
    }

    if (selectedImage) {
      // store image
      setImage({
        id: selectedImage.id,
        imagePath: selectedImage.imagePath,
        url: selectedImage.url,
        imageSource: LIBRARY_IMAGE,
      });
    }

    // close modal
    setShowImageLibraryModal(false);
  };

  const onAutoCompleteSelected = (place) => {
    // form has been modified
    !formModified && setFormModified(true);

    // remove error prompt if any
    if (errors.locationAddress) {
      setErrors({ ...errors, locationAddress: null });
    }

    setLocationAddress(place);
  };

  const onSubmit = async () => {
    try {
      let invalidMessages = {};

      // validate all input
      if (!title) {
        invalidMessages.title = "Event title cannot be empty.";
      }
      if (!type) {
        invalidMessages.type = "Event type cannot be empty.";
      }
      if (!startDate) {
        invalidMessages.startDate = "Start date cannot be empty.";
      }

      if (endDate && startDate.getTime() > endDate.getTime()) {
        invalidMessages.endDate =
          "End date must be after the start date of the event.";
      }

      if (!locationAddress?.description) {
        invalidMessages.locationAddress = "Address cannot be empty.";
      } else if (_.isEmpty(locationAddress?.geoPoint)) {
        invalidMessages.locationAddress =
          "Something went wrong with address you selected. Please clear the location address and select it again.";
      }
      if (!locationName) {
        invalidMessages.locationName = "Location name cannot be empty.";
      }
      if (!maxAttendees) {
        invalidMessages.maxAttendees =
          "Please specify number of maximum attendees for this event.";
      }
      if (cost && !_.isNumber(cost)) {
        invalidMessages.cost = "Please enter only a number.";
      }
      if (!description?.trim()) {
        invalidMessages.description = "Description cannot be empty.";
      }

      // validate rich text editor fields
      if (!basicDescription.getCurrentContent().getPlainText()?.trim()) {
        invalidMessages.basicDescription = "Basic description cannot be empty.";
      }

      if (!_.isEmpty(invalidMessages)) {
        // terminate function
        setErrors(invalidMessages);
        return;
      }

      setErrors({});
      setLoading(true);

      const rawBasicDescription = draftToHtmlPuri(
        convertToRaw(basicDescription.getCurrentContent())
      )?.trim();
      const rawPremiumDescription = draftToHtmlPuri(
        convertToRaw(premiumDescription.getCurrentContent())
      )?.trim();

      const filteredAssignedUserIds = _.map(assignedUserIds, (user) => user.id);

      // create or update the event
      const manageEventRes = await manageEventDetailsAPI({
        eventId,
        coverImage: image,
        title,
        type,
        assignedUserIds: filteredAssignedUserIds,
        locationName,
        locationAddress: locationAddress.description,
        geoPoint: locationAddress.geoPoint,
        startDate,
        endDate,
        description: description?.trim(),
        basicDescription: rawBasicDescription,
        premiumDescription: rawPremiumDescription,
        minAge,
        maxAge,
        maxAttendees,
        cost,
      });

      if (manageEventRes.code !== 200) {
        throw new Error(manageEventRes.message);
      }

      if (formMode === DRAFT) {
        // update event detials in redux
        dispatch(
          updateEventAction({
            ...event,
            id: eventId,
            coverImage: image,
            title,
            type,
            assignedUserIds: filteredAssignedUserIds,
            locationName,
            locationAddress: locationAddress.description,
            geoPoint: locationAddress.geoPoint,
            startDate,
            endDate,
            description: description?.trim(),
            basicDescription: rawBasicDescription,
            premiumDescription: rawPremiumDescription,
            minAge,
            maxAge,
            maxAttendees,
            cost,
          })
        );
      }

      setFormModified(false);
      setLoading(false);

      // execute call back
      onSuccess(manageEventRes.results?.eventId);
    } catch (error) {
      console.log("onSubmit ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  return (
    <div className={formDisabled ? "dimmer active" : ""}>
      <div className="card dimmer-content">
        {/* form title */}
        <div className="card-header">
          <h3 className="card-title">{formTitle}</h3>
        </div>

        <div className={`card-body dimmer ${loading ? "active" : ""}`}>
          <form className="row">
            {/* event image container */}
            <div className="form-group col-lg-12">
              <label className="form-label">
                Event image <span className="text-red">*</span>
                {/* side text */}
                <span
                  className={`label-side-text float-right ${
                    disabled ? "text-secondary" : "text-primary"
                  }`}
                  onClick={
                    disabled ? null : () => setShowImageLibraryModal(true)
                  }
                >
                  <i className="icon-picture" /> Select From Library Images
                </span>
              </label>

              {/* image container */}
              <ImageUpload
                disabled={disabled}
                image={image}
                accept={ACCEPTED_IMAGE_TYPES.join(",")}
                onImageChange={onImageChange}
                errorMessage={errors.image}
              />
            </div>

            {/* event name */}
            <div className="form-group col-lg-12 col-md-12">
              <label className="form-label">
                Event title <span className="text-red">*</span>
              </label>
              <input
                disabled={disabled}
                type="text"
                name="title"
                autoComplete="off"
                className={`form-control ${errors.title && "error"}`}
                placeholder="Enter event title here"
                onChange={onTextChange(setTitle)}
                value={title}
              />
              {errors.title ? (
                <div className="input-invalid">{errors.title}</div>
              ) : null}
            </div>

            {/* event type */}
            <div className="form-group col-lg-6 col-md-12">
              <label className="form-label">
                Event Type <span className="text-red">*</span>
              </label>
              <select
                className={`form-control custom-select ${
                  errors.type && "error"
                }`}
                disabled={disabled}
                name="type"
                value={type}
                onChange={onTextChange(setType)}
              >
                <option value={EVENT_GENERAL_TYPE}>
                  {capitalizeEachWord(EVENT_GENERAL_TYPE)}
                </option>
                <option value={EVENT_VOLUNTEER_TYPE}>
                  {capitalizeEachWord(EVENT_VOLUNTEER_TYPE)}
                </option>
              </select>
              {errors.type ? (
                <div className="input-invalid">{errors.type}</div>
              ) : null}
            </div>

            {/* assigned notification */}
            <div className="form-group col-lg-6 col-md-12">
              <label
                className="form-label"
                style={styles.assignedPeopleLabelContainer}
              >
                Assigned people
                <i
                  className="icon-question ml-2"
                  data-toggle="dropdown"
                  style={styles.assignedPeopleHelpIcon}
                  title="About Assigned People"
                />
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-10">
                  <span style={styles.helpText}>
                    This field is optional. The assigned people will receive a
                    push notification in the mobile app if they are logged in
                    when the user send a chat message asking about this event.
                  </span>
                </div>
              </label>
              <Multiselect
                className={`form-control ${errors.assignedUserIds && "error"}`}
                selectedValues={assignedUserIds}
                options={allAdminUsers}
                onSelect={(selectedList) => {
                  setAssignedUserIds(selectedList);
                }}
                onRemove={(selectedList) => {
                  setAssignedUserIds(selectedList);
                }}
                displayValue="name"
                style={styles.assignedUser}
                avoidHighlightFirstOption={true}
              />
              {errors.assignedUserIds ? (
                <div className="input-invalid">{errors.assignedUserIds}</div>
              ) : null}
            </div>

            {/* event start date */}
            <div className="form-group col-lg-6 col-md-12">
              <label className="form-label">
                Start Date and Time <span className="text-red">*</span>
              </label>
              <DatePicker
                disabled={disabled || disabledStartDate}
                selectsStart
                showTimeSelect
                timeIntervals={15}
                filterTime={filterStartTime}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minStartDate}
                maxDate={endDate}
                dateFormat={DATE_PICKER_FORMAT}
                onInputClick={onStartDateClick}
                onChange={onStartDateChange}
                className={`form-control ${errors.startDate && "error"}`}
                placeholderText="Select start date and time here"
              />
              {errors.startDate ? (
                <div className="input-invalid">{errors.startDate}</div>
              ) : null}
            </div>

            {/* event end date */}
            <div className="form-group col-lg-6 col-md-12">
              <label className="form-label">
                End Date and Time <small>(Optional)</small>
              </label>
              <DatePicker
                disabled={!startDate || disabled}
                selectsEnd
                showTimeSelect
                timeIntervals={15}
                filterTime={filterEndTime}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minEndDate}
                dateFormat={DATE_PICKER_FORMAT}
                onInputClick={onEndDateClick}
                onChange={onEndDateChange}
                className={`form-control ${errors.endDate && "error"}`}
                placeholderText="Select end date and time here"
              />
              {errors.endDate ? (
                <div className="input-invalid">{errors.endDate}</div>
              ) : null}
            </div>

            {/* event location address */}
            <div
              className="form-group col-lg-6 col-md-12"
              style={{ position: "relative" }}
            >
              <AutoCompleteAddress
                required
                disabled={disabled}
                errorMessage={errors.locationAddress}
                label="Location address"
                placeholder="Search event address here..."
                onSelected={onAutoCompleteSelected}
                defaultValue={locationAddress}
              />
            </div>

            {/* event location name */}
            <div className="form-group col-lg-6 col-md-12">
              <label className="form-label">
                Business/Location name <span className="text-red">*</span>
              </label>
              <input
                disabled={disabled}
                type="text"
                name="locationName"
                autoComplete="off"
                className={`form-control ${errors.locationName && "error"}`}
                placeholder="Enter event location name here"
                onChange={onTextChange(setLocationName)}
                value={locationName}
              />
              {errors.locationName ? (
                <div className="input-invalid">{errors.locationName}</div>
              ) : null}
            </div>

            {/* maximum attendees */}
            <div className="form-group col-lg-6">
              <label className="form-label">
                Max. Attendees <span className="text-red">*</span>
              </label>
              <div className="input-icon">
                <span className="input-icon-addon">
                  <i className="fe fe-users" />
                </span>
                <input
                  disabled={disabled}
                  type="number"
                  name="maxAttendees"
                  autoComplete="off"
                  className={`form-control ${errors.maxAttendees && "error"}`}
                  placeholder="Maximum number of 2000"
                  onChange={onNumberChange(setMaxAttendees)}
                  value={maxAttendees}
                />
              </div>
              {errors.maxAttendees ? (
                <div className="input-invalid">{errors.maxAttendees}</div>
              ) : null}
            </div>

            {/* event cost */}
            <div className="form-group col-lg-6">
              <label className="form-label">
                Cost <small>(Optional)</small>
              </label>
              <div className="input-icon">
                <span className="input-icon-addon">
                  <i className="fe fe-dollar-sign" />
                </span>
                <input
                  disabled={disabled}
                  type="number"
                  name="cost"
                  autoComplete="off"
                  className={`form-control ${errors.cost && "error"}`}
                  placeholder="Enter cost here"
                  onChange={onNumberChange(setCost)}
                  value={cost}
                />
              </div>
              {errors.cost ? (
                <div className="input-invalid">{errors.cost}</div>
              ) : null}
            </div>

            {/* age ranges */}
            <div className="form-group col-lg-12">
              <label className="form-label">
                Age Ranges <span className="text-red">*</span>
              </label>
              <DualSlider
                disabled={disabled}
                min={18}
                max={80}
                step={5}
                minValue={minAge || 20}
                maxValue={maxAge || 35}
                onChange={onAgeChange}
              />
              {errors.age ? (
                <div className="input-invalid">{errors.age}</div>
              ) : null}
            </div>

            {/* event description */}
            <div className="form-group col-lg-12">
              <label className="form-label">
                Description <span className="text-red">*</span>
              </label>
              <textarea
                disabled={disabled}
                type="text"
                name="description"
                rows={5}
                autoComplete="off"
                className={`form-control ${errors.description && "error"}`}
                placeholder="Enter description here"
                onChange={onTextChange(setDescription)}
                value={description}
              />
              {errors.description ? (
                <div className="input-invalid">{errors.description}</div>
              ) : null}
            </div>

            {/* event basic description */}
            <div className="form-group dimmer-content col-lg-12 col-12 mt-2">
              <label className="form-label">
                New description <span className="text-red">*</span>
              </label>
              <Editor
                readOnly={disabled}
                editorStyle={EDITOR_STYLE}
                handlePastedText={() => false}
                localization={{ locale: "en", translations: EDITOR_LABELS }}
                editorState={basicDescription}
                toolbarClassName="faq-text-editor-toolbar"
                wrapperClassName={`faq-text-editor-wrapper ${
                  errors.basicDescription && "error"
                }`}
                editorClassName="faq-text-editor"
                onEditorStateChange={onEditorStateChange(
                  "basicDescription",
                  setBasicDescription
                )}
                toolbar={TOOLBAR}
              />
              {errors.basicDescription ? (
                <div className="input-invalid">{errors.basicDescription}</div>
              ) : null}
            </div>

            {/* event premium description */}
            <div className="form-group dimmer-content col-lg-12 col-12 mt-2">
              <label className="form-label">Premium content</label>
              <Editor
                readOnly={disabled}
                editorStyle={EDITOR_STYLE}
                handlePastedText={() => false}
                localization={{ locale: "en", translations: EDITOR_LABELS }}
                editorState={premiumDescription}
                toolbarClassName="faq-text-editor-toolbar"
                wrapperClassName="faq-text-editor-wrapper"
                editorClassName="faq-text-editor"
                onEditorStateChange={onEditorStateChange(
                  "premiumDescription",
                  setPremiumDescription
                )}
                toolbar={TOOLBAR}
              />
              {errors.premiumDescription ? (
                <div className="input-invalid">{errors.premiumDescription}</div>
              ) : null}
            </div>
          </form>
        </div>

        {/* submit button */}
        <div className="card-footer text-right">
          <button
            disabled={disabled || !formModified}
            type="button"
            className="btn btn-round btn-primary"
            onClick={onSubmit}
          >
            {loading ? (
              <>
                <div
                  className="spinner-border text-white spinner-border-sm mr-10"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                <span>{eventId ? "Updating..." : "Creating..."}</span>
              </>
            ) : eventId ? (
              "Update Event"
            ) : (
              "Next"
            )}
          </button>
        </div>
      </div>

      {/* image library modal */}
      <ImageLibraryModal
        isOpen={showImageLibraryModal}
        onRequestClose={() => setShowImageLibraryModal(false)}
        selectedImage={image}
        onConfirm={onImageLibraryConfirm}
      />
    </div>
  );
};

export default ManageEventForm;

const styles = {
  assignedUser: {
    searchBox: {
      border: "none",
      padding: 0,
      margin: 0,
    },
    inputField: {
      padding: 0,
      margin: 0,
    },
    chips: {
      display: "inline-flex",
      backgroundColor: "#F68D2E",
      color: "white",
    },
    optionContainer: {
      padding: 0,
      margin: 0,
    },
  },
  assignedPeopleLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  assignedPeopleHelpIcon: {
    color: "#f68d2e",
    cursor: "pointer",
  },
  helpText: {
    fontWeight: 400,
  },
};
