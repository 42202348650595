import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { allRoutes } from "routes";
import defaultProfileCover from "assets/images/default-profile-cover.png";
import verifiedIcon from "assets/icons/VerifiedShield.svg";
import unverifiedIcon from "assets/icons/UnverifiedShield.svg";
import {
  blockUserAccountAPI,
  logoutUserAllSessionsAPI,
  unblockUserAccountAPI,
  updateProfileVerificationAPI,
} from "api/user";
import { calculateAge, capitalizeEachWord, errorAlert } from "utils/helpers";
import { alert } from "utils/alert";
import Loader from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateUserReducerAction } from "stores/actions/userAction";
import { GENERAL_DATE_FORMAT } from "utils/constants";

const UpdateUser = (props) => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allUsers: users, allUsersPagination: usersPagination } = useSelector(
    (state) => state.userReducer || {}
  );

  // states
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [loadingLogoutSession, setLoadingLogoutSession] = useState(false);
  const [loadingBlockAccount, setLoadingBlockAccount] = useState(false);
  const [loadingTemporaryBlockAccount, setLoadingTemporaryBlockAccount] =
    useState(false);
  const [loadingUnblockAccount, setLoadingUnblockAccount] = useState(false);

  // variables
  const userId = location?.state?.userId;
  const userIndex = _.findIndex(users[usersPagination.index], {
    id: userId,
  });
  const user = users[usersPagination.index][userIndex];

  useEffect(() => {
    if (_.isEmpty(user)) {
      // user not found, redirect to manage user page
      navigate(allRoutes.users.index.path);
    }
  }, []);

  const renderBlockStatus = () => {
    if (_.isBoolean(user.block) && user.block) {
      return (
        <span className="text-danger">
          <b>Permanently blocked</b>
        </span>
      );
    } else if (_.isString(user.block)) {
      return (
        <span className="text-warning">
          <b>
            {`Temporary blocked until `}
            {moment(user.block).format(GENERAL_DATE_FORMAT)}
          </b>
        </span>
      );
    } else {
      return (
        <span className="text-success">
          <b>Active</b>
        </span>
      );
    }
  };

  const onVerificationButtonClick = async () => {
    try {
      const status = !user.verified;

      const { isConfirmed } = await alert.fire({
        title: "Are you sure?",
        text: `Do you want to make ${capitalizeEachWord(
          user.firstName
        )} profile to be ${status ? "VERIFIED" : "UNVERIFIED"}?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: status ? "Verified" : "Unverified",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoadingVerification(true);

        const updateVerificationRes = await updateProfileVerificationAPI({
          userId: user.id,
          status,
        });

        if (updateVerificationRes.code !== 200) {
          throw new Error(updateVerificationRes.message);
        }

        // update user details flag in redux
        users[usersPagination.index][userIndex] = {
          ...user,
          verified: status,
        };
        dispatch(updateUserReducerAction({ allUsers: [...users] }));

        setLoadingVerification(false);

        // show success pop up
        alert.fire({
          title: `Update Profile Verification Success`,
          text: `${capitalizeEachWord(user.firstName)} profile has now been ${
            status ? "verified" : "unverified"
          }.`,
          icon: "success",
        });
      }
    } catch (error) {
      console.log("onVerificationButtonClick ~ error", error);
      setLoadingVerification(false);
      errorAlert(error.message);
    }
  };

  const onLogoutSessionClick = async () => {
    try {
      const { isConfirmed } = await alert.fire({
        title: "Are you sure?",
        text: `Do you want to logout ${capitalizeEachWord(
          user.firstName
        )} from all of his devices?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Logout All Devices",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoadingLogoutSession(true);

        const logoutSessionRes = await logoutUserAllSessionsAPI({
          userId: user.id,
        });

        if (logoutSessionRes.code !== 200) {
          throw new Error(logoutSessionRes.message);
        }

        setLoadingLogoutSession(false);

        // show success pop up
        alert.fire({
          title: `Logout Success`,
          text: `${capitalizeEachWord(
            user.firstName
          )} has now been logout from all of his devices.`,
          icon: "success",
        });
      }
    } catch (error) {
      console.log("onLogoutSessionClick ~ error", error);
      setLoadingLogoutSession(false);
      errorAlert(error.message);
    }
  };

  const onBlockAccountClick = async (permanent, setLoading) => {
    try {
      const { isConfirmed } = await alert.fire({
        title: "Are you sure?",
        text: `Do you want to block ${capitalizeEachWord(
          user.firstName
        )} account ${permanent ? "PERMANENTLY" : "for 7 days"}?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Block",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoading(true);

        const blockAccountRes = await blockUserAccountAPI({
          userId: user.id,
          permanent: !!permanent,
        });

        if (blockAccountRes.code !== 200) {
          throw new Error(blockAccountRes.message);
        }

        // update user details flag in redux
        users[usersPagination.index][userIndex] = {
          ...user,
          block: blockAccountRes.results?.block,
        };
        dispatch(updateUserReducerAction({ allUsers: [...users] }));

        setLoading(false);

        // show success pop up
        alert.fire({
          title: `Block Success`,
          text: `${capitalizeEachWord(
            user.firstName
          )} account has now been blocked ${
            permanent ? "PERMANENTLY" : "for 7 days"
          }.`,
          icon: "success",
        });
      }
    } catch (error) {
      console.log("onBlockAccountClick ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const onUnblockAccountClick = async () => {
    try {
      const { isConfirmed } = await alert.fire({
        title: "Are you sure?",
        text: `Do you want to unblock ${capitalizeEachWord(
          user.firstName
        )} account?`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Unblock",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoadingUnblockAccount(true);

        const unblockAccountRes = await unblockUserAccountAPI({
          userId: user.id,
        });

        if (unblockAccountRes.code !== 200) {
          throw new Error(unblockAccountRes.message);
        }

        // update user details flag in redux
        users[usersPagination.index][userIndex] = {
          ...user,
          block: undefined,
        };
        dispatch(updateUserReducerAction({ allUsers: [...users] }));

        setLoadingUnblockAccount(false);

        // show success pop up
        alert.fire({
          title: `Unblock Success`,
          text: `${capitalizeEachWord(
            user.firstName
          )} account has now been unblocked.`,
          icon: "success",
        });
      }
    } catch (error) {
      console.log("onUnblockAccountClick ~ error", error);
      setLoadingUnblockAccount(false);
      errorAlert(error.message);
    }
  };

  if (_.isEmpty(user)) {
    // user not found
    return null;
  }

  return (
    <div className="row clearfix">
      {/* profile details */}
      <div className={`col-lg-4 col-md-6 col-sm-12`}>
        <div className="card" style={{ overflow: "hidden" }}>
          <div style={styles.imageContainer}>
            {/* cover image */}
            <img
              src={user.coverImage?.url || defaultProfileCover}
              style={styles.coverImage}
              alt="User Cover"
            />

            {/* profile image */}
            <div style={styles.profileImageContainer}>
              <img
                src={user.profileImage?.url}
                style={styles.profileImage}
                alt="User Profile"
              />

              {/* verification icon */}
              <ReactSVG
                src={user.verified ? verifiedIcon : unverifiedIcon}
                style={styles.profileVerificationIcon}
              />
            </div>

            <h3
              className="text-capitalize mt-10"
              style={styles.usernameContainer}
            >
              {/* user name */}
              <b>{`${user.firstName} ${user.lastName}`}</b>
            </h3>
          </div>

          <div className="card-body">
            {/* date of birth */}
            {user.dob ? (
              <>
                <small className="text-muted">Date of Birth: </small>
                <p>{moment(user.dob, "YYYY/MM").format("MMM YYYY")}</p>
              </>
            ) : null}

            {/* age */}
            {user.dob ? (
              <>
                <small className="text-muted">Age: </small>
                <p>{calculateAge(user.dob)}</p>
              </>
            ) : null}

            {/* email */}
            {user.email ? (
              <>
                <small className="text-muted">
                  Email:
                  <span
                    className={`text-${
                      user.emailVerified ? "success" : "warning"
                    } ml-2`}
                  >
                    <b>{user.emailVerified ? "Verified" : "Unverified"}</b>
                    {user.emailVerified ? (
                      <i className="fa fa-check-circle ml-1" />
                    ) : null}
                  </span>
                </small>
                <p>{user.email}</p>
              </>
            ) : null}

            {/* role */}
            {user.role ? (
              <>
                <small className="text-muted">Role: </small>
                <p className="text-capitalize">
                  <span className="tag tag-success">{user.role}</span>
                </p>
              </>
            ) : null}

            {/* bio */}
            {user.bio ? (
              <>
                <small className="text-muted">Bio: </small>
                <p>{user.bio}</p>
              </>
            ) : null}

            {/* account status */}
            <small className="text-muted">Status: </small>
            <p className="text-capitalize">{renderBlockStatus()}</p>
          </div>
        </div>
      </div>

      {/* control panel */}
      <div className={`col-xl-3 col-lg-4 col-md-6 col-sm-12`}>
        <div className="card">
          {/* title */}
          <div className="card-header">
            <h3 className="card-title">Control Buttons</h3>
          </div>

          <div className="card-body">
            {/* verify profile action */}
            <small className="text-muted">Verification control: </small>
            <div className="mt-1 mb-10">
              <button
                className={`text-capitalize btn btn-outline-${
                  user.verified ? "warning" : "success"
                }`}
                disabled={loadingVerification}
                onClick={onVerificationButtonClick}
              >
                {loadingVerification ? (
                  <Loader className="mr-10 spinner-border-sm" />
                ) : null}
                {user.verified
                  ? `Unverified ${user.firstName} profile`
                  : `Verified ${user.firstName} profile`}
              </button>
            </div>

            {/* clear user session action */}
            <small className="text-muted">User sessions control: </small>
            <div className="mt-1 mb-10">
              <button
                className="btn btn-outline-info"
                onClick={onLogoutSessionClick}
              >
                {loadingLogoutSession ? (
                  <Loader className="mr-10 spinner-border-sm" />
                ) : null}
                Logout all sessions
              </button>
            </div>

            {/* block action */}
            <small className="text-muted">Block account control: </small>
            <div className="mt-1 mb-10">
              {user.block ? (
                <button
                  className="btn btn-outline-primary mr-10 mb-10"
                  onClick={onUnblockAccountClick}
                >
                  {loadingUnblockAccount ? (
                    <Loader className="mr-10 spinner-border-sm" />
                  ) : null}
                  Unblock
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-outline-danger mr-10 mb-10"
                    onClick={() =>
                      onBlockAccountClick(true, setLoadingBlockAccount)
                    }
                  >
                    {loadingBlockAccount ? (
                      <Loader className="mr-10 spinner-border-sm" />
                    ) : null}
                    Block
                  </button>
                  <button
                    className="btn btn-outline-primary mb-10"
                    onClick={() =>
                      onBlockAccountClick(
                        false,
                        setLoadingTemporaryBlockAccount
                      )
                    }
                  >
                    {loadingTemporaryBlockAccount ? (
                      <Loader className="mr-10 spinner-border-sm" />
                    ) : null}
                    Tempopary Block
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;

const styles = {
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  coverImage: {
    width: "100%",
    aspectRatio: "3/1",
    backgroundColor: "#E2E2E2",
    objectFit: "cover",
  },
  profileImageContainer: { position: "relative" },
  profileImage: {
    width: "150px",
    height: "150px",
    borderRadius: "150px",
    marginTop: "-75px",
    alignSelf: "center",
    backgroundColor: "#F2F2F2",
  },
  usernameContainer: {
    position: "relative",
    maxWidth: "80%",
  },
  profileVerificationIcon: {
    position: "absolute",
    bottom: 0,
    right: 20,
    transform: "scale(2)",
    display: "flex",
    alignItems: "center",
  },
};
