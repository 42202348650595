export const updateEventAction = (payload) => ({
  type: "UPDATE_EVENT",
  payload,
});

export const updateEventFormModeAction = (payload) => ({
  type: "UPDATE_EVENT_FORM_MODE",
  payload,
});

export const updateSelectedChatEventAction = (payload) => ({
  type: "UPDATE_SELECTED_CHAT_EVENT",
  payload,
});

export const updateAllEventChatListAction = (payload) => ({
  type: "UPDATE_EVENT_CHAT_LIST",
  payload,
});

export const updateAllEventDetailsAction = (payload) => ({
  type: "UPDATE_ALL_EVENT_DETAILS",
  payload,
});
