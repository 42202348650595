import _ from "lodash";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import Image from "components/Image";
import { errorAlert, getEventImages } from "utils/helpers";
import { LIBRARY_IMAGE } from "utils/constants";

const ImageLibraryModal = (props) => {
  // states
  const [gettingImages, setGettingImages] = useState(false);
  const [libraryImages, setLibraryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // variables
  const {
    isOpen,
    onRequestClose,
    selectedImage: prevSelectedImage,
    onConfirm,
  } = props;

  useEffect(() => {
    if (isOpen) {
      if (prevSelectedImage?.imageSource === LIBRARY_IMAGE) {
        // marked selected image
        setSelectedImage(prevSelectedImage);
      } else {
        setSelectedImage(null);
      }

      if (_.isEmpty(libraryImages)) {
        // get image library if there aren't any
        loadLibraryImages();
      }
    }
  }, [isOpen]);

  const loadLibraryImages = async () => {
    try {
      setGettingImages(true);

      const getImageRes = await getEventImages();

      if (!getImageRes.status) {
        throw new Error(getImageRes.message);
      }

      setLibraryImages(getImageRes.images);
      setGettingImages(false);
    } catch (error) {
      console.log("loadLibraryImages ~ error", error);
      setGettingImages(false);
    }
  };

  const onImageClick = (image) => {
    try {
      if (!_.isEmpty(image)) {
        if (image.id === selectedImage?.id) {
          // unselected image
          setSelectedImage(null);
        } else {
          // marked image as selected
          setSelectedImage(image);
        }
      }
    } catch (error) {
      console.log("onImageClick ~ error", error);
      errorAlert("Something went wrong. Please try again.");
    }
  };

  const onModalHide = () => {
    setSelectedImage(null);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {/* modal header */}
      <div className="card-header">
        {/* modal title */}
        <h3 className="card-title">Image Library</h3>

        <div className="card-options">
          <span className="cursor-pointer" onClick={onRequestClose}>
            <i className="fe fe-x" />
          </span>
        </div>
      </div>

      {/* modal body content */}
      <div className="card-body" style={styles.modalBodyContainer}>
        {/* show loading */}
        {gettingImages ? (
          <div style={styles.modalCenterContent}>
            <div className="spinner-grow text-primary mb-10">
              <span className="sr-only">Loading...</span>
            </div>
            <span>Loading images...</span>
          </div>
        ) : null}

        {/* no images */}
        {!gettingImages && libraryImages.length < 1 ? (
          <div style={styles.modalCenterContent}>
            <span>No image library found</span>
          </div>
        ) : null}

        <div className="row gutters-sm">
          {_.map(libraryImages, (image) => (
            <Image
              key={image.id}
              className="col-lg-3 col-md-4 col-sm-6"
              src={image.url}
              selected={image.id === selectedImage?.id}
              onClick={() => onImageClick(image)}
            />
          ))}
        </div>
      </div>

      {/* modal footer */}
      <div className="card-footer d-flex justify-content-end">
        {/* cancel button */}
        <button
          type="button"
          disabled={gettingImages}
          className="btn btn-round btn-outline-primary mr-10"
          onClick={onRequestClose}
        >
          Cancel
        </button>

        {/* confirm button */}
        <button
          type="button"
          disabled={gettingImages || _.isEmpty(selectedImage)}
          className="btn btn-round btn-primary"
          onClick={() => onConfirm(selectedImage)}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default ImageLibraryModal;

const styles = {
  modalBodyContainer: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    overflowY: "scroll",
  },
  modalCenterContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
