import React from "react";

import EventChatList from "components/lists/EventChatList";
import EventChatBox from "components/EventChatBox";

const ChatEvent = (props) => {
  return (
    <div className="row clearfix pb-50">
      {/* list of user profile for chat */}
      <div className="col-lg-4 col-md-4" style={styles.eventChatListContainer}>
        <EventChatList />
      </div>

      {/* chat window */}
      <div className="col-lg-8 col-md-8" style={styles.eventChatBoxContainer}>
        <EventChatBox />
      </div>
    </div>
  );
};

export default ChatEvent;

const styles = {
  eventChatListContainer: {
    maxHeight: "calc(100vh - 130px)",
  },
  eventChatBoxContainer: {
    height: "calc(100vh - 130px)",
  },
};
