import React from "react";

import ChatDate from "components/ChatDate";
import { capitalizeEachWord } from "utils/helpers";
import DefaultUserProfile from "assets/images/default-user-profile.png";

const ChatCard = (props) => {
  // variables
  let { selected, user, event, eventMessage, onClick = () => {} } = props;

  if (!user) {
    // add dummy user data
    user = {
      profileImage: {
        url: DefaultUserProfile,
      },
      firstName: "User",
      lastName: "Name",
    };
  }

  return (
    <div
      className={`chat-card-container ${selected ? "selected" : ""}`}
      style={styles.mainContainer}
      onClick={onClick}
    >
      {/* profile image */}
      <div style={styles.profileImageContainer}>
        {user?.profileImage?.url ? (
          <img
            style={styles.profileImage}
            src={user?.profileImage?.url}
            data-toggle="tooltip"
            data-placement="top"
            alt="Avatar"
            className="avatar"
            data-original-title="Avatar Name"
          />
        ) : (
          <span
            className="avatar avatar-green text-uppercase"
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Avatar Name"
            style={styles.profileImage}
          >
            {`${user?.firstName[0]}${user?.lastName[0]}`}
          </span>
        )}
      </div>

      {/* right container */}
      <div style={styles.rightContainer}>
        {/* user name */}
        <span
          style={{
            ...styles.title,
            fontWeight: eventMessage?.viewed ? 600 : 800,
          }}
        >
          {capitalizeEachWord(
            `${user?.firstName} ${user?.lastName} · ${event?.title}`
          )}
        </span>

        {/* last chat message and date */}
        <div style={styles.messageContainer}>
          {/* message */}
          <div
            style={{
              ...styles.textMessage,
              fontWeight: eventMessage?.viewed ? 500 : 700,
            }}
          >
            {eventMessage?.subtitle}
          </div>
          {/* divider */}
          <div style={styles.divider}>·</div>
          {/* date */}
          <ChatDate style={styles.date} date={eventMessage?.lastMessageTime} />
        </div>

        {/* unread badge */}
        {!eventMessage?.viewed ? <div style={styles.badge} /> : null}
      </div>
    </div>
  );
};

export default ChatCard;

const styles = {
  mainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    padding: "0.5rem",
    overflow: "hidden",
    userSelect: "none",
  },
  profileImageContainer: {
    height: 60,
    width: 60,
    marginRight: "1rem",
  },
  profileImage: {
    height: 60,
    width: 60,
    objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightContainer: {
    position: "relative",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingRight: "2rem",
    flexDirection: "column",
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    color: "grey",
  },
  textMessage: {
    display: "flex",
    alignItems: "flex-end",
    fontSize: "0.85rem",
  },
  divider: {
    display: "flex",
    padding: "0 .3rem",
    alignItems: "flex-end",
  },
  date: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end",
    whiteSpace: "nowrap",
    fontSize: "0.85rem",
  },
  badge: {
    position: "absolute",
    right: "0.5rem",
    height: "0.75rem",
    width: "0.75rem",
    backgroundColor: "#f68d2e",
    borderRadius: "1rem",
  },
};
