import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const MenuLink = (props) => {
  // hooks
  const location = useLocation();
  const menuIcon = useSelector((state) => state.settings.isMenuIcon);
  const subMenuIcon = useSelector((state) => state.settings.isSubMenuIcon);

  const onLinkClick = (event) => {
    if (props.hasSubMenu) {
      props.toggleSubMenu(event);
    } else {
      props.activateMe({
        newLocation: props.to,
        selectedMenuLabel: props.label,
      });
    }
  };

  return (
    <span className={location.pathname === props.to ? "active" : ""}>
      <Link
        to={props.to}
        onClick={onLinkClick}
        className={location.pathname === props.to ? menuIcon : subMenuIcon}
      >
        {props.children[0].props.className ? props.children : props.label}
      </Link>
    </span>
  );
};

export default MenuLink;
