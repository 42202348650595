// session key
export const USER_INFO = "userInfo";

// firestore response messages
export const NO_MATCHING_DOCUMENTS = "No matching documents.";
export const ERROR_GETTING_DOCUMENT = "Error getting document.";
export const ERROR_ADDING_DOCUMENT = "Error adding document.";
export const ERROR_DELETING_DOCUMENT = "Error deleting document.";
export const ERROR_UPDATING_DOCUMENT = "Error updating document.";
export const ERROR_SETTING_DOCUMENT = "Error setting document.";

// error messages
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please refreshing the page and try again.";

// user roles
export const SUPER_ADMIN = "super admin";
export const ADMIN = "admin";
export const USER = "user";

// allowed roles
export const ALLOWED_ROLES = [SUPER_ADMIN, ADMIN];

// date formats
export const GENERAL_DATE_FORMAT = "DD MMM yyyy";
export const CREATED_DATE_FORMAT = "DD MMM yyyy";
export const DATE_PICKER_FORMAT = "d MMMM yyyy   h:mm aa";

// image src lists
export const LOCAL_IMAGE = "local image";
export const LIBRARY_IMAGE = "library image";

// list of accepted file types
export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg"];

// list of maximum file size
export const IMAGE_SIZE_LIMIT = 10; // unit in MB

// list of event type
export const PUBLISHED = "published";
export const DRAFT = "draft";
export const HISTORY = "history";

// list of form mode
export const NEW = "new";

// text editor toolbar options
export const EDITOR_STYLE = {
  minHeight: "250px",
  lineHeight: "120%",
};
export const EDITOR_LABELS = {
  "components.controls.blocktype.normal": "Normal",
};
export const EDITOR_TOOLBAR_OPTIONS = ["inline", "link", "history"];
export const EDITOR_INLINE = {
  options: ["bold", "underline"],
};
export const EDITOR_BLOCK_TYPE = {
  options: ["Normal", "H4", "H5"],
};
export const TOOLBAR = {
  options: EDITOR_TOOLBAR_OPTIONS,
  inline: EDITOR_INLINE,
  blockType: EDITOR_BLOCK_TYPE,
};

// list of pagination default value
export const INITIAL_PAGINATION = {
  index: 0,
  nextDocId: null,
  limit: 0,
};

// list of event types
export const EVENT_GENERAL_TYPE = "general";
export const EVENT_VOLUNTEER_TYPE = "volunteer";

// list of notification limits
export const NOTIFICATIONS_LIMIT_DOCS = 20;
export const HEADER_NOTIFICATIONS_LIMIT_DOCS = 6;

// list of callout participated types
export const GOING = "going";
export const INTERESTED = "interested";
