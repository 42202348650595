import _ from "lodash";
import axios from "axios";

import config from "config";
import { firebaseAuth } from "lib/firebase";

let timeout = 30000; // 30s
let baseURL = config.BASE_URL;

export const preAuthAxios = async () => {
  return axios.create({
    baseURL,
    timeout,
  });
};

const axiosHelper = async () => {
  const token = await firebaseAuth.currentUser.getIdToken();
  return axios.create({
    baseURL,
    timeout,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const errorHandler = (error) => {
  if (error.code === "ERR_BAD_REQUEST") {
    return {
      message:
        error?.response?.data?.message ||
        "Something went wrong. Please try again.",
    };
  } else if (error.message === "Network Error") {
    return error;
  } else if (error.code === "ECONNABORTED") {
    return { message: "Exceeded Timeout. Please try again." };
  } else if (
    error?.response?.data?.code === 422 &&
    _.isArray(error?.response?.data?.errors)
  ) {
    let message = "";

    _.forEach(error?.response?.data?.errors, (error) => {
      message += `${_.startCase(error.param)}: ${error.msg}\n`;
    });

    error.response.data.message = message;
  }
  return error?.response?.data;
};

export default axiosHelper;
