import axios, { errorHandler } from "api";
import moment from "moment";

import config from "config";
import { LIBRARY_IMAGE, LOCAL_IMAGE } from "utils/constants";

export const manageEventDetailsAPI = async ({
  eventId,
  coverImage,
  title,
  type,
  assignedUserIds,
  locationName,
  locationAddress,
  geoPoint,
  startDate,
  endDate,
  description, // TODO to be deleted
  basicDescription,
  premiumDescription,
  minAge,
  maxAge,
  maxAttendees,
  cost,
}) => {
  try {
    // prepare form data
    let formData = new FormData();
    formData.append("eventId", eventId);
    formData.append("title", title);
    formData.append("type", type);
    formData.append("assignedUserIds", JSON.stringify(assignedUserIds));
    formData.append("locationName", locationName);
    formData.append("locationAddress", locationAddress);
    formData.append("latitude", geoPoint.latitude);
    formData.append("longitude", geoPoint.longitude);
    formData.append("startDate", moment(startDate).toISOString());
    formData.append("endDate", endDate ? moment(endDate).toISOString() : "");
    formData.append("description", description); // TODO to be deleted
    formData.append("basicDescription", basicDescription);
    formData.append("premiumDescription", premiumDescription);
    formData.append("minAge", minAge);
    formData.append("maxAge", maxAge);
    formData.append("maxAttendees", maxAttendees);
    formData.append("cost", cost || 0);

    // append cover image
    if (coverImage?.imageSource === LOCAL_IMAGE) {
      formData.append("coverImage", coverImage);
    } else if (coverImage?.imageSource === LIBRARY_IMAGE) {
      formData.append("coverImageId", coverImage.id);
    }

    const instance = await axios();
    const response = await instance.post(
      config.EP_MANAGE_EVENT_DETAILS,
      formData
    );
    return response.data;
  } catch (error) {
    console.log("manageEventDetailsAPI ~ error", error);
    return errorHandler(error);
  }
};

export const manageEventInterestsAPI = async ({
  eventId,
  selectedInterestIds,
}) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_MANAGE_EVENT_INTERESTS, {
      eventId,
      selectedInterestIds,
    });
    return response.data;
  } catch (error) {
    console.log("manageEventInterestsAPI ~ error", error);
    return errorHandler(error);
  }
};

export const getEventsAPI = async ({ type, nextDocId = null }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_GET_EVENTS, {
      type,
      nextDocId,
    });
    return response.data;
  } catch (error) {
    console.log("getEventsAPI ~ error", error);
    return errorHandler(error);
  }
};

export const getEventDetailsAPI = async (eventId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_GET_EVENT_DETAILS, {
      eventId,
    });
    return response.data;
  } catch (error) {
    console.log("getEventDetailsAPI ~ error", error);
    return errorHandler(error);
  }
};

export const deleteEventAPI = async (eventId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_DELETE_EVENT, {
      eventId,
    });
    return response.data;
  } catch (error) {
    console.log("deleteEventAPI ~ error", error);
    return errorHandler(error);
  }
};

export const manageEventFaqAPI = async ({
  eventId,
  faqId,
  question,
  answer,
}) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_MANAGE_EVENT_FAQ, {
      eventId,
      faqId,
      question,
      answer,
    });
    return response.data;
  } catch (error) {
    console.log("manageEventFaqAPI ~ error", error);
    return errorHandler(error);
  }
};

export const deleteEventFaqAPI = async (faqId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_DELETE_EVENT_FAQ, {
      faqId,
    });
    return response.data;
  } catch (error) {
    console.log("deleteEventFaqAPI ~ error", error);
    return errorHandler(error);
  }
};

export const seenEventChatMessageAPI = async (eventMessageId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_SEEN_EVENT_CHAT_MESSAGE, {
      eventMessageId,
    });
    return response.data;
  } catch (error) {
    console.log("seenEventChatMessageAPI ~ error", error);
    return errorHandler(error);
  }
};

export const sendEventChatMessageAPI = async (eventMessageId, message) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_SEND_EVENT_CHAT_MESSAGE, {
      eventMessageId,
      message,
    });
    return response.data;
  } catch (error) {
    console.log("sendEventChatMessageAPI ~ error", error);
    return errorHandler(error);
  }
};

export const duplicateEventAPI = async (eventId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_DUPLICATE_EVENT, {
      eventId,
    });
    return response.data;
  } catch (error) {
    console.log("duplicateEventAPI ~ error", error);
    return errorHandler(error);
  }
};
