import React from "react";
import { allRoutes } from "routes";

const NotFound = () => {
  return (
    <div className="row clearfix">
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-body">
            <div className="display-3 text-muted mb-5">
              <i className="fa fa-exclamation" /> <b>404</b>
            </div>
            <h1 className="h3 mb-3">Oops... Page not found</h1>
            <p className="h6 text-muted font-weight-normal mb-3">
              We are sorry but the page you are looking for could not be found
            </p>
            <a
              className="btn btn-primary"
              href={allRoutes.dashboard.index.path}
            >
              <i className="fe fe-arrow-left mr-2" />
              Back to Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
