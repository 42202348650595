import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ManageEventForm from "components/forms/ManageEventForm";
import ManageInterestForm from "components/forms/ManageInterestForm";
import { alert } from "utils/alert";
import { allRoutes } from "routes";
import { resetEventFormAction } from "stores/actions/formAction";
import {
  updateEventAction,
  updateEventFormModeAction,
} from "stores/actions/eventAction";
import { NEW } from "utils/constants";

const CreateEvent = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [eventId, setEventId] = useState("");
  const [eventFormDisabled, setEventFormDisabled] = useState(false);
  const [interestFormDisabled, setInterestFormDisabled] = useState(false);

  useEffect(() => {
    // clear the event in redux and set the form mode to new
    dispatch(updateEventAction(null));
    dispatch(updateEventFormModeAction(NEW));

    return () => {
      // remove event in redux
      dispatch(updateEventAction(null));
      dispatch(updateEventFormModeAction(""));
    };
  }, []);

  const onCreateEventSuccess = (eventId) => {
    setEventFormDisabled(true);
    setEventId(eventId);
    dispatch(updateEventAction({ id: eventId }));
  };

  const onCreateEventInterestsSuccess = async () => {
    try {
      setInterestFormDisabled(true);

      const { isConfirmed } = await alert.fire({
        title: "Event Created Success!",
        text: "Do you want to create another event?",
        icon: "success",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Go Back",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        reverseButtons: true,
      });

      // remove the event in redux
      dispatch(updateEventAction(null));

      if (isConfirmed) {
        setEventId("");
        setEventFormDisabled(false);
        setInterestFormDisabled(false);
        // reset all states
        dispatch(resetEventFormAction());
      } else {
        // go to manage events
        navigate(allRoutes.events.index.path);
      }
    } catch (error) {
      console.log("onCreateEventInterestsSuccess ~ error", error);
    }
  };

  return (
    <div className="row clearfix pb-50">
      {/* create event form */}
      <div className="col-lg-6 col-md-12">
        <ManageEventForm
          formDisabled={eventFormDisabled}
          formTitle="New Event Form"
          onSuccess={onCreateEventSuccess}
        />
      </div>

      {/* list of interests form */}
      {eventId ? (
        <div className="col-lg-3 col-md-6">
          <ManageInterestForm
            formDisabled={interestFormDisabled}
            onSuccess={onCreateEventInterestsSuccess}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CreateEvent;
