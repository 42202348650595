import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import "./DualSlider.css";

const MultiRangeSlider = ({
  disabled,
  min,
  max,
  minValue,
  maxValue,
  onChange,
}) => {
  // refs
  const minValRef = useRef(minValue);
  const maxValRef = useRef(maxValue);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minValue, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxValue);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxValue, getPercent]);

  return (
    <div className={`mt-20 dimmer ${disabled ? "active" : ""}`}>
      <input
        type="range"
        min={min}
        max={max}
        disabled={disabled}
        value={minValue}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxValue - 1);
          onChange({ minValue: value, maxValue });
          minValRef.current = value;
        }}
        className="thumb thumb--left"
        // style={{ zIndex: minValue > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        disabled={disabled}
        value={maxValue}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minValue + 1);
          onChange({ minValue, maxValue: value });
          maxValRef.current = value;
        }}
        className="thumb thumb--right"
      />

      {/* slider thumbs */}
      <div className="slider dimmer-content">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>

      {/* value */}
      <div className="slider__value dimmer-content">
        <div className="slider__left-value">{minValue}</div>
        <div className="slider__right-value">{maxValue}</div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
