import _ from "lodash";
import moment from "moment";
import React from "react";

import Table from "components/tables/Table";

const EventTable = (props) => {
  // variables
  const {
    loading,
    events,
    pageNumber = 1,
    pageLimit,
    showDuplicateButton = true,
    showEditButton = true,
    onEditClick = () => {},
    onDuplicateClick = () => {},
    onDeleteClick = () => {},
    onPreviousClick,
    onNextClick,
    hasPreviousPage,
    hasNextPage,
  } = props;
  const startOrderNumber = (pageNumber - 1) * pageLimit + 1;

  if (events.length === 0 && loading) {
    // show loading
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mt-20">Getting list of events...</div>
      </div>
    );
  }

  return (
    <Table
      loading={loading}
      pageNumber={pageNumber}
      showPagination={true}
      header={
        <tr>
          <th className="w40">#</th>
          <th>Event</th>
          <th>Address</th>
          <th>Date</th>
          <th>Participants</th>
        </tr>
      }
      body={events.map((event, index) => {
        const startDate = event.startDate ? moment(event.startDate) : null;
        const endDate = event.endDate ? moment(event.endDate) : null;
        const isSameDate = !!startDate?.isSame(endDate, "day");

        return (
          <tr key={event.id}>
            {/* order number */}
            <td>{_.padStart(startOrderNumber + index, 2, "0")}</td>

            {/* event title and location name */}
            <td>
              <h6 className="mb-0">{event.title}</h6>
              <span>@{event.locationName}</span>
            </td>

            {/* location address */}
            <td>
              <span>
                <i className="fe fe-map-pin" /> {event.locationAddress}
              </span>
            </td>

            {/* event date */}
            <td style={styles.dateContainer}>
              <i className="fe fe-calendar mt-1 mr-10" />
              <span style={{ flexGrow: 1, flexDirection: "row" }}>
                {startDate ? (
                  <>
                    <div>
                      {isSameDate
                        ? startDate.format("DD MMM YYYY")
                        : endDate
                        ? `${startDate.format(
                            "DD MMM YYYY"
                          )} - ${endDate.format("DD MMM YYYY")}`
                        : startDate.format("DD MMM YYYY")}
                    </div>
                    <div className="text-primary">
                      {isSameDate
                        ? `${startDate.format("h:mm a")} - ${endDate.format(
                            "h:mm a"
                          )}`
                        : endDate
                        ? `${startDate.format("h:mm a")} - ${endDate.format(
                            "h:mm a"
                          )}`
                        : startDate.format("h:mm a")}
                    </div>
                  </>
                ) : (
                  <>
                    <div>-</div>
                    <div>&nbsp;</div>
                  </>
                )}
              </span>
            </td>

            {/* participants */}
            <td>
              <span>
                <div className="text-success">
                  <b>{event.participants?.going}</b> Going
                </div>
                <div className="text-info">
                  <b>{event.participants?.interested}</b> Interested
                </div>
              </span>
            </td>

            {/* action buttons */}
            <td className="text-center">
              <div className="item-action dropdown">
                {/* more button */}
                <i
                  className="fe fe-more-vertical cursor-pointer"
                  data-toggle="dropdown"
                />

                <div className="dropdown-menu dropdown-menu-right">
                  {/* edit button */}
                  {showEditButton ? (
                    <div
                      className="dropdown-item"
                      onClick={() => onEditClick(event)}
                    >
                      <i className="dropdown-icon icon-note" /> Edit
                    </div>
                  ) : null}

                  {/* duplicate button */}
                  {showDuplicateButton ? (
                    <div
                      className="dropdown-item"
                      onClick={() => onDuplicateClick(event)}
                    >
                      <i className="dropdown-icon icon-docs" /> Duplicate
                    </div>
                  ) : null}

                  {/* delete button */}
                  <div
                    className="dropdown-item text-danger"
                    onClick={() => onDeleteClick(event)}
                  >
                    <i className="dropdown-icon icon-trash" /> Delete
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
    />
  );
};

export default EventTable;

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3rem",
  },
  dateContainer: {
    display: "flex",
    flexGrow: 1,
  },
};
