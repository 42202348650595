import axios, { errorHandler, preAuthAxios } from "api";
import config from "config";

export const resetPasswordAPI = async (email) => {
  try {
    const instance = await preAuthAxios();
    const response = await instance.post(config.EP_RESET_PASSWORD, {
      email,
    });
    return response.data;
  } catch (error) {
    console.log("resetPasswordAPI ~ error", error);
    return errorHandler(error);
  }
};

export const getUsersAPI = async ({ nextDocId = null }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_GET_USERS, {
      nextDocId,
    });
    return response.data;
  } catch (error) {
    console.log("getUsersAPI ~ error", error);
    return errorHandler(error);
  }
};

export const updateProfileVerificationAPI = async ({ userId, status }) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      config.EP_UPDATE_PROFILE_VERIFICATION,
      { userId, status }
    );
    return response.data;
  } catch (error) {
    console.log("updateProfileVerificationAPI ~ error", error);
    return errorHandler(error);
  }
};

export const logoutUserAllSessionsAPI = async ({ userId }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_LOGOUT_ALL_SESSIONS, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.log("logoutUserAllSessionsAPI ~ error", error);
    return errorHandler(error);
  }
};

export const blockUserAccountAPI = async ({ userId, permanent }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_BLOCK_USER_ACCOUNT, {
      userId,
      permanent,
    });
    return response.data;
  } catch (error) {
    console.log("blockUserAccountAPI ~ error", error);
    return errorHandler(error);
  }
};

export const unblockUserAccountAPI = async ({ userId }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_UNBLOCK_USER_ACCOUNT, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.log("unblockUserAccountAPI ~ error", error);
    return errorHandler(error);
  }
};
