import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { errorAlert } from "utils/helpers";
import { getCalloutsAPI } from "api/callout";
import CalloutTable from "components/tables/CalloutTable";
import { DRAFT, HISTORY, INITIAL_PAGINATION, PUBLISHED } from "utils/constants";
import { updateCalloutReducerAction } from "stores/actions/calloutAction";

const TAB_ITMES = {
  published: { id: 0, title: "Active" },
  draft: { id: 1, title: "Draft" },
  history: { id: 2, title: "Past" },
};

const Callout = () => {
  // hooks
  const dispatch = useDispatch();
  const {
    activeTab,
    publishedCallouts,
    publishedCalloutsPagination,
    draftCallouts,
    draftCalloutsPagination,
    historyCallouts,
    historyCalloutsPagination,
  } = useSelector((state) => state.calloutReducer || {});

  // states
  const [loading, setLoading] = useState(true);

  // variables for published callout table
  const hasPreviousPublishedCallouts = publishedCalloutsPagination.index > 0;
  const hasNextPublishedCallouts =
    publishedCalloutsPagination.index < publishedCallouts.length - 1 ||
    !!publishedCalloutsPagination.nextDocId;

  // variables for draft callout table
  const hasPreviousDraftCallouts = draftCalloutsPagination.index > 0;
  const hasNextDraftCallouts =
    draftCalloutsPagination.index < draftCallouts.length - 1 ||
    !!draftCalloutsPagination.nextDocId;

  // variables for history callout table
  const hasPreviousHistoryCallouts = historyCalloutsPagination.index > 0;
  const hasNextHistoryCallouts =
    historyCalloutsPagination.index < historyCallouts.length - 1 ||
    !!historyCalloutsPagination.nextDocId;

  useEffect(() => {
    loadInitialCallouts();
  }, []);

  const loadInitialCallouts = async () => {
    try {
      if (!_.isEmpty(publishedCallouts[0])) {
        return setLoading(false);
      }

      const getPublishedCalloutRes = await getCalloutsAPI({ type: PUBLISHED });

      let tempPublishedCallouts = [[]];
      let tempPublishedCalloutsPagination = INITIAL_PAGINATION;

      if (getPublishedCalloutRes.code !== 200 && activeTab === 0) {
        throw new Error(getPublishedCalloutRes.message);
      } else {
        const { callouts, nextDocId, limit } = getPublishedCalloutRes.results;
        tempPublishedCallouts = [callouts];
        tempPublishedCalloutsPagination = { index: 0, nextDocId, limit };
      }

      dispatch(
        updateCalloutReducerAction({
          publishedCallouts: tempPublishedCallouts,
          publishedCalloutsPagination: tempPublishedCalloutsPagination,
        })
      );

      setLoading(false);
    } catch (error) {
      console.log("getCallouts ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const onTabClick = async (index) => {
    // get tab pane element and hide the tab
    const element = document.getElementById("callout-tab-pane");
    element.classList.remove("show");

    setTimeout(() => {
      // change the tab
      dispatch(updateCalloutReducerAction({ activeTab: index }));
      // show the new tab
      element.classList.add("show");
    }, 200);

    if (index === TAB_ITMES.history.id && _.isEmpty(historyCallouts[0])) {
      // get history callouts
      try {
        setLoading(true);
        const getHistoryCalloutRes = await getCalloutsAPI({ type: HISTORY });

        if (getHistoryCalloutRes.code !== 200) {
          throw new Error(getHistoryCalloutRes.message);
        } else {
          const { callouts, nextDocId, limit } = getHistoryCalloutRes.results;
          dispatch(
            updateCalloutReducerAction({
              historyCallouts: [callouts],
              historyCalloutsPagination: { index: 0, nextDocId, limit },
            })
          );
          setLoading(false);
        }
      } catch (error) {
        console.log("getHistoryCallouts ~ error", error);
        setLoading(false);
        errorAlert(error.message);
      }
    } else if (index === TAB_ITMES.draft.id && _.isEmpty(draftCallouts[0])) {
      // get history callouts
      try {
        setLoading(true);
        const getDraftCalloutRes = await getCalloutsAPI({ type: DRAFT });

        if (getDraftCalloutRes.code !== 200) {
          throw new Error(getDraftCalloutRes.message);
        } else {
          const { callouts, nextDocId, limit } = getDraftCalloutRes.results;
          dispatch(
            updateCalloutReducerAction({
              draftCallouts: [callouts],
              draftCalloutsPagination: { index: 0, nextDocId, limit },
            })
          );
          setLoading(false);
        }
      } catch (error) {
        console.log("getDraftCalloutRes ~ error", error);
        setLoading(false);
        errorAlert(error.message);
      }
    }
  };

  const onNextClick = async (
    callouts,
    calloutFieldName,
    pagination,
    calloutPaginationFieldName
  ) => {
    try {
      setLoading(true);
      const nextIndex = pagination.index + 1;

      if (_.isEmpty(callouts[nextIndex])) {
        // no callouts in local state, get the next callouts from the API
        const getCalloutRes = await getCalloutsAPI({
          type: PUBLISHED,
          nextDocId: pagination.nextDocId,
        });

        if (getCalloutRes.code !== 200) {
          throw new Error(getCalloutRes.message);
        } else {
          // add next callouts to the local state
          const nextIndex = pagination.index + 1;
          const { nextDocId, limit } = getCalloutRes.results;
          callouts[nextIndex] = getCalloutRes.results.callouts;
          dispatch(
            updateCalloutReducerAction({
              [calloutFieldName]: [...callouts],
              [calloutPaginationFieldName]: {
                index: nextIndex,
                nextDocId,
                limit,
              },
            })
          );
          setLoading(false);
        }
      } else {
        dispatch(
          updateCalloutReducerAction({
            [calloutPaginationFieldName]: { ...pagination, index: nextIndex },
          })
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  const onPreviousClick = async (pagination, calloutPaginationFieldName) => {
    try {
      const previousIndex = pagination.index - 1;
      if (previousIndex <= 0) {
        dispatch(
          updateCalloutReducerAction({
            [calloutPaginationFieldName]: { ...pagination, index: 0 },
          })
        );
      } else {
        dispatch(
          updateCalloutReducerAction({
            [calloutPaginationFieldName]: {
              ...pagination,
              index: previousIndex,
            },
          })
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  return (
    <>
      {/* header */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={styles.tabContainer}
      >
        {/* tab */}
        <ul className="nav nav-tabs page-header-tab">
          {_.map(TAB_ITMES, (item) => (
            <li key={item.id} className="nav-item">
              <span
                className={`nav-link ${activeTab === item.id ? "active" : ""}`}
                data-toggle="tab"
                onClick={() => onTabClick(item.id)}
              >
                {item.title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="tab-content pb-50">
        <div
          id="callout-tab-pane"
          className="tab-pane show fade active"
          role="tabpanel"
        >
          {/* published callouts */}
          {activeTab === TAB_ITMES.published.id ? (
            <CalloutTable
              loading={loading}
              callouts={publishedCallouts[publishedCalloutsPagination.index]}
              pageNumber={publishedCalloutsPagination.index + 1}
              pageLimit={publishedCalloutsPagination.limit}
              onPreviousClick={() =>
                onPreviousClick(
                  publishedCalloutsPagination,
                  "publishedCalloutsPagination"
                )
              }
              onNextClick={() =>
                onNextClick(
                  publishedCallouts,
                  "publishedCallouts",
                  publishedCalloutsPagination,
                  "publishedCalloutsPagination"
                )
              }
              hasPreviousPage={hasPreviousPublishedCallouts}
              hasNextPage={hasNextPublishedCallouts}
            />
          ) : null}

          {/* draft callouts */}
          {activeTab === TAB_ITMES.draft.id ? (
            <CalloutTable
              loading={loading}
              showDuplicateButton={false}
              callouts={draftCallouts[draftCalloutsPagination.index]}
              pageNumber={draftCalloutsPagination.index + 1}
              pageLimit={draftCalloutsPagination.limit}
              onPreviousClick={() =>
                onPreviousClick(
                  draftCalloutsPagination,
                  "draftCalloutsPagination"
                )
              }
              onNextClick={() =>
                onNextClick(
                  draftCallouts,
                  "draftCallouts",
                  draftCalloutsPagination,
                  "draftCalloutsPagination"
                )
              }
              hasPreviousPage={hasPreviousDraftCallouts}
              hasNextPage={hasNextDraftCallouts}
            />
          ) : null}

          {/* past callouts */}
          {activeTab === TAB_ITMES.history.id ? (
            <CalloutTable
              loading={loading}
              showEditButton={false}
              callouts={historyCallouts[historyCalloutsPagination.index]}
              pageNumber={historyCalloutsPagination.index + 1}
              pageLimit={historyCalloutsPagination.limit}
              onPreviousClick={() =>
                onPreviousClick(
                  historyCalloutsPagination,
                  "historyCalloutsPagination"
                )
              }
              onNextClick={() =>
                onNextClick(
                  historyCallouts,
                  "historyCallouts",
                  historyCalloutsPagination,
                  "historyCalloutsPagination"
                )
              }
              hasPreviousPage={hasPreviousHistoryCallouts}
              hasNextPage={hasNextHistoryCallouts}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Callout;

const styles = {
  tabContainer: {
    marginTop: "-1rem",
  },
};
