import { Outlet } from "react-router-dom";

import sliderImage from "assets/images/slider.svg";

const AuthLayout = (props) => {
  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <Outlet />
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={sliderImage} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Welcome to Crewmen Portal</h4>
                <p>A portal that manage all sevices in one place.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
