import React from "react";
import { capitalizeEachWord, humanReadableDate } from "utils/helpers";

const ChatMessageCard = (props) => {
  // variables
  const { align, user, message, date } = props;

  return (
    <div
      style={{
        ...styles.messageContainer,
        justifyContent: align === "left" ? "flex-start" : "flex-end",
      }}
    >
      {/* user profile */}
      {align === "left" ? (
        <div style={styles.profileImageContainer}>
          {user ? (
            user?.profileImage?.url ? (
              <img
                title={capitalizeEachWord(
                  `${user?.firstName} ${user?.lastName}`
                )}
                style={styles.profileImage}
                src={user?.profileImage?.url}
                data-toggle="tooltip"
                data-placement="top"
                alt="Avatar"
                className="avatar"
                data-original-title="Avatar Name"
              />
            ) : (
              <span
                title={capitalizeEachWord(
                  `${user?.firstName} ${user?.lastName}`
                )}
                className="avatar avatar-green text-uppercase"
                data-toggle="tooltip"
                data-placement="top"
                data-original-title="Avatar Name"
              >
                {`${user?.firstName[0]}${user?.lastName[0]}`}
              </span>
            )
          ) : null}
        </div>
      ) : null}

      {/* chat message container */}
      <div
        style={{
          ...styles.message,
          backgroundColor: align === "left" ? "#F2F2F2" : "#F68D2E",
          color: align === "left" ? "inherit" : "#ffffff",
        }}
      >
        {/* chat message */}
        <div style={{ textAlign: align }}>{message}</div>

        {/* date time */}
        {date ? (
          <div style={{ ...styles.date, textAlign: "right" }}>
            {humanReadableDate(date)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatMessageCard;

const styles = {
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0.2rem",
    alignItems: "flex-end",
  },
  profileImageContainer: {
    height: 40,
    width: 40,
    marginRight: "0.5rem",
  },
  profileImage: {
    height: 40,
    width: 40,
    objectFit: "cover",
  },
  message: {
    padding: "0.5rem 1rem",
    maxWidth: "80%",
    borderRadius: 12,
  },
  date: {
    fontSize: "0.75rem",
    fontWeight: 500,
  },
};
