import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import config from "config";

const AutoCompleteAddress = (props) => {
  // hooks
  const resetEventForm = useSelector(
    (state) => state.formReducer?.resetEventForm
  );
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: config.GOOGLE_PLACE_API_KEY,
    debounce: 500,
    language: "au",
    options: {
      componentRestrictions: { country: "au" },
    },
  });

  // refs
  const searchContainerRef = useRef(null);
  const searchInputRef = useRef(null);

  // states
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(false);

  // variables
  const {
    disabled: disabledInput,
    defaultValue,
    label,
    required,
    placeholder,
    onSelected,
    errorMessage,
  } = props;

  // handle default value of auto complete input
  useEffect(() => {
    if (!value && !_.isEmpty(defaultValue)) {
      setValue(defaultValue.description);
      defaultValue?.geoPoint && setDisabled(true);
    }
  }, [defaultValue]);

  // clear the input
  useEffect(() => {
    disabled && setDisabled(false);
    getPlacePredictions({ input: "" });
    setValue("");
    // clear the selected address to parent
    onSelected(null);
  }, [resetEventForm]);

  const onClearClick = () => {
    disabled && setDisabled(false);
    getPlacePredictions({ input: "" });
    setValue("");

    // clear the selected address to parent
    onSelected(null);

    // focus search input
    setTimeout(() => {
      searchInputRef.current?.focus && searchInputRef.current?.focus();
    }, 100);
  };

  const onTextChange = (event) => {
    const { value } = event.target;
    if (value.length >= 3) {
      getPlacePredictions({ input: value });
    }
    setValue(value);
  };

  const onSuggestionClick = async (place) => {
    try {
      setDisabled(true);
      getPlacePredictions({ input: "" });
      setValue(place.description);

      // get geo point by place id
      placesService.getDetails({ placeId: place.place_id }, (details) => {
        onSelected({
          ...place,
          geoPoint: {
            latitude: details?.geometry?.location?.lat(),
            longitude: details?.geometry?.location?.lng(),
          },
        });
      });
    } catch (error) {
      console.log("onSuggestionClick ~ error", error);
    }
  };

  return (
    <>
      {/* label */}
      <label className="form-label">
        {label} {required ? <span className="text-red">*</span> : null}
      </label>

      {/* input text */}
      <div className="input-icon" ref={searchContainerRef}>
        <input
          id="input-auto-complete"
          ref={searchInputRef}
          disabled={disabled || disabledInput}
          type="text"
          autoComplete="off"
          className={`form-control ${errorMessage && "error"}`}
          placeholder={placeholder}
          onChange={onTextChange}
          value={value}
        />

        {/* suggestion list */}
        {placePredictions.length ? (
          <div
            className="input-suggestion-container"
            style={styles.suggestionContainer}
            tabIndex={1}
          >
            {placePredictions.map((place) => (
              <div
                key={place.place_id}
                className="input-suggestion"
                onClick={() => onSuggestionClick(place)}
              >
                {/* search icon */}
                <i className="fe fe-search mr-15 mt-1" />
                {/* address */}
                <div>{place.description}</div>
              </div>
            ))}
          </div>
        ) : null}

        {/* show loading or search icon */}
        {!disabledInput && (isPlacePredictionsLoading || value) ? (
          <span
            className="input-group-addon"
            onClick={value ? onClearClick : null}
          >
            {isPlacePredictionsLoading ? (
              <div
                className="spinner-border text-primary spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i className="fe fe-x" tabIndex="-1" />
            )}
          </span>
        ) : null}
      </div>

      {errorMessage ? (
        <div className="input-invalid">{errorMessage}</div>
      ) : null}
    </>
  );
};

export default AutoCompleteAddress;

const styles = {
  suggestionContainer: {
    zIndex: 6,
    position: "absolute",
    marginTop: "0.5rem",
    width: "100%",
    backgroundColor: "white",
    maxHeight: "200px",
    border: "1px solid #e8e9e9",
    borderRadius: "0.25rem",
    overflow: "hidden",
    overflowY: "scroll",
  },
};
