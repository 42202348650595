import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userInfoHelper, userSignOut } from "lib/firebase";
import { allRoutes } from "routes";
import {
  capitalizeEachWord,
  humanReadableDate,
  onNotificationClick,
  seenNotifications,
} from "utils/helpers";
import Loader from "components/Loader";
import { HEADER_NOTIFICATIONS_LIMIT_DOCS } from "utils/constants";

const DashboardHeader = (props) => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();

  // states
  const [user, setUser] = useState(null);

  // variables
  const allNotifications = useSelector(
    (state) => state.notificationReducer?.allNotifications
  );
  const loadingNotifications = useSelector(
    (state) => state.notificationReducer?.loadingNotifications
  );
  const settingReducer = useSelector((state) => state.settings);
  const { fixNavbar, darkHeader } = settingReducer;
  const previewNotifiations =
    allNotifications.slice(0, HEADER_NOTIFICATIONS_LIMIT_DOCS) || [];

  useEffect(() => {
    userInfoHelper().then((userInfo) => setUser(userInfo));
  }, []);

  useEffect(() => {}, [allNotifications]);

  const onSignOutClick = async () => {
    // remove all user credentials
    await userSignOut();

    // take user to login page
    navigate(allRoutes.auth.login.path);
  };

  const onViewAllNotificationsClick = () => {
    navigate(allRoutes.notifications.index);
  };

  return (
    <div>
      <div
        id="page_top"
        className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
          darkHeader ? "top_dark" : ""
        }`}
      >
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
              {/* header title */}
              <h1 className="page-title">
                {allRoutes.dashboard.index.path === location.pathname
                  ? `${capitalizeEachWord(user?.role)} ${props.title}`
                  : props.title}
              </h1>
            </div>

            <div className="right">
              <div className="notification d-flex">
                {/* notification dropdown */}
                <div className="dropdown d-flex">
                  <i
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                    onClick={seenNotifications}
                  >
                    <i className="fa fa-bell" />
                    <span
                      className={`badge badge-primary ${
                        !!_.find(previewNotifiations, { seen: false })
                          ? "nav-unread"
                          : ""
                      }`}
                    />
                  </i>

                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                    style={styles.notificationContainer}
                  >
                    {/* loading mask */}
                    {loadingNotifications ? (
                      <div style={styles.loadingNotificationContainer}>
                        <Loader className="text-primary mb-10" />
                        Loading...
                      </div>
                    ) : null}

                    {!loadingNotifications ? (
                      <>
                        <ul className="list-unstyled feeds_widget">
                          {previewNotifiations.map((notification) => {
                            return (
                              <li
                                key={`${notification.id}:${notification.createdAt}`}
                                className="notification-card"
                                onClick={() =>
                                  onNotificationClick(notification, navigate)
                                }
                              >
                                <div
                                  className="feeds-left"
                                  style={{ position: "relative" }}
                                >
                                  <i className="icon-speech" />
                                  {/* unread badge */}
                                  {!notification.viewed ? (
                                    <i
                                      className="fa fa-circle text-primary"
                                      style={styles.unreadBadge}
                                    />
                                  ) : null}
                                </div>
                                <div className="feeds-body">
                                  <h4 className="title text-warning">
                                    {notification.title}{" "}
                                    <small className="float-right text-muted">
                                      {humanReadableDate(
                                        notification.createdAt
                                      )}
                                    </small>
                                  </h4>
                                  <small> {notification.body}</small>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                        {allNotifications?.length >
                        HEADER_NOTIFICATIONS_LIMIT_DOCS ? (
                          <>
                            <div className="dropdown-divider" />
                            <div
                              href="fake_url"
                              className="dropdown-item text-center text-muted-dark readall"
                              onClick={onViewAllNotificationsClick}
                            >
                              View all notifications
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>

                {/* user dropdown */}
                <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-user" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <span className="dropdown-item">
                      <i className="dropdown-icon fe fe-user" />
                      {` ${capitalizeEachWord(
                        user?.firstName
                      )} ${capitalizeEachWord(user?.lastName)}`}
                    </span>
                    {/* <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-settings" /> Settings
                    </a> */}
                    <div className="dropdown-divider" />
                    {/* <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-help-circle" /> Need
                      help?
                    </a> */}
                    <span className="dropdown-item" onClick={onSignOutClick}>
                      <i className="dropdown-icon fe fe-log-out" /> Sign out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;

const styles = {
  notificationContainer: {
    width: "350px",
  },
  loadingNotificationContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
  unreadBadge: {
    position: "absolute",
    top: "-2px",
    right: "8px",
    fontSize: "10px",
  },
};
