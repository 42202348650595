import { combineReducers } from "redux";

import appReducer from "stores/reducers/appReducer";
import authReducer from "stores/reducers/authReducer";
import calloutReducer from "stores/reducers/calloutReducer";
import eventChatReducer from "stores/reducers/eventChatReducer";
import eventReducer from "stores/reducers/eventReducer";
import formReducer from "stores/reducers/formReducer";
import imageReducer from "stores/reducers/imageReducer";
import interestReducer from "stores/reducers/interestReducer";
import notificationReducer from "stores/reducers/notificationReducer";
import settings from "stores/reducers/settingReducer";
import userProfileReducer from "stores/reducers/userProfileReducer";
import userReducer from "stores/reducers/userReducer";

export default combineReducers({
  appReducer,
  authReducer,
  calloutReducer,
  eventChatReducer,
  eventReducer,
  formReducer,
  imageReducer,
  interestReducer,
  notificationReducer,
  settings,
  userProfileReducer,
  userReducer,
});
