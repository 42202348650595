import _ from "lodash";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { allRoutes } from "routes";
import Logo from "assets/images/logo.svg";
import { errorAlert, validateEmail } from "utils/helpers";
import { firebaseAuth, getUserRoleClaim, userSignOut } from "lib/firebase";
import { ALLOWED_ROLES } from "utils/constants";

const Login = () => {
  // hooks
  const navigate = useNavigate();

  // states
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const onLoginClick = async (event) => {
    try {
      event.preventDefault();

      // check if all text field are input correctly
      let errorMessages = [];
      if (!email) {
        errorMessages.push("Email cannot be empty.");
      } else if (!validateEmail(email)) {
        errorMessages.push("Please enter proper email address.");
      }

      if (!password) {
        errorMessages.push("Password cannot be empty.");
      }

      if (!_.isEmpty(errorMessages)) {
        // found error, show the messages
        errorAlert(errorMessages.join("\n"));
        return;
      }

      setLoading(true);

      // login to firebase
      await setPersistence(firebaseAuth, browserSessionPersistence); // store token locally
      await signInWithEmailAndPassword(firebaseAuth, email, password);

      // check if user role
      const role = await getUserRoleClaim();

      if (!ALLOWED_ROLES.includes(role)) {
        // sign out user before showing error
        await userSignOut();

        throw new Error(
          "Your account is not allowed to this platform. Please contact support."
        );
      }

      setLoading(false);

      // go to dashboard
      navigate(allRoutes.dashboard.index.path);
    } catch (error) {
      console.log("onLoginClick ~ error", error);

      // stop loading
      setLoading(false);

      let message = error.message;

      if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found"
      ) {
        message =
          "Invalid email or password. Please check your login credential and try again.";
      }

      // show error message
      errorAlert(message);
    }
  };

  return (
    <>
      {/* logo */}
      <div className="text-center mb-2">
        <ReactSVG className="brand-logo" src={Logo} />
      </div>

      <div className="card-body">
        <form onSubmit={onLoginClick}>
          {/* title */}
          <div className="card-title">Login to your account</div>

          {/* email input */}
          <div className="form-group">
            <label className="form-label">
              Email address <span className="text-red">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email address"
              disabled={loading}
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
          </div>

          {/* password input */}
          <div className="form-group">
            <label className="form-label">
              Password <span className="text-red">*</span>
              <Link
                className="float-right small"
                to={allRoutes.auth.forgotPassword.path}
                tabIndex="-1" // ignore tab button from focusing this element
              >
                I forgot password
              </Link>
            </label>
            <div className="input-group-container">
              <input
                type={hidePassword ? "password" : "text"}
                className="form-control"
                style={{ paddingRight: "2.5rem" }}
                id="exampleInputPassword1"
                placeholder="Password"
                disabled={loading}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
              />

              {/* eye icon */}
              <div
                className="input-group-addon"
                onClick={() => setHidePassword(!hidePassword)}
              >
                <i
                  className={`fa ${hidePassword ? "fa-eye-slash" : "fa-eye"}`}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>

          {/* login button */}
          <div className="form-footer">
            <input
              className="btn btn-primary btn-block"
              type="submit"
              value={loading ? "Logging in..." : "Login"}
              disabled={loading || !email || !password}
              onClick={onLoginClick}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
