const initialState = {
  formMode: "",
  event: null,
  selectedChat: null,
  allEventChatList: [],
  allEventDetails: {}, // store all event details for efficiency
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_EVENT":
      return {
        ...state,
        event: action.payload,
      };

    case "UPDATE_EVENT_FORM_MODE":
      return {
        ...state,
        formMode: action.payload,
      };

    case "UPDATE_SELECTED_CHAT_EVENT":
      return {
        ...state,
        selectedChat: action.payload,
      };

    case "UPDATE_EVENT_CHAT_LIST":
      return {
        ...state,
        allEventChatList: action.payload,
      };

    case "UPDATE_ALL_EVENT_DETAILS":
      return {
        ...state,
        allEventDetails: { ...state.allEventDetails, ...action.payload },
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default eventReducer;
