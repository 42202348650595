import _ from "lodash";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";

import {
  EDITOR_LABELS,
  EDITOR_STYLE,
  SOMETHING_WENT_WRONG,
  TOOLBAR,
} from "utils/constants";
import { convertHtmlToDraft, errorAlert } from "utils/helpers";
import { deleteEventFaqAPI, manageEventFaqAPI } from "api/event";
import { alert } from "utils/alert";
import Loader from "components/Loader";
import { updateEventAction } from "stores/actions/eventAction";

const FAQModal = (props) => {
  // hooks
  const dispatch = useDispatch();
  const event = useSelector((state) => state.eventReducer.event);

  // states
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [question, setQuestion] = useState(EditorState.createEmpty());
  const [answer, setAnswer] = useState(EditorState.createEmpty());

  // variables
  const eventId = event?.id;
  const { faq, isOpen, onRequestClose } = props;
  const isFaqExsited = !_.isEmpty(faq);

  useEffect(() => {
    // eventId or faq are changed, reset the question and answer
    setQuestion(EditorState.createEmpty());
    setAnswer(EditorState.createEmpty());
  }, [eventId, faq]);

  useEffect(() => {
    if (isFaqExsited) {
      // replace faq content in the text editor
      setQuestion(convertHtmlToDraft(faq.question));
      setAnswer(convertHtmlToDraft(faq.answer));
    }
  }, [faq]);

  const onSubmitClick = async () => {
    try {
      const rawQuestion = draftToHtmlPuri(
        convertToRaw(question.getCurrentContent())
      ).trim();
      const rawAnswer = draftToHtmlPuri(
        convertToRaw(answer.getCurrentContent())
      ).trim();

      // validate if the question or answer is empty
      const emptyText = "<p></p>";
      if (rawQuestion === emptyText || rawAnswer === emptyText) {
        return errorAlert("Both question and answer cannot be empty.");
      }

      setLoading(true);

      const manageFaqRes = await manageEventFaqAPI({
        eventId,
        faqId: faq?.id,
        question: rawQuestion,
        answer: rawAnswer,
      });

      if (manageFaqRes.code !== 200) {
        throw new Error(manageFaqRes.message);
      }

      // add new faq to event in redux
      let newFAQs = event?.faq || [];
      if (faq?.id) {
        // modified existing FAQ
        newFAQs = _.map(newFAQs, (item) => {
          if (item.id === faq.id) {
            item.question = rawQuestion;
            item.answer = rawAnswer;
          }
          return item;
        });
      } else {
        // add new FAQ to the list
        newFAQs.push({
          id: manageFaqRes.results.faqId,
          question: rawQuestion,
          answer: rawAnswer,
        });
      }

      // update event in redux with new FAQ
      dispatch(updateEventAction({ ...event, faq: newFAQs }));

      // close the modal
      setQuestion(EditorState.createEmpty());
      setAnswer(EditorState.createEmpty());
      setLoading(false);
      onRequestClose();
    } catch (error) {
      console.log("onSubmitClick ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const onDeleteClick = async () => {
    try {
      if (!isFaqExsited) {
        console.error("onDeleteClick ~ error", "Missing FAQ details.");
        return errorAlert(SOMETHING_WENT_WRONG);
      }

      const { isConfirmed } = await alert.fire({
        title: "Delete Event?",
        text: `Do you want to delete this FAQ? Once deleted, you will not be able to recover this FAQ.`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Delete",
        confirmButtonColor: "#eb1e1e",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setDeleting(true);

        const deleteFaqRes = await deleteEventFaqAPI(faq.id);

        if (deleteFaqRes.code !== 200) {
          throw new Error(deleteFaqRes.message);
        }

        // delete FAQ in redux
        let newFAQs = _.remove(event?.faq || [], (item) => item.id !== faq.id);

        // update event in redux
        dispatch(updateEventAction({ ...event, faq: newFAQs }));

        // reset state to initial state
        setQuestion(EditorState.createEmpty());
        setAnswer(EditorState.createEmpty());
        setDeleting(false);

        // close modal
        onRequestClose();

        // show success modal
        alert.fire({
          title: "Delete FAQ Success",
          text: `The FAQ has been successfully deleted.`,
          icon: "success",
        });
      }
    } catch (error) {
      setDeleting(false);
      console.log("onDeleteClick ~ error", error);
      errorAlert(error.message);
    }
  };

  return (
    <Modal
      id="faq-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      {/* modal header */}
      <div className="card-header">
        {/* modal title */}
        <h3 className="card-title">
          {isFaqExsited ? "Manage" : "Add New"} FAQ
        </h3>

        <div className="card-options">
          <span className="cursor-pointer" onClick={onRequestClose}>
            <i className="fe fe-x" />
          </span>
        </div>
      </div>

      {/* modal body content */}
      <div
        className={`card-body dimmer ${loading ? "active" : ""}`}
        style={styles.modalBodyContainer}
      >
        {/* question editor */}
        <div className="form-group dimmer-content">
          <label className="form-label">Question:</label>
          <Editor
            readOnly={loading}
            editorStyle={EDITOR_STYLE}
            handlePastedText={() => false}
            localization={{ locale: "en", translations: EDITOR_LABELS }}
            editorState={question}
            toolbarClassName="faq-text-editor-toolbar"
            wrapperClassName="faq-text-editor-wrapper"
            editorClassName="faq-text-editor"
            onEditorStateChange={setQuestion}
            toolbar={TOOLBAR}
          />
        </div>

        {/* answer editor */}
        <div className="form-group dimmer-content">
          <label className="form-label">Answer:</label>
          <Editor
            readOnly={loading}
            editorStyle={EDITOR_STYLE}
            handlePastedText={() => false}
            localization={{ locale: "en", translations: EDITOR_LABELS }}
            editorState={answer}
            toolbarClassName="faq-text-editor-toolbar"
            wrapperClassName="faq-text-editor-wrapper"
            editorClassName="faq-text-editor"
            onEditorStateChange={setAnswer}
            toolbar={TOOLBAR}
          />
        </div>
      </div>

      {/* modal footer */}
      <div className="card-footer d-flex justify-content-between">
        <div className="">
          {/* delete button */}
          {isFaqExsited ? (
            <button
              disabled={loading}
              type="button"
              className="btn btn-round btn-outline-danger"
              onClick={onDeleteClick}
            >
              {deleting ? (
                <Loader className="spinner-border-sm mr-10" />
              ) : (
                <i className="fe fe-trash mr-10" />
              )}
              {deleting ? "Deleting" : "Delete"}
            </button>
          ) : null}
        </div>

        <div>
          {/* cancel button */}
          <button
            disabled={loading}
            type="button"
            className="btn btn-round btn-outline-primary mr-10"
            onClick={onRequestClose}
          >
            Cancel
          </button>

          {/* add button */}
          <button
            type="button"
            disabled={loading}
            className="btn btn-round btn-primary"
            onClick={onSubmitClick}
          >
            {loading ? (
              <Loader
                className="text-white spinner-border-sm mr-10"
                text={isFaqExsited ? "Updating..." : "Adding..."}
              />
            ) : isFaqExsited ? (
              "Update FAQ"
            ) : (
              "Add FAQ"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FAQModal;

const styles = {
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
    overflowY: "scroll",
  },
};
