import _ from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Swal from "sweetalert2";

import Logo from "assets/images/logo.svg";
import { allRoutes } from "routes";
import { errorAlert, validateEmail } from "utils/helpers";
import { resetPasswordAPI } from "api/user";

const ForgotPassword = (props) => {
  // states
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onResetPasswordClick = async (event) => {
    try {
      event.preventDefault();

      // check if all text field are input correctly
      let errorMessages = [];
      if (!email) {
        errorMessages.push("Email cannot be empty.");
      } else if (!validateEmail(email)) {
        errorMessages.push("Please enter proper email address.");
      }

      if (!_.isEmpty(errorMessages)) {
        // found error, show the messages
        errorAlert(errorMessages.join("\n"));
        return;
      }

      setLoading(true);

      const resetPasswordRes = await resetPasswordAPI(email);

      if (resetPasswordRes.code !== 200) {
        throw new Error(resetPasswordRes.message);
      }

      setLoading(false);
      setEmail("");

      // show success message
      Swal.fire({
        title: "Success",
        text: resetPasswordRes.message,
        icon: "success",
        confirmButtonText: "Got it",
        confirmButtonColor: "#f68d2e",
      });
    } catch (error) {
      console.log("onResetPasswordClick ~ error", error);

      // stop loading
      setLoading(false);

      // show error message
      errorAlert(error.message);
    }
  };

  return (
    <>
      {/* logo */}
      <div className="text-center mb-3">
        <ReactSVG className="brand-logo" src={Logo} />
      </div>

      {/* content */}
      <div className="card-body">
        <form onSubmit={onResetPasswordClick}>
          {/* title */}
          <div className="card-title">Forgot password</div>
          <p className="text-muted">We'll send a recovery link to</p>

          {/* email input */}
          <div className="form-group">
            <label className="form-label" htmlFor="exampleInputEmail1">
              Email address <span className="text-red">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Your email address"
              disabled={loading}
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
          </div>

          {/* reset button */}
          <div className="form-footer">
            <input
              className="btn btn-primary btn-block"
              type="submit"
              value={loading ? "Loading..." : "Reset password"}
              disabled={loading || !email}
              onClick={onResetPasswordClick}
            />
          </div>
        </form>
      </div>

      {/* footer */}
      <div className="text-center text-muted">
        Forget it, <Link to={allRoutes.auth.login.path}>Take me Back</Link> to
        the Login page.
      </div>
    </>
  );
};

export default ForgotPassword;
