import _ from "lodash";
import React, { useEffect, useState } from "react";

import UserTable from "components/tables/UserTable";
import { getUsersAPI } from "api/user";
import { errorAlert, getImageUrl } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { updateUserReducerAction } from "stores/actions/userAction";

const User = (props) => {
  // hooks
  const dispatch = useDispatch();
  const { allUsers: users, allUsersPagination: usersPagination } = useSelector(
    (state) => state.userReducer || {}
  );

  // states
  const [loading, setLoading] = useState(true);

  // variables
  const hasPreviousUsers = usersPagination.index > 0;
  const hasNextUsers =
    usersPagination.index < users.length - 1 || !!usersPagination.nextDocId;

  useEffect(() => {
    loadInitialUsers();
  }, []);

  const loadInitialUsers = async () => {
    try {
      if (!_.isEmpty(users[0])) {
        return setLoading(false);
      }

      const getUserRes = await getUsersAPI({ nextDocId: "" });
      if (getUserRes.code !== 200) {
        throw new Error(getUserRes.message);
      } else {
        const { nextDocId, limit } = getUserRes.results;
        const allUsers = await Promise.all(
          _.map(getUserRes.results.users, async (user) => {
            // get images url
            if (user.profileImage?.imagePath) {
              user.profileImage.url = (
                await getImageUrl(user.profileImage.imagePath)
              )?.imageUrl;
            }
            if (user.coverImage?.imagePath) {
              user.coverImage.url = (
                await getImageUrl(user.coverImage.imagePath)
              )?.imageUrl;
            }
            return user;
          })
        );

        dispatch(
          updateUserReducerAction({
            allUsers: [allUsers],
            allUsersPagination: { index: 0, nextDocId, limit },
          })
        );
      }

      setLoading(false);
    } catch (error) {
      console.log("loadInitialUsers ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const onNextClick = async () => {
    try {
      setLoading(true);
      const nextIndex = usersPagination.index + 1;

      if (_.isEmpty(users[nextIndex])) {
        // no users in local state, get the next users from the API
        const getUserRes = await getUsersAPI({
          nextDocId: usersPagination.nextDocId,
        });

        if (getUserRes.code !== 200) {
          throw new Error(getUserRes.message);
        } else {
          // add next users to the local state
          const nextIndex = usersPagination.index + 1;
          const { nextDocId, limit } = getUserRes.results;
          users[nextIndex] = await Promise.all(
            _.map(getUserRes.results.users, async (user) => {
              // get images url
              if (user.profileImage?.imagePath) {
                user.profileImage.url = (
                  await getImageUrl(user.profileImage.imagePath)
                )?.imageUrl;
              }
              if (user.coverImage?.imagePath) {
                user.coverImage.url = (
                  await getImageUrl(user.coverImage.imagePath)
                )?.imageUrl;
              }
              return user;
            })
          );
          dispatch(
            updateUserReducerAction({
              allUsers: [...users],
              allUsersPagination: { index: nextIndex, nextDocId, limit },
            })
          );
          setLoading(false);
        }
      } else {
        dispatch(
          updateUserReducerAction({
            allUsers: [...users],
            allUsersPagination: { ...usersPagination, index: nextIndex },
          })
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  const onPreviousClick = async () => {
    try {
      const previousIndex = usersPagination.index - 1;
      if (previousIndex <= 0) {
        dispatch(
          updateUserReducerAction({
            allUsersPagination: { ...usersPagination, index: 0 },
          })
        );
      } else {
        dispatch(
          updateUserReducerAction({
            allUsersPagination: { ...usersPagination, index: previousIndex },
          })
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  return (
    <UserTable
      loading={loading}
      showEditButton={false}
      users={users[usersPagination.index]}
      pageNumber={usersPagination.index + 1}
      pageLimit={usersPagination.limit}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
      hasPreviousPage={hasPreviousUsers}
      hasNextPage={hasNextUsers}
    />
  );
};

export default User;
