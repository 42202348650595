import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { firebaseAuth } from "lib/firebase";
import { allRoutes } from "routes";

const PublicRoute = ({ children, redirect = false }) => {
  // hooks
  const navigate = useNavigate();

  // states
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (redirect && firebaseAuth.currentUser) {
      // redirect to home dashboard
      navigate(allRoutes.dashboard.index.path);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return children;
};

export default PublicRoute;
