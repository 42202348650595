import React from "react";
import { useNavigate } from "react-router-dom";

import { humanReadableDate, onNotificationClick } from "utils/helpers";

const NotificationCard = (props) => {
  // hooks
  const navigate = useNavigate();

  // variables
  const { className } = props;
  const { title, body, viewed, createdAt } = props.notification || {};

  return (
    <li
      className={`notification-card ${className}`}
      style={styles.mainContainer}
      onClick={() => onNotificationClick(props.notification, navigate)}
    >
      <div
        className="feeds-left"
        style={{ position: "relative", marginRight: "1rem" }}
      >
        <i className="icon-speech" />
        {/* unread badge */}
        {!viewed ? (
          <i className="fa fa-circle text-primary" style={styles.unreadBadge} />
        ) : null}
      </div>
      <div className="feeds-body">
        <h4
          className="title text-primary"
          style={{ fontWeight: viewed ? 500 : 700 }}
        >
          {title}{" "}
          <small
            className="float-right text-muted"
            style={{ fontWeight: viewed ? 500 : 700 }}
          >
            {humanReadableDate(createdAt)}
          </small>
        </h4>
        <small style={{ fontWeight: viewed ? 500 : 700 }}>{body}</small>
      </div>
    </li>
  );
};

export default NotificationCard;

const styles = {
  mainContainer: {
    borderRadius: "0.8rem",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },
  unreadBadge: {
    position: "absolute",
    top: "-2px",
    right: "8px",
    fontSize: "10px",
  },
};
