import { INITIAL_PAGINATION } from "utils/constants";

const initialState = {
  allUsers: [[]],
  allUsersPagination: INITIAL_PAGINATION,
  allAdminUsers: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_REDUCER":
      return {
        ...state,
        ...action.payload,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
