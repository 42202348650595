import React from "react";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "routes";

const InternalError = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <div className="row clearfix">
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-body">
            <div className="display-3 text-muted mb-5">
              <i className="fa fa-exclamation" /> <b>500</b>
            </div>
            <h1 className="h3 mb-3">Oops... Something went wrong</h1>
            <p className="h6 text-muted font-weight-normal mb-3">
              We are sorry that you encountered an error. Please try to refresh
              this page or contact us if the problem persists.
            </p>
            <button
              className="btn btn-primary text-white"
              onClick={() => navigate(-1)}
            >
              <i className="fe fe-arrow-left mr-2" />
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalError;
