import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import "firebase/storage";
import { getStorage } from "firebase/storage";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/database";
import {
  doc,
  getFirestore,
  collection,
  limit,
  query,
  where,
  getDocs,
  getDoc,
  orderBy,
  startAt,
  startAfter,
} from "firebase/firestore";

import config from "config";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
  USER_INFO,
} from "utils/constants";
import store from "stores";
import { signOutAction } from "stores/actions/authAction";

var firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);

export const db = getFirestore(app);

export const storage = getStorage();

export const getFirestoreDoc = async (
  collectionName,
  docId,
  options = { cursor: false }
) => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (options?.cursor) {
        return docSnap;
      }
      return { ...docSnap.data(), id: docSnap.id };
    } else {
      return NO_MATCHING_DOCUMENTS;
    }
  } catch (error) {
    console.log("getFirestoreDoc ~ error", error);
    return ERROR_GETTING_DOCUMENT;
  }
};

export const getFirestoreMultipleDocs = async (
  collectionName,
  condition = []
) => {
  try {
    let rows = [];
    let docRef = collection(db, collectionName);
    let conditionRef = [];

    // if has condition, add to docRef
    if (condition.length) {
      condition.forEach((con) => {
        conditionRef.push(where(con[0], con[1], con[2]));
      });
    }

    // obtain snapshot of collection no filters
    const docSnap = await getDocs(query(docRef, ...conditionRef));

    if (docSnap.empty) {
      return NO_MATCHING_DOCUMENTS;
    } else {
      // loop each document within collection
      docSnap.forEach((doc) => {
        rows.push({ ...doc.data(), id: doc.id });
      });

      //retrun all documents
      return rows;
    }
  } catch (error) {
    console.log("getFirestoreMultipleDocs ~ error", error);
    return ERROR_GETTING_DOCUMENT;
  }
};

export const getFirestoreMultipleDocsByPagination = async (
  collectionName,
  condition = [],
  {
    startDoc = null,
    startAfterDoc = null,
    order = ["createdAt asc"],
    limitDocs = 50,
  }
) => {
  try {
    let rows = [];
    let docRef = collection(db, collectionName);
    let conditionRef = [];
    let orderRef = [];

    // if has condition, add to docRef
    if (condition.length) {
      condition.forEach((con) => {
        conditionRef.push(where(con[0], con[1], con[2]));
      });
    }

    // manage multiple orders
    if (order.length) {
      order.forEach((o) => {
        const [fieldname = "createdAt", direction = "asc"] = o.split(" ");
        orderRef.push(
          orderBy(fieldname, direction !== "desc" ? "asc" : "desc")
        );
      });
    }

    // obtain snapshot of collection no filters
    const docSnap = await getDocs(
      query(
        docRef,
        ...conditionRef,
        ...orderRef,
        startDoc ? startAt(startDoc || "") : startAfter(startAfterDoc || ""),
        limit(limitDocs)
      )
    );

    if (docSnap.empty) {
      return NO_MATCHING_DOCUMENTS;
    } else {
      // loop each document within collection
      docSnap.forEach((doc) => {
        rows.push({ ...doc.data(), id: doc.id });
      });

      //retrun all documents
      return rows;
    }
  } catch (error) {
    console.log("getFirestoreMultipleDocsByPagination ~ error", error);
    return ERROR_GETTING_DOCUMENT;
  }
};

// handle userInfo in session (for using in component only)
export const userInfoHelper = async () => {
  let userInfo;

  // get userInfo from session
  userInfo = sessionStorage.getItem(USER_INFO);

  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    // get userInfo from firebase
    const user = firebaseAuth.currentUser;
    if (!user) {
      return null;
    }

    const getUserRes = await getFirestoreDoc(config.COLLECTION_USER, user.uid);
    if (
      getUserRes === ERROR_GETTING_DOCUMENT ||
      getUserRes === NO_MATCHING_DOCUMENTS
    ) {
      return null;
    }

    userInfo = getUserRes;
    const userRole = await getUserRoleClaim();

    if (userInfo && userRole) {
      // set session for userInfo
      userInfo.role = userRole;
      window.sessionStorage.setItem(USER_INFO, JSON.stringify(userInfo));
      return userInfo;
    }
  }

  return null;
};

// get user role from firebase custom claims
export const getUserRoleClaim = async () => {
  try {
    const { claims } = await firebaseAuth.currentUser.getIdTokenResult();
    return claims.role; // return the lowest role for security purpose when role not found
  } catch (error) {
    console.log("getUserRoleClaim ~ error", error);
    // return the lowest role for security purpose
    return;
  }
};

export const userSignOut = async () => {
  try {
    await store.dispatch(signOutAction());
  } catch (error) {
    console.log("dispatch sign out ~ error", error);
  }

  try {
    await firebaseAuth.signOut();
  } catch (error) {
    console.log("userSignOut ~ error", error);
  }

  try {
    // clear session storage
    sessionStorage.removeItem(USER_INFO);
  } catch (error) {
    console.log("clear session storage ~ error", error);
  }
};
