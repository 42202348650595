import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Modal from "react-modal";
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";

import "./App.css";

import config from "config";
import { allRoutes } from "routes";
import { userInfoHelper } from "lib/firebase";
import useScript from "hooks/useScript";

// layouts
import AuthLayout from "layouts/AuthLayout";
import MainLayout from "layouts/MainLayout";

// pages
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import { updateChatClockAction } from "stores/actions/appAction";

const App = () => {
  // hooks
  const dispatch = useDispatch();
  useScript({
    url: `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_PLACE_API_KEY}&libraries=places`,
  });

  // states
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Modal.setAppElement("body");

    initializingAuth();

    const interval = setInterval(() => {
      // update chat clock in redux store
      dispatch(updateChatClockAction());
    }, 60000);

    return () => {
      clearInterval(interval); // stop the timer
    };
  }, []);

  const initializingAuth = () => {
    getAuth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // refresh token from being expired
          const token = await getAuth()?.currentUser?.getIdToken(true);
          console.log("getAuth ~ token", token);

          // update user info in the session
          await userInfoHelper();
        }
        setLoading(false);
      } catch (error) {
        console.log("onAuthStateChanged ~ error", error);
        setLoading(false);
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="font-montserrat">
      <Routes>
        {/* authentication routes */}
        <Route element={<AuthLayout />}>
          {/* login page */}
          <Route
            path={allRoutes.auth.login.path}
            element={
              <PublicRoute redirect>{allRoutes.auth.login.element}</PublicRoute>
            }
          />

          {/* forgot password page */}
          <Route
            path={allRoutes.auth.forgotPassword.path}
            element={
              <PublicRoute redirect>
                {allRoutes.auth.forgotPassword.element}
              </PublicRoute>
            }
          />
        </Route>

        {/* app routes */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
