import _ from "lodash";
import Swal from "sweetalert2";
import { ref, getDownloadURL } from "firebase/storage";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

import {
  getFirestoreDoc,
  getFirestoreMultipleDocs,
  getFirestoreMultipleDocsByPagination,
  storage,
} from "lib/firebase";
import {
  ADMIN,
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
  SOMETHING_WENT_WRONG,
} from "./constants";
import config from "config";
import store from "stores";
import { updateInterestAction } from "stores/actions/interestAction";
import { updateUserProfileReducerAction } from "stores/actions/userProfileAction";
import {
  updateAllEventDetailsAction,
  updateSelectedChatEventAction,
} from "stores/actions/eventAction";
import { updateUserReducerAction } from "stores/actions/userAction";
import { seenNotificationAPI, viewedNotificationAPI } from "api/notification";
import { updateNotificationReducerAction } from "stores/actions/notificationAction";
import { allRoutes } from "routes";
import { updateImageReducerAction } from "stores/actions/imageAction";

// validate email address and return boolean
export const validateEmail = (email = "") => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

// capitalize the string
export const capitalize = (word = "") => {
  if (word) {
    word = word.trim();
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return word;
};

// capitalize each word of the string
export const capitalizeEachWord = (word = "") => {
  return word?.trim().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
};

export const convertToMomentInstance = (date) => {
  try {
    let momentDate;

    if (typeof date === "string") {
      momentDate = moment(new Date(date));
    } else if (date instanceof Date) {
      momentDate = moment(date);
    } else if (date instanceof Timestamp) {
      momentDate = moment(date.toDate());
    } else {
      momentDate = moment(date);
    }

    return momentDate;
  } catch (error) {
    return moment(date);
  }
};

export const humanReadableDate = (date) => {
  if (date) {
    date = convertToMomentInstance(date);
    const momentDate = moment(date);
    if (momentDate.isSame(moment().startOf("day"), "d")) {
      // today
      return `${momentDate.format("h:mma")}`;
    }
    if (momentDate.isSame(moment().subtract(1, "days").startOf("day"), "d")) {
      // yesterday
      return `Yesterday ${momentDate.format("h:mma")}`;
    }
    return moment(date).format("l h:mma");
  }
  return date;
};

export const errorAlert = (message) => {
  Swal.fire({
    title: "Error!",
    text: message,
    icon: "error",
    confirmButtonText: "OK",
    confirmButtonColor: "#f68d2e",
  });
};

export const getEventImages = async () => {
  try {
    let images = [];

    const getImageRes = await getFirestoreMultipleDocs(
      config.COLLECTION_COVER_IMAGE,
      [["status", "==", true]]
    );

    if (getImageRes === ERROR_GETTING_DOCUMENT) {
      throw new Error("Error while getting event cover images.");
    }

    if (getImageRes !== NO_MATCHING_DOCUMENTS) {
      await Promise.all(
        getImageRes.map(async (image) => {
          const getImageUrlRes = await getImageUrl(image.imagePath);
          if (getImageUrlRes.status) {
            images.push({ ...image, url: getImageUrlRes.imageUrl });
          }
        })
      );
    }

    images = _.orderBy(images, ["createdAt"], ["desc"]);

    return { status: true, images };
  } catch (error) {
    console.log("getEventImages ~ error", error);
    return { status: false, message: error.message };
  }
};

export const getAllInterests = async () => {
  try {
    let allInterests = store.getState()?.interestReducer?.allInterests || {};
    let indexedInterests =
      store.getState()?.interestReducer?.indexedInterests || {};

    // check if interests already store locally
    if (_.isEmpty(allInterests)) {
      const getInterestRes = await getFirestoreMultipleDocs(
        config.COLLECTION_INTEREST
      );

      if (getInterestRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting interests.");
      }

      if (getInterestRes === NO_MATCHING_DOCUMENTS) {
        return { status: true, allInterests: {}, indexedInterests: {} };
      }

      allInterests = _.chain(getInterestRes)
        .orderBy([(item) => item.primaryOrder, (item) => item.secondaryOrder])
        .groupBy((interest) => {
          // index the interest
          indexedInterests[interest.id] = interest.secondaryTitle;
          return interest.primaryId;
        })
        .value();

      // store all interests to redux
      store.dispatch(updateInterestAction({ allInterests, indexedInterests }));
    }

    return { status: true, allInterests, indexedInterests };
  } catch (error) {
    console.log("getAllInterests ~ error", error);
    return { status: false, message: error.message };
  }
};

export const convertHtmlToDraft = (html) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
};

export const formatEventDate = (startDate, endDate) => {
  try {
  } catch (error) {
    console.error("formatEventDate ~ error", error);
    return "";
  }
};

export const getUserProfileDetails = async (userId) => {
  try {
    if (!userId) {
      throw new Error("Missing userId.");
    }

    let allUserProfiles = store.getState()?.userProfileReducer || {};
    let user = allUserProfiles[userId];

    // check if user details does not exist in the redux store
    if (_.isEmpty(user)) {
      const getUserDetailsRes = await getFirestoreDoc(
        config.COLLECTION_USER,
        userId
      );

      if (getUserDetailsRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting user details.");
      }

      if (getUserDetailsRes === NO_MATCHING_DOCUMENTS) {
        return { status: true, user: null };
      }

      user = getUserDetailsRes;

      // covert profile image uri to url
      if (!_.isEmpty(user.profileImage)) {
        const url = (await getImageUrl(user.profileImage.imagePath)).imageUrl;
        user.profileImage.url = url;
      }

      // store user details in redux store
      store.dispatch(updateUserProfileReducerAction({ [userId]: user }));
    }

    return { status: true, user };
  } catch (error) {
    console.log("getUserProfileDetails ~ error", error);
    return { status: false, message: error.message };
  }
};

export const getEventDetails = async (eventId) => {
  try {
    if (!eventId) {
      throw new Error("Missing eventId.");
    }

    let event =
      store.getState()?.eventReducer?.allEventDetails?.[eventId] || null;

    // check if event details does not exist in the redux store
    if (_.isEmpty(event)) {
      const getEventDetailsRes = await getFirestoreDoc(
        config.COLLECTION_EVENT,
        eventId
      );

      if (getEventDetailsRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting event details.");
      }

      if (getEventDetailsRes === NO_MATCHING_DOCUMENTS) {
        return { status: true, event: null };
      }

      event = getEventDetailsRes;

      // covert event cover image uri to url
      if (!_.isEmpty(event.coverImage)) {
        const url = (await getImageUrl(event.coverImage.imagePath)).imageUrl;
        event.coverImage.url = url;
      }

      // store event details in redux store
      store.dispatch(updateAllEventDetailsAction({ [eventId]: event }));
    }

    return { status: true, event };
  } catch (error) {
    console.log("getEventDetails ~ error", error);
    return { status: false, message: error.message };
  }
};

export const getEventLastChatMessage = async (eventMessageId) => {
  try {
    const getEventLastChatRes = await getFirestoreMultipleDocsByPagination(
      `${config.COLLECTION_EVENT_MESSAGE}/${eventMessageId}/messages`,
      [],
      { order: ["createdAt desc"], limitDocs: 1 }
    );

    let chatMessage = null;

    if (getEventLastChatRes === ERROR_GETTING_DOCUMENT) {
      throw new Error("Error while getting last chat message.");
    }

    if (getEventLastChatRes !== NO_MATCHING_DOCUMENTS) {
      chatMessage = getEventLastChatRes[0] || null;
    }

    return { status: true, chatMessage };
  } catch (error) {
    return { satus: false, message: error.message };
  }
};

export const getEventMessageAdditionalData = async ({
  userId,
  eventId,
  eventMessageId,
}) => {
  try {
    const [getUserRes, getEventRes, getLastChatRes] = await Promise.all([
      getUserProfileDetails(userId),
      getEventDetails(eventId),
      getEventLastChatMessage(eventMessageId),
    ]);

    if (!getUserRes.status) {
      throw new Error(getUserRes.message);
    }

    if (!getEventRes.status) {
      throw new Error(getEventRes.message);
    }

    if (!getLastChatRes.status) {
      console.log(
        "getEventMessageAdditionalData ~ error get last chat message",
        getLastChatRes.message
      );
    }

    return {
      status: true,
      user: getUserRes.user,
      event: getEventRes.event,
      lastChat: getLastChatRes.chatMessage,
    };
  } catch (error) {
    console.log("getEventMessageAdditionalData ~ error", error);
    return { status: false, message: error.message };
  }
};

export const getEventMessageDetails = async (eventMessageId) => {
  try {
    if (!eventMessageId) {
      throw new Error("Missing eventMessageId parameter.");
    }

    // check if the event message is stored in redux store
    let allEventChatList = store.getState()?.eventReducer?.allEventChatList;

    let eventMessage = _.find(allEventChatList, { id: eventMessageId });

    if (!eventMessage || !eventMessage?.user || !eventMessage?.event) {
      // event message document not found or not completed, get from db
      const getEventMessageRes = await getFirestoreDoc(
        config.COLLECTION_EVENT_MESSAGE,
        eventMessageId
      );

      if (getEventMessageRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting event chat message.");
      }

      if (getEventMessageRes !== NO_MATCHING_DOCUMENTS) {
        eventMessage = getEventMessageRes;

        // add user details to the event chat lists
        const getAdditionalDataRes = await getEventMessageAdditionalData({
          userId: eventMessage.userId,
          eventId: eventMessage.eventId,
          eventMessageId: eventMessage.id,
        });

        if (!getAdditionalDataRes.status) {
          throw new Error(getAdditionalDataRes.message);
        }

        // add user details to event message
        eventMessage.user = getAdditionalDataRes.user;
        eventMessage.event = getAdditionalDataRes.event;

        eventMessage = {
          ...eventMessage,
          lastMessageTime: eventMessage.lastMessageTime.toDate(),
        };
      }
    }

    return { status: true, eventMessage };
  } catch (error) {
    console.log("getEventMessageDetails ~ error", error);
    return { status: false, message: error.message };
  }
};

export const adjustEventChatData = async (eventChat) => {
  try {
    let eventChatDetails = { ...eventChat };

    // get user details
    const getUserRes = await getUserProfileDetails(eventChat.from);

    if (!getUserRes.status) {
      throw new Error(getUserRes.message);
    }

    // add user details to event chat document
    eventChatDetails.user = getUserRes.user;

    // convert date timestamp field to date object
    if (eventChatDetails.createdAt instanceof Timestamp) {
      eventChatDetails.createdAt = eventChatDetails.createdAt.toDate();
    }

    return { status: true, eventChat: eventChatDetails };
  } catch (error) {
    console.log("adjustEventChatData ~ error", error);
    return { status: false, message: error.message };
  }
};

export const getAllAdminUsers = async () => {
  try {
    let allAdminUsers = store.getState()?.userReducer?.allAdminUsers || [];

    // check if users don't exist in the redux store
    if (_.isEmpty(allAdminUsers)) {
      const getAdminUserRes = await getFirestoreMultipleDocs(
        config.COLLECTION_USER,
        [["role", "==", ADMIN]]
      );

      if (getAdminUserRes === ERROR_GETTING_DOCUMENT) {
        throw new Error("Error while getting admin users.");
      }

      if (getAdminUserRes === NO_MATCHING_DOCUMENTS) {
        return { status: true, allAdminUsers: [] };
      }

      allAdminUsers = await Promise.all(
        getAdminUserRes.map(async (user) => {
          if (!_.isEmpty(user.profileImage)) {
            const url = (await getImageUrl(user.profileImage.imagePath))
              .imageUrl;
            user.profileImage.url = url;
          }
          return user;
        })
      );

      // store all admin uesrs in redux store
      store.dispatch(updateUserReducerAction({ allAdminUsers }));
    }

    return { status: true, allAdminUsers };
  } catch (error) {
    console.log("getAllAdminUsers ~ error", error);
    return { status: false, message: error.message };
  }
};

export const seenNotifications = async () => {
  try {
    // get all notifications
    let allNotifications = await store.getState().notificationReducer
      ?.allNotifications;

    if (!_.isEmpty(allNotifications)) {
      let haveUnseenNotification = false;

      allNotifications = _.map(allNotifications, (notification) => {
        if (!notification.seen) {
          haveUnseenNotification = true;
          notification.seen = true;
        }
        return notification;
      });

      if (haveUnseenNotification) {
        // update seen flag locally
        store.dispatch(updateNotificationReducerAction({ allNotifications }));

        // trigger api to mark all notification as seen
        seenNotificationAPI();
      }
    }

    return { status: true };
  } catch (error) {
    console.log("seenNotifications ~ error", error);
    return { status: false, message: error.message };
  }
};

export const viewedNotification = async (notification) => {
  try {
    // get all notifications
    let allNotifications = await store.getState().notificationReducer
      ?.allNotifications;

    if (!_.isEmpty(allNotifications) && !_.isEmpty(notification)) {
      const viewedNotificationIndex = _.findIndex(allNotifications, {
        id: notification.id,
        viewed: false,
      });

      if (viewedNotificationIndex !== -1) {
        allNotifications[viewedNotificationIndex].viewed = true;

        // update viewed flag locally
        store.dispatch(
          updateNotificationReducerAction({
            allNotifications: [...allNotifications],
          })
        );

        // update viewed flag to db
        viewedNotificationAPI(notification.id);
      }
    }

    return { status: true };
  } catch (error) {
    console.log("seenNotifications ~ error", error);
    return { status: false, message: error.message };
  }
};

export const onNotificationClick = async (notification, navigate) => {
  try {
    if (_.isEmpty(notification) && !notification.id) {
      throw new Error("Get empty notification object.");
    }

    // mark notification as viewed if it hasn't
    viewedNotification(notification);

    if (notification.type === "event message" && notification.eventMessageId) {
      // update redux to select the chat room
      store.dispatch(
        updateSelectedChatEventAction(notification.eventMessageId)
      );

      // navigate user to chat page
      navigate(allRoutes.events.chat.path);
    }
  } catch (error) {
    console.log("onNotificationClick ~ error", error);
    errorAlert(SOMETHING_WENT_WRONG);
  }
};

export const getImageUrl = async (imagePath) => {
  try {
    let imageUrl = "";

    if (imagePath) {
      imageUrl = store.getState()?.imageReducer?.[imagePath];

      if (!imageUrl) {
        // covert image uri to url
        const url = await getDownloadURL(ref(storage, imagePath));
        imageUrl = url;

        // keep in redux
        store.dispatch(
          updateImageReducerAction({
            [imagePath]: imageUrl,
          })
        );
      }
    }

    return { status: true, imageUrl };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const calculateAge = (dob) => {
  var ageDifMs = Date.now() - new Date(dob).getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
