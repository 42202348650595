import _ from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Table from "components/tables/Table";
import { allRoutes } from "routes";
import { updateCalloutReducerAction } from "stores/actions/calloutAction";

const CalloutTable = (props) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // variables
  const {
    loading,
    callouts,
    pageNumber = 1,
    pageLimit,
    onPreviousClick,
    onNextClick,
    hasPreviousPage,
    hasNextPage,
  } = props;
  const startOrderNumber = (pageNumber - 1) * pageLimit + 1;

  const onViewClick = (callout) => {
    dispatch(
      updateCalloutReducerAction({
        selectedCallout: callout,
      })
    );

    navigate(allRoutes.callouts.view.path);
  };

  if (callouts.length === 0 && loading) {
    // show loading
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mt-20">Getting list of callouts...</div>
      </div>
    );
  }

  return (
    <Table
      loading={loading}
      pageNumber={pageNumber}
      showPagination={true}
      header={
        <tr>
          <th className="w40">#</th>
          <th>Callout</th>
          <th>Address</th>
          <th>Date</th>
          <th>Participants</th>
          <th className="text-center">Action</th>
        </tr>
      }
      body={callouts.map((callout, index) => {
        const date = callout.date ? moment(callout.date) : null;
        const participants = _.groupBy(callout.participants, "type");

        return (
          <tr
            key={callout.id}
            className="cursor-pointer"
            onClick={() => onViewClick(callout)}
          >
            {/* order number */}
            <td>{_.padStart(startOrderNumber + index, 2, "0")}</td>

            {/* callout title and location name */}
            <td>
              <h6 className="mb-0">{callout.title}</h6>
              <span>@{callout.locationName}</span>
            </td>

            {/* location address */}
            <td>
              <span>
                <i className="fe fe-map-pin" /> {callout.locationAddress}
              </span>
            </td>

            {/* callout date */}
            <td>
              <i className="fe fe-calendar mt-1 mr-10" />
              {date ? date.format("DD MMM YYYY h:mm a") : "-"}
            </td>

            {/* participants */}
            <td>
              <span>
                <div className="text-success">
                  <b>{participants?.going?.length || 0}</b> Going
                </div>
                <div className="text-info">
                  <b>{participants?.interested?.length || 0}</b> Interested
                </div>
              </span>
            </td>

            {/* action buttons */}
            <td className="text-center">
              <button
                className="btn text-uppercase text-info"
                onClick={() => onViewClick(callout)}
              >
                <b>
                  <u>View</u>
                </b>
              </button>
            </td>
          </tr>
        );
      })}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
    />
  );
};

export default CalloutTable;

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3rem",
  },
};
