import React, { useState } from "react";

import DefaultImage from "assets/images/default-callout-cover.png";

const Image = ({ src, selected, onClick, ...props }) => {
  // states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div {...props}>
      <label className="imagecheck mb-3">
        {/* check icon */}
        {selected ? (
          <i className="fa fa-check" style={styles.checkIcon} />
        ) : null}

        <figure className="imagecheck-figure">
          {/* image */}
          <img
            alt=""
            src={loading || error ? DefaultImage : src}
            className="imagecheck-image"
            style={{ opacity: selected ? 1 : 0.5 }}
            onLoad={() => setLoading(false)}
            onError={() => setError(true)}
            onClick={onClick}
          />
        </figure>
      </label>
    </div>
  );
};

export default Image;

const styles = {
  checkIcon: {
    position: "absolute",
    zIndex: 1,
    right: "0.5em",
    color: "white",
    background: "#f68d2e",
    top: "0.5em",
    padding: "0.2em",
    fontSize: "0.8em",
    borderRadius: "2px",
  },
};
