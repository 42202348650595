import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { errorAlert, getAllInterests } from "utils/helpers";
import CollapseCard from "components/cards/CollapseCard";
import { manageEventInterestsAPI } from "api/event";
import { DRAFT, NEW, PUBLISHED } from "utils/constants";
import { updateEventFormModeAction } from "stores/actions/eventAction";

const ManageInterestForm = (props) => {
  // hooks
  const dispatch = useDispatch();
  const resetEventForm = useSelector(
    (state) => state.formReducer?.resetEventForm
  );
  const event = useSelector((state) => state.eventReducer.event);
  const formMode = useSelector((state) => state.eventReducer.formMode);

  // states
  const [loadingInterests, setLoadingInterests] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formModified, setFormModified] = useState(formMode === DRAFT);
  const [interests, setInterests] = useState({});
  const [selectedInterestIds, setSelectedInterestIds] = useState({});
  const [expandedCard, setExpandedCard] = useState("");

  // variables
  const eventId = event?.id;
  const interestIds = event?.interestIds;
  const { formDisabled, onSuccess = () => {} } = props;
  const isPublished = formMode !== DRAFT && formMode !== NEW;

  useEffect(() => {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (formModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    loadAllInterests();
  }, []);

  // update initial selected ids
  useEffect(() => {
    if (!_.isEmpty(interestIds)) {
      const groupedInterestIds = _.groupBy(
        interestIds,
        (id) => id.split(":")[0]
      );
      setSelectedInterestIds(groupedInterestIds);
    }
  }, [interestIds]);

  // reset state to initial states
  useEffect(() => {
    if (!loadingInterests) {
      setSelectedInterestIds({});
      setExpandedCard("");
    }
  }, [resetEventForm]);

  const loadAllInterests = async () => {
    try {
      const getInterestRes = await getAllInterests();
      if (!getInterestRes.status) {
        throw new Error(getInterestRes.message);
      }
      setLoadingInterests(false);
      setInterests(getInterestRes.allInterests);
    } catch (error) {
      console.log("loadAllInterests ~ error", error);
      setLoadingInterests(false);
      errorAlert(error.message);
    }
  };

  const onCardClick = (id) => {
    if (expandedCard === id) {
      setExpandedCard("");
    } else {
      setExpandedCard(id);
    }
  };

  const onTickAll = (primaryId) => {
    // form has been modified
    !formModified && setFormModified(true);

    const category = _.map(interests[primaryId], (interest) => interest.id);
    setSelectedInterestIds({ ...selectedInterestIds, [primaryId]: category });
  };

  const onUntickAll = (primaryId) => {
    // form has been modified
    !formModified && setFormModified(true);

    setSelectedInterestIds({ ...selectedInterestIds, [primaryId]: [] });
  };

  const onInterestSelected = (primaryId, interestId) => {
    // form has been modified
    !formModified && setFormModified(true);

    let category = selectedInterestIds[primaryId] || [];
    if (_.includes(category, interestId)) {
      // remove interest id
      _.remove(category, (id) => id === interestId);
    } else {
      // add interest id
      category.push(interestId);
    }
    setSelectedInterestIds({ ...selectedInterestIds, [primaryId]: category });
  };

  const onSaveClick = async () => {
    try {
      let selectedIds = [];
      _.forEach(selectedInterestIds, (interestIds) => {
        selectedIds = [...selectedIds, ...interestIds];
      });

      selectedIds = _.uniq(selectedIds);

      if (selectedIds.length < 1) {
        throw new Error("Please select at least 1 interest.");
      }

      setLoading(true);

      const manageInterestRes = await manageEventInterestsAPI({
        eventId,
        selectedInterestIds: selectedIds,
      });

      if (manageInterestRes.code !== 200) {
        throw new Error(manageInterestRes.message);
      }

      setLoading(false);
      setFormModified(false);

      // update form mode to published
      if (formMode === DRAFT) {
        dispatch(updateEventFormModeAction(PUBLISHED));
      }

      onSuccess();
    } catch (error) {
      console.log("onSaveClick ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  return (
    <div className={`card dimmer ${loading || formDisabled ? "active" : ""}`}>
      {/* form title */}
      <div className="card-header dimmer-content">
        <h3 className="card-title">Interests</h3>
      </div>

      <div className="card-body dimmer-content">
        <div className="mb-10">
          Select at least 1 interest or activity that suit to this event
        </div>

        {/* loading interest */}
        {loadingInterests ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

        {/* list of interests */}
        {!loadingInterests
          ? _.map(interests, (items, key) => {
              const primaryId = items[0].primaryId;
              const title = items[0].primaryTitle;

              const isAllTicked =
                selectedInterestIds[primaryId]?.length ===
                interests[primaryId]?.length;

              if (title === "About you") {
                // hide about you
                return;
              }

              return (
                <CollapseCard
                  key={key}
                  title={title}
                  active={!_.isEmpty(selectedInterestIds[primaryId])}
                  expanded={key === expandedCard}
                  onClick={() => onCardClick(key)}
                >
                  <div>
                    <b
                      style={styles.tickAll}
                      className="hover"
                      onClick={
                        isAllTicked
                          ? () => onUntickAll(primaryId)
                          : () => onTickAll(primaryId)
                      }
                    >
                      {isAllTicked ? "UNTICK ALL" : "TICK ALL"}
                    </b>
                  </div>

                  <div className="row clearfix">
                    {_.map(items, (interest) => (
                      <div key={interest.id} className="col-lg-12">
                        <label className="custom-control custom-checkbox">
                          {/* check box */}
                          <input
                            type="checkbox"
                            checked={_.includes(
                              selectedInterestIds[primaryId],
                              interest.id
                            )}
                            className="custom-control-input"
                            onChange={() =>
                              onInterestSelected(primaryId, interest.id)
                            }
                          />

                          {/* title */}
                          <span
                            className="custom-control-label"
                            style={styles.secondaryTitle}
                          >
                            {interest.secondaryTitle}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </CollapseCard>
              );
            })
          : null}
      </div>

      {/* submit button */}
      <div className="card-footer text-right dimmer-content">
        <button
          disabled={loading || !formModified}
          type="button"
          className="btn btn-round btn-primary"
          onClick={onSaveClick}
        >
          {loading ? (
            <>
              <div
                className="spinner-border text-white spinner-border-sm mr-10"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <span>{!isPublished ? "Publishing" : "Saving..."}</span>
            </>
          ) : !isPublished ? (
            "Publish"
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default ManageInterestForm;

const styles = {
  tickAll: {
    userSelect: "none",
    cursor: "pointer",
    fontSize: "0.6rem",
    textDecoration: "underline",
  },
  secondaryTitle: {
    fontSize: "0.8em",
    fontWeight: 500,
    userSelect: "none",
    cursor: "pointer",
  },
};
