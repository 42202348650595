const initialState = {};

const eventChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_EVENT_CHAT_MESSAGE":
      return {
        ...state,
        ...action.payload,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default eventChatReducer;
