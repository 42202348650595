import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertToMomentInstance } from "utils/helpers";

const ChatDate = (props) => {
  // hooks
  const chatClock = useSelector((state) => state.appReducer?.chatClock);

  // states
  const [formattedDate, setFormattedDate] = useState("");

  // variables
  const { date } = props;

  useEffect(() => {
    try {
      if (date) {
        const now = moment();
        const momentDate = convertToMomentInstance(date);
        const duration = now.diff(momentDate, "minutes", true);

        let readableDate;

        if (duration < 1) {
          readableDate = "now";
        } else if (duration < 60) {
          // less than an hour
          readableDate = Math.floor(duration) + "m";
        } else if (duration < 1440) {
          // less than a days
          readableDate = Math.floor(duration / 60) + "h";
        } else if (duration < 10080) {
          // less than a week
          readableDate = momentDate.format("ddd");
        } else {
          // more than a week
          readableDate = momentDate.format("D MMM");
        }

        if (formattedDate !== readableDate) {
          setFormattedDate(readableDate);
        }
      }
    } catch (error) {
      console.log("ChatDate ~ useEffect ~ error:", error);
    }
  }, [chatClock]);

  if (!formattedDate) {
    return null;
  }

  return <div style={props.style}>{formattedDate}</div>;
};

export default ChatDate;
