// redux store to keep all images for optmization
const initialState = {};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_IMAGE_REDUCER":
      return {
        ...state,
        ...action.payload,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default imageReducer;
