const initialState = {
  allInterests: {},
  indexedInterests: {},
};

const interestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_INTEREST":
      return {
        ...state,
        ...action.payload,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default interestReducer;
