import React from "react";
import { ReactSVG } from "react-svg";

import verifiedIcon from "assets/icons/VerifiedShield.svg";
import unverifiedIcon from "assets/icons/UnverifiedShield.svg";
import { capitalizeEachWord } from "utils/helpers";
import { GOING } from "utils/constants";

const UserProfileCard = (props) => {
  // variables
  const { containerStyle = {}, user = {}, onClick = () => {} } = props;

  return (
    <div
      className="user-card-container"
      style={{ ...styles.mainContainer, ...containerStyle }}
      onClick={onClick}
    >
      {/* profile image */}
      <div style={styles.profileImageContainer}>
        {user?.profileImage?.url ? (
          <img
            style={styles.profileImage}
            src={user?.profileImage?.url}
            data-toggle="tooltip"
            data-placement="top"
            alt="Avatar"
            className="avatar"
            data-original-title="Avatar Name"
          />
        ) : (
          <span
            className="avatar avatar-green text-uppercase"
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Avatar Name"
            style={styles.profileImage}
          >
            {`${user?.firstName[0]}${user?.lastName[0]}`}
          </span>
        )}

        {/* verification icon */}
        <ReactSVG
          src={user.verified ? verifiedIcon : unverifiedIcon}
          style={styles.profileVerificationIcon}
        />
      </div>

      {/* right container */}
      <div style={styles.rightContainer}>
        {/* user name */}
        <span style={styles.title}>
          {capitalizeEachWord(`${user?.firstName} ${user?.lastName}`)}
          <span
            className={`tag tag-${user.type === GOING ? "green" : "info"} ml-2`}
          >
            {user.type === GOING ? "Going" : "Interested"}
          </span>
        </span>

        <div style={styles.subtitleContainer}>
          {/* email address */}
          <div style={styles.email}>{user.email}</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileCard;

const styles = {
  mainContainer: {
    display: "flex",
    flex: 1,
    padding: "0.5rem",
    flexDirection: "row",
    overflow: "hidden",
  },
  profileImageContainer: {
    height: 60,
    width: 60,
    marginRight: "1rem",
    position: "relative",
  },
  profileImage: {
    height: 60,
    width: 60,
    objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileVerificationIcon: {
    position: "absolute",
    bottom: 0,
    right: -2,
    transform: "scale(1.2)",
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    position: "relative",
    display: "flex",
    flexShrink: 1,
    flexGrow: 1,
    justifyContent: "center",
    paddingRight: "2rem",
    flexDirection: "column",
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  subtitleContainer: {
    display: "flex",
    flexDirection: "row",
    color: "grey",
    marginTop: "0.2rem",
  },
  email: {
    display: "flex",
    alignItems: "flex-end",
    fontSize: "0.85rem",
  },
  divider: {
    display: "flex",
    padding: "0 .3rem",
    alignItems: "flex-end",
  },
  date: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end",
    whiteSpace: "nowrap",
    fontSize: "0.85rem",
  },
  badge: {
    position: "absolute",
    right: "0.5rem",
    height: "0.75rem",
    width: "0.75rem",
    backgroundColor: "#f68d2e",
    borderRadius: "1rem",
  },
};
