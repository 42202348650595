import { INITIAL_PAGINATION } from "utils/constants";

const initialState = {
  activeTab: 0,
  publishedCallouts: [[]],
  publishedCalloutsPagination: INITIAL_PAGINATION,
  draftCallouts: [[]],
  draftCalloutsPagination: INITIAL_PAGINATION,
  historyCallouts: [[]],
  historyCalloutsPagination: INITIAL_PAGINATION,
  selectedCallout: null,
};

const calloutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CALLOUT_REDUCER":
      return {
        ...state,
        ...action.payload,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default calloutReducer;
