const initialState = {
  resetEventForm: 0,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_EVENT_FORM":
      return {
        ...state,
        resetEventForm: state.resetEventForm + 1,
      };

    case "SIGN_OUT":
      return initialState;

    default:
      return state;
  }
};

export default formReducer;
