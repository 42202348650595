import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import MetisMenu from "react-metismenu";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import moment from "moment";

import config from "config";
import { toggleLeftMenuAction } from "stores/actions/settingsAction";
import { updateNotificationReducerAction } from "stores/actions/notificationAction";

import DashboardHeader from "components/headers/DashboardHeader";
import MenuLink from "components/MenuLink";

import NotFound from "pages/error/NotFound";
import InternalError from "pages/error/InternalError";

import logo from "assets/images/logo.png";
import {
  db,
  getFirestoreMultipleDocsByPagination,
  getUserRoleClaim,
} from "lib/firebase";
import { allRoutes, roleRoute } from "routes";
import { errorAlert } from "utils/helpers";
import {
  ADMIN,
  ERROR_GETTING_DOCUMENT,
  NOTIFICATIONS_LIMIT_DOCS,
  NO_MATCHING_DOCUMENTS,
  SOMETHING_WENT_WRONG,
} from "utils/constants";

export const SIDE_MENU = [
  {
    label: "Dashboard",
    icon: "icon-home",
    to: allRoutes.dashboard.index.path,
  },
  {
    label: "Events",
    icon: "icon-calendar",
    content: [
      {
        label: "Create Event",
        to: allRoutes.events.create.path,
      },
      {
        label: "Manage Events",
        to: allRoutes.events.index.path,
      },
      {
        label: "Event Chats",
        to: allRoutes.events.chat.path,
      },
    ],
  },
  {
    label: "Callouts",
    icon: "icon-flag",
    content: [
      {
        label: "Manage Callouts",
        to: allRoutes.callouts.index.path,
      },
    ],
  },
  {
    label: "Coupons",
    icon: "icon-present",
    content: [
      {
        label: "Create Coupons",
        to: allRoutes.coupons.create.path,
      },
      {
        label: "Manage Coupons",
        to: allRoutes.coupons.index.path,
      },
    ],
  },
  {
    label: "Users",
    icon: "icon-user",
    content: [
      {
        label: "Create User",
        to: allRoutes.users.create.path,
      },
      {
        label: "Manage Users",
        to: allRoutes.users.index.path,
      },
    ],
  },
  {
    label: "Notifications",
    icon: "icon-bell",
    content: [
      {
        label: "All Notifications",
        to: allRoutes.notifications.index.path,
      },
    ],
  },
];

const MainLayout = () => {
  // hooks
  const location = useLocation();
  const dispatch = useDispatch();

  // states
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState(false);
  const [subscribeListener, setSubscribeListener] = useState(false);
  const [subscribing, setSubscribing] = useState(false);

  // variables
  const isToggleLeftMenu = useSelector(
    (state) => state.settings?.isToggleLeftMenu
  );
  const allNotifications = useSelector(
    (state) => state.notificationReducer?.allNotifications
  );

  // refs
  const notificationListenerRef = useRef(null);

  useEffect(() => {
    initializeSideMenu();
  }, []);

  const initializeSideMenu = async () => {
    try {
      // get user roles
      const role = await getUserRoleClaim();

      // get routes based on role
      const routes = [...roleRoute[role].routes, ...roleRoute.common.routes];

      // filter the side menu based on role
      let sideMenuContent = [];
      _.forEach(SIDE_MENU, (menu) => {
        if (!_.isEmpty(menu.content)) {
          let submenuContent = [];
          _.forEach(menu.content, (submenu) => {
            const isFound = _.findIndex(routes, ["path", submenu.to]);
            isFound !== -1 && submenuContent.push(submenu); // add submenu if found
          });
          // if one of the submenu matched, push the parent menu with founded submenu
          if (submenuContent.length > 0) {
            sideMenuContent.push({ ...menu, content: submenuContent });
          }
        } else {
          const isFound = _.findIndex(routes, ["path", menu.to]);
          isFound !== -1 && sideMenuContent.push(menu); // add menu if index is not -1
        }
      });

      setMenu(sideMenuContent);
      setRoutes(routes);
      setLoading(false);
    } catch (error) {
      console.log("initializeSideMenu ~ error", error);
      setLoading(false);
      setError(true);
    }
  };

  // initialize notification listener
  useEffect(() => {
    loadInitialNotifications();

    return () => {
      console.log("UNSUBSCRIBED NOTIFICATIONS");
      if (typeof notificationListenerRef.current === "function") {
        notificationListenerRef.current();
      }
    };
  }, []);

  const loadInitialNotifications = async () => {
    try {
      dispatch(updateNotificationReducerAction({ loadingNotifications: true }));

      let notifications = [];
      let isNotificationListEnded = false;

      // check if notifications are stored in redux store
      if (_.isEmpty(allNotifications)) {
        // get notifications from db
        const getNotificationRes = await getFirestoreMultipleDocsByPagination(
          `${config.COLLECTION_NOTIFICATION}`,
          [
            ["role", "==", ADMIN],
            ["isDeleted", "==", false],
            ["status", "==", true],
          ],
          {
            order: ["createdAt desc"],
            limitDocs: NOTIFICATIONS_LIMIT_DOCS,
          }
        );

        if (getNotificationRes === ERROR_GETTING_DOCUMENT) {
          throw new Error("Error while getting notifications");
        }

        if (getNotificationRes !== NO_MATCHING_DOCUMENTS) {
          notifications = _.map(getNotificationRes, (notification) => ({
            ...notification,
            createdAt: notification.createdAt?.toDate?.(),
            updatedAt: notification.updatedAt?.toDate?.(),
          }));
        }
      } else {
        notifications = allNotifications;
      }

      if (notifications.length < NOTIFICATIONS_LIMIT_DOCS) {
        isNotificationListEnded = true;
      }

      // store notifications in redux
      dispatch(
        updateNotificationReducerAction({
          loadingNotifications: false,
          allNotifications: notifications,
          isNotificationListEnded,
        })
      );

      setSubscribeListener(true);
    } catch (error) {
      console.log("loadInitialNotifications ~ error", error);
      dispatch(
        updateNotificationReducerAction({ loadingNotifications: false })
      );
      errorAlert(SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    // subscribing to listener to get real time notifications
    if (subscribeListener === true && subscribing === false) {
      let lastUpdatedTime;
      if (!_.isEmpty(allNotifications)) {
        lastUpdatedTime = Timestamp.fromDate(allNotifications[0]?.updatedAt);
      }

      if (!lastUpdatedTime) {
        lastUpdatedTime = Timestamp.fromDate(moment().toDate());
      }

      const q = query(
        collection(db, `${config.COLLECTION_NOTIFICATION}`),
        where("createdAt", ">", lastUpdatedTime),
        where("role", "==", ADMIN),
        where("isDeleted", "==", false),
        where("status", "==", true),
        orderBy("createdAt", "desc")
      );

      notificationListenerRef.current = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach(async ({ doc }) => {
          // get the notification data
          let incomingNotification = { ...doc.data(), id: doc.id };
          incomingNotification.createdAt =
            incomingNotification.createdAt?.toDate?.();
          incomingNotification.updatedAt =
            incomingNotification.updatedAt?.toDate?.();

          let notifications = allNotifications;

          // check if the notifications already contains the doc
          let notificationIndex = _.findIndex(notifications, {
            id: incomingNotification.id,
          });

          if (notificationIndex === -1) {
            notifications.push(incomingNotification);
          } else {
            notifications[notificationIndex] = incomingNotification;
          }

          notifications = _.orderBy(notifications, "createdAt", "desc");

          dispatch(
            updateNotificationReducerAction({ allNotifications: notifications })
          );
        });
      });

      console.log("SUBSCRIBED NOTIFICATIONS");
      setSubscribing(true);
    }
  }, [subscribeListener, subscribing]);

  const toggleLeftMenu = () => {
    dispatch(toggleLeftMenuAction());
  };

  const toggleSubMenu = (e) => {
    let menucClass = "";
    if (e.itemId) {
      const subClass = e.items.map((menuItem, i) => {
        if (menuItem.to === location.pathname) {
          menucClass = "in";
        } else {
          menucClass = "collapse";
        }
        return menucClass;
      });
      return subClass;
    } else {
      return e.visible ? "collapse" : "metismenu";
    }
  };

  const renderPage = () => {
    if (error) {
      // show error page
      return <InternalError />;
    }

    const route = _.find(routes, { path: location.pathname });
    return route ? route.element : <NotFound />;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`${isToggleLeftMenu ? "offcanvas-active" : ""}`}>
      {/* side bar */}
      <div id="header_top" className="header_top">
        {/* logo */}
        <div className="container">
          <div className="hleft">
            <NavLink
              to={allRoutes.dashboard.index.path}
              className="header-brand"
            >
              <img style={styles.logo} src={logo} />
            </NavLink>
          </div>

          {/* side menu toggler */}
          <div className="hright">
            <div className="dropdown">
              <p className="nav-link icon menu_toggle" onClick={toggleLeftMenu}>
                <i className="fa  fa-align-left" />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* side menu */}
      <div id="left-sidebar" className="sidebar">
        <h5 className="brand-name">Crewmen Administration</h5>
        <nav id="left-sidebar-nav" className="sidebar-nav mt-4">
          <MetisMenu
            content={menu}
            noBuiltInClassNames={true}
            classNameContainer={toggleSubMenu}
            classNameContainerVisible="in"
            classNameItemActive="active"
            classNameLinkActive="active"
            classNameItemHasVisibleChild="active"
            classNameItemHasActiveChild="active"
            classNameLink="has-arrow arrow-c"
            iconNamePrefix=""
            activeLinkTo={location.pathname}
            LinkComponent={(itemProps) => {
              return <MenuLink {...itemProps} />;
            }}
          />
        </nav>
      </div>

      <div className="page">
        {/* header */}
        <DashboardHeader
          title={_.find(routes, { path: location.pathname })?.title}
        />

        {/* page content */}
        <div className="section-body mt-3">
          <div className="container-fluid">{renderPage()}</div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

const styles = {
  logo: {
    height: "56px",
    width: "56px",
    borderRadius: "2rem",
    padding: "0.5rem",
    overflow: "hidden",
  },
};
