import React from "react";

const Loader = (props) => {
  // variables
  const { className, text } = props;

  return (
    <>
      <div className={`spinner-border ${className}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {text ? <span>{text}</span> : null}
    </>
  );
};

export default Loader;
