import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ImagePicture from "assets/images/image-file.png";

const ImageUpload = (props) => {
  // hooks
  const resetEventForm = useSelector(
    (state) => state.formReducer?.resetEventForm
  );

  // states
  const [imagePath, setImagePath] = useState("");
  const [dragActive, setDragActive] = useState(false);

  // variables
  const { disabled, image, onImageChange, errorMessage } = props;

  useEffect(() => {
    if (image) {
      // handle image path
      setImagePath(image.url);
    }
  }, [image]);

  // clear the input
  useEffect(() => {
    setImagePath("");
    setDragActive(false);
  }, [resetEventForm]);

  return (
    <>
      <div
        style={{
          ...styles.mainContainer,
          borderStyle: imagePath ? "solid" : "dashed",
          borderColor: errorMessage ? "#dc3545" : "#e8e9e9",
          opacity: disabled ? 0.7 : 1,
        }}
      >
        {imagePath ? (
          <>
            <img src={imagePath} style={styles.imagePreview} alt="Selected" />

            {/* overlay */}
            <div style={{ ...styles.overlay, backgroundColor: "black" }} />

            {/* change button */}
            <span style={styles.changeButton}>
              <i className="icon-refresh" /> CHANGE
            </span>
          </>
        ) : null}

        {!imagePath ? (
          <>
            {/* file icon */}
            <img src={ImagePicture} alt="icon" style={{ height: "5rem" }} />

            {/* upload description */}
            <b className="text-center mb-10 ml-10 mr-10">
              Drag & drop <u className="text-primary">image</u> or{" "}
              <u className="text-primary">browser image</u> on your computer
            </b>
            <b className="text-center mb-10 ml-10 mr-10">
              Recommended to use image with{" "}
              <u className="text-primary">1280 x 720</u> pixel
            </b>
          </>
        ) : null}

        {/* overlay while dragging file */}
        {!disabled && dragActive ? <div style={styles.overlay} /> : null}

        {/* hidden input file */}
        <input
          disabled={disabled}
          className="upload-input-file form-control"
          type="file"
          accept="image/png, image/jpeg"
          onDragEnter={() => setDragActive(true)}
          onDragLeave={() => setDragActive(false)}
          onDrop={() => setDragActive(false)}
          onChange={onImageChange}
        />
      </div>

      {errorMessage ? (
        <div className="input-invalid">{errorMessage}</div>
      ) : null}
    </>
  );
};

export default ImageUpload;

const styles = {
  mainContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    aspectRatio: "3/1",
    border: "2px dashed #e8e9e9",
    borderRadius: "2em",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  imagePreview: {
    position: "absolute",
    width: "100%",
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#F68D2E",
    opacity: 0.2,
  },
  changeButton: {
    padding: "0.5em 1em",
    border: "2px solid white",
    color: "white",
    fontSize: "0.8em",
    fontWeight: 500,
    zIndex: 1,
  },
};
