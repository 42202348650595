import axios, { errorHandler } from "api";
import config from "config";

export const seenNotificationAPI = async () => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_SEEN_NOTIFICATION);
    return response.data;
  } catch (error) {
    console.log("seenNotificationAPI ~ error", error);
    return errorHandler(error);
  }
};

export const viewedNotificationAPI = async (notificationId) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_VIEWED_NOTIFICATION, {
      notificationId,
    });
    return response.data;
  } catch (error) {
    console.log("viewedNotificationAPI ~ error", error);
    return errorHandler(error);
  }
};
