import axios, { errorHandler } from "api";

import config from "config";

export const getCalloutsAPI = async ({ type, nextDocId = null }) => {
  try {
    const instance = await axios();
    const response = await instance.post(config.EP_GET_CALLOUTS, {
      type,
      nextDocId,
    });
    return response.data;
  } catch (error) {
    console.log("getCalloutsAPI ~ error", error);
    return errorHandler(error);
  }
};
