import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { alert } from "utils/alert";
import { errorAlert } from "utils/helpers";
import { deleteEventAPI, duplicateEventAPI, getEventsAPI } from "api/event";
import {
  DRAFT,
  HISTORY,
  INITIAL_PAGINATION,
  PUBLISHED,
  SOMETHING_WENT_WRONG,
} from "utils/constants";
import EventTable from "components/tables/EventTable";
import { allRoutes } from "routes";
import { updateEventFormModeAction } from "stores/actions/eventAction";

const TAB_ITMES = {
  published: { id: 0, title: "Published Events" },
  draft: { id: 1, title: "Draft" },
  history: { id: 2, title: "History" },
};

const Event = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // states
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(TAB_ITMES.published.id);
  const [publishedEvents, setPublishedEvents] = useState([[]]);
  const [publishedEventsPagination, setPublishedEventsPagination] =
    useState(INITIAL_PAGINATION);
  const [draftEvents, setDraftEvents] = useState([[]]);
  const [draftEventsPagination, setDraftEventsPagination] =
    useState(INITIAL_PAGINATION);
  const [historyEvents, setHistoryEvents] = useState([[]]);
  const [historyEventsPagination, setHistoryEventsPagination] =
    useState(INITIAL_PAGINATION);

  // variables for published event table
  const hasPreviousPublishedEvents = publishedEventsPagination.index > 0;
  const hasNextPublishedEvents =
    publishedEventsPagination.index < publishedEvents.length - 1 ||
    !!publishedEventsPagination.nextDocId;

  // variables for draft event table
  const numberOfDrafts = _.reduce(
    draftEvents,
    (sumLength, draft) => (sumLength += draft.length),
    0
  );
  const hasPreviousDraftEvents = draftEventsPagination.index > 0;
  const hasNextDraftEvents =
    draftEventsPagination.index < draftEvents.length - 1 ||
    !!draftEventsPagination.nextDocId;

  // variables for history event table
  const hasPreviousHistoryEvents = historyEventsPagination.index > 0;
  const hasNextHistoryEvents =
    historyEventsPagination.index < historyEvents.length - 1 ||
    !!historyEventsPagination.nextDocId;

  useEffect(() => {
    loadInitialEvents();
  }, []);

  const loadInitialEvents = async () => {
    try {
      const [getPublishedEventRes, getDraftEventRes] = await Promise.all([
        getEventsAPI({ type: PUBLISHED }),
        getEventsAPI({ type: DRAFT }),
      ]);

      if (getPublishedEventRes.code !== 200 && activeTab === 0) {
        throw new Error(getPublishedEventRes.message);
      } else {
        const { events, nextDocId, limit } = getPublishedEventRes.results;
        setPublishedEvents([events]);
        setPublishedEventsPagination({ index: 0, nextDocId, limit });
      }

      if (getDraftEventRes.code !== 200 && activeTab === 1) {
        throw new Error(getDraftEventRes.message);
      } else {
        const { events, nextDocId, limit } = getDraftEventRes.results;
        setDraftEvents([events]);
        setDraftEventsPagination({ index: 0, nextDocId, limit });
      }

      setLoading(false);
    } catch (error) {
      console.log("getEvents ~ error", error);
      setLoading(false);
      errorAlert(error.message);
    }
  };

  const onTabClick = async (index) => {
    // get tab pane element and hide the tab
    const element = document.getElementById("event-tab-pane");
    element.classList.remove("show");

    setTimeout(() => {
      // change the tab
      setActiveTab(index);
      // show the new tab
      element.classList.add("show");
    }, 200);

    if (index === TAB_ITMES.history.id && _.isEmpty(historyEvents[0])) {
      // get history events
      try {
        setLoading(true);
        const getHistoryEventRes = await getEventsAPI({ type: HISTORY });

        if (getHistoryEventRes.code !== 200) {
          throw new Error(getHistoryEventRes.message);
        } else {
          const { events, nextDocId, limit } = getHistoryEventRes.results;
          setHistoryEvents([events]);
          setHistoryEventsPagination({ index: 0, nextDocId, limit });
          setLoading(false);
        }
      } catch (error) {
        console.log("getHistoryEvents ~ error", error);
        setLoading(false);
        errorAlert(error.message);
      }
    }
  };

  const onEditEventClick = (mode) => (event) => {
    // update form mode
    dispatch(updateEventFormModeAction(mode || ""));

    // redirect to edit event page
    navigate(allRoutes.events.update.path, {
      state: { eventId: event.id },
    });
  };

  const onDuplicateEventClick = async (event) => {
    try {
      if (_.isEmpty(event)) {
        console.error(
          "onDuplicateEventClick ~ error",
          "Missing event details."
        );
        return errorAlert(SOMETHING_WENT_WRONG);
      }

      const { isConfirmed } = await alert.fire({
        title: "Duplicate Event",
        text: `Do you want to duplicate ${event.title} event?`,
        icon: "info",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Duplicate",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoading(true);

        // duplicate event
        const duplicateEventRes = await duplicateEventAPI(event.id);

        setLoading(false);

        if (duplicateEventRes.code === 200) {
          // update form mode
          dispatch(updateEventFormModeAction(DRAFT));

          // redirect to update event page
          navigate(allRoutes.events.update.path, {
            state: { eventId: duplicateEventRes?.results?.eventId },
          });
        } else {
          // show error alert
          errorAlert(duplicateEventRes.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("onDuplicateEventClick ~ error", error);
      errorAlert(error.message);
    }
  };

  const onDeleteEventClick = async (event) => {
    try {
      if (_.isEmpty(event)) {
        console.error("onDeleteEventClick ~ error", "Missing event details.");
        return errorAlert(SOMETHING_WENT_WRONG);
      }

      const { isConfirmed } = await alert.fire({
        title: "Delete Event?",
        text: `Do you want to delete ${event.title} event? Once deleted, you will not be able to recover this event.`,
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Delete",
        confirmButtonColor: "#eb1e1e",
        reverseButtons: true,
      });

      if (isConfirmed) {
        setLoading(true);

        // delete event
        const deleteEventRes = await deleteEventAPI(event.id);

        setLoading(false);

        if (deleteEventRes.code === 200) {
          // reload the event data
          // TODO optimised the reloading data based on the tab
          loadInitialEvents();

          alert.fire({
            title: "Delete Event Success",
            text: `The ${event.title} event has been successfully deleted.`,
            icon: "success",
          });
        } else {
          // show error alert
          errorAlert(deleteEventRes.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("onDeleteEventClick ~ error", error);
      errorAlert(error.message);
    }
  };

  const onNextClick = async (
    events,
    setEvents,
    pagination,
    setPaginationState
  ) => {
    try {
      setLoading(true);
      const nextIndex = pagination.index + 1;

      if (_.isEmpty(events[nextIndex])) {
        // no events in local state, get the next events from the API
        const getEventRes = await getEventsAPI({
          type: PUBLISHED,
          nextDocId: pagination.nextDocId,
        });

        if (getEventRes.code !== 200) {
          throw new Error(getEventRes.message);
        } else {
          // add next events to the local state
          const nextIndex = pagination.index + 1;
          const { nextDocId, limit } = getEventRes.results;
          events[nextIndex] = getEventRes.results.events;
          setEvents([...events]);
          setPaginationState({ index: nextIndex, nextDocId, limit });
          setLoading(false);
        }
      } else {
        setPaginationState({ ...pagination, index: nextIndex });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  const onPreviousClick = async (pagination, setPaginationState) => {
    try {
      const previousIndex = pagination.index - 1;
      if (previousIndex <= 0) {
        setPaginationState({ ...pagination, index: 0 });
      } else {
        setPaginationState({ ...pagination, index: previousIndex });
      }
    } catch (error) {
      setLoading(false);
      console.error("onNextClick ~ error", error);
      errorAlert(error.message);
    }
  };

  return (
    <>
      {/* header */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={styles.tabContainer}
      >
        {/* tab */}
        <ul className="nav nav-tabs page-header-tab">
          {_.map(TAB_ITMES, (item) => (
            <li key={item.id} className="nav-item">
              <span
                className={`nav-link ${activeTab === item.id ? "active" : ""}`}
                data-toggle="tab"
                onClick={() => onTabClick(item.id)}
              >
                {`${item.title}${
                  TAB_ITMES.draft.id === item.id ? ` (${numberOfDrafts})` : ""
                }`}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="tab-content pb-50">
        <div
          id="event-tab-pane"
          className="tab-pane show fade active"
          role="tabpanel"
        >
          {/* published events */}
          {activeTab === TAB_ITMES.published.id ? (
            <EventTable
              loading={loading}
              events={publishedEvents[publishedEventsPagination.index]}
              pageNumber={publishedEventsPagination.index + 1}
              pageLimit={publishedEventsPagination.limit}
              onEditClick={onEditEventClick(PUBLISHED)}
              onDuplicateClick={onDuplicateEventClick}
              onDeleteClick={onDeleteEventClick}
              onPreviousClick={() =>
                onPreviousClick(
                  publishedEventsPagination,
                  setPublishedEventsPagination
                )
              }
              onNextClick={() =>
                onNextClick(
                  publishedEvents,
                  setPublishedEvents,
                  publishedEventsPagination,
                  setPublishedEventsPagination
                )
              }
              hasPreviousPage={hasPreviousPublishedEvents}
              hasNextPage={hasNextPublishedEvents}
            />
          ) : null}

          {/* draft events */}
          {activeTab === TAB_ITMES.draft.id ? (
            <EventTable
              loading={loading}
              showDuplicateButton={false}
              events={draftEvents[draftEventsPagination.index]}
              pageNumber={draftEventsPagination.index + 1}
              pageLimit={draftEventsPagination.limit}
              onEditClick={onEditEventClick(DRAFT)}
              onDeleteClick={onDeleteEventClick}
              onPreviousClick={() =>
                onPreviousClick(draftEventsPagination, setDraftEventsPagination)
              }
              onNextClick={() =>
                onNextClick(
                  draftEvents,
                  setDraftEvents,
                  draftEventsPagination,
                  setDraftEventsPagination
                )
              }
              hasPreviousPage={hasPreviousDraftEvents}
              hasNextPage={hasNextDraftEvents}
            />
          ) : null}

          {/* past events */}
          {activeTab === TAB_ITMES.history.id ? (
            <EventTable
              loading={loading}
              showEditButton={false}
              events={historyEvents[historyEventsPagination.index]}
              pageNumber={historyEventsPagination.index + 1}
              pageLimit={historyEventsPagination.limit}
              onDuplicateClick={onDuplicateEventClick}
              onDeleteClick={onDeleteEventClick}
              onPreviousClick={() =>
                onPreviousClick(
                  historyEventsPagination,
                  setHistoryEventsPagination
                )
              }
              onNextClick={() =>
                onNextClick(
                  historyEvents,
                  setHistoryEvents,
                  historyEventsPagination,
                  setHistoryEventsPagination
                )
              }
              hasPreviousPage={hasPreviousHistoryEvents}
              hasNextPage={hasNextHistoryEvents}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Event;

const styles = {
  tabContainer: {
    marginTop: "-1rem",
  },
};
