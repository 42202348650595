import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getUserRoleClaim, userSignOut } from "lib/firebase";
import { ALLOWED_ROLES } from "utils/constants";
import { allRoutes } from "routes";

const PrivateRoute = ({ children }) => {
  // hooks
  const navigate = useNavigate();
  const fixNavbar = useSelector((state) => state.settings.fixNavbar);

  // states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    authCheck();
  }, []);

  const authCheck = async () => {
    try {
      const role = await getUserRoleClaim();

      if (!ALLOWED_ROLES.includes(role)) {
        await userSignOut();
        return navigate(allRoutes.auth.login.path);
      }

      setLoading(false);
    } catch (error) {
      console.log("authCheck ~ error", error);

      // show error message
      setError(error);
    }
  };

  if (loading || error) {
    return (
      <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              {error ? (
                <span className="text-red">
                  <i className="fa fa-exclamation mr-1" />
                  {error?.message || "Something went wrong."}
                </span>
              ) : (
                "Loading..."
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return children;
};

export default PrivateRoute;
