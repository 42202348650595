import _ from "lodash";
import React, { useState } from "react";
import parseHtml from "html-react-parser";

import FAQModal from "components/modals/FAQModal";
import { useSelector } from "react-redux";

const ManageFAQForm = (props) => {
  // hooks
  const event = useSelector((state) => state.eventReducer.event);

  // states
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  // variables
  const eventId = event?.id;
  const faq = event?.faq || [];
  const hasFAQ = !_.isEmpty(faq);

  const onEditClick = (item) => {
    setSelectedFAQ(item);
    setShowFAQModal(true);
  };

  const onModalClose = () => {
    setShowFAQModal(false);
    hasFAQ && setSelectedFAQ(null);
  };

  return (
    <>
      <div className="card">
        {/* form title */}
        <div className="card-header">
          <h3 className="card-title">Q&A</h3>

          <div className="card-options">
            {/* add button */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowFAQModal(true)}
            >
              <i className="fe fe-plus mr-2" />
              Add
            </button>
          </div>
        </div>

        {/* list of FAQs */}
        <div className="card-body">
          <table className="table table-hover table-striped table-vcenter">
            <tbody>
              {hasFAQ ? (
                faq.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {/* question */}
                      <div className="event-faq-container mb-1">
                        <b>Question: {parseHtml(item.question)}</b>
                      </div>

                      {/* answer */}
                      <div className="event-faq-container">
                        Answer: {parseHtml(item.answer)}
                      </div>
                    </td>

                    <td className="d-flex justify-content-end">
                      {/* edit icon */}
                      <i
                        className="fe fe-edit cursor-pointer"
                        onClick={() => onEditClick(item)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center mb-10">No FAQ Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* modal */}
      <FAQModal
        eventId={eventId}
        faq={selectedFAQ}
        isOpen={showFAQModal}
        onRequestClose={onModalClose}
      />
    </>
  );
};

export default ManageFAQForm;
