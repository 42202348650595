import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { userInfoHelper } from "lib/firebase";
import { capitalizeEachWord } from "utils/helpers";
import { allRoutes, roleRoute } from "routes";

const Dashboard = () => {
  // states
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [dashCards, setDashCards] = useState([]);

  // variables
  const DASH_CARDS = [
    {
      label: "Events",
      icon: "icon-calendar",
      to: allRoutes.events.index.path,
    },
    {
      label: "Callouts",
      icon: "icon-flag",
      to: allRoutes.callouts.index.path,
    },
    {
      label: "Coupons",
      icon: "icon-present",
      to: allRoutes.coupons.index.path,
    },
    {
      label: "Users",
      icon: "icon-user",
      to: allRoutes.users.index.path,
    },
    {
      label: "Notifications",
      icon: "icon-bell",
      to: allRoutes.notifications.index.path,
    },
  ];

  useEffect(() => {
    loadInitialInformation();
  }, []);

  const loadInitialInformation = async () => {
    try {
      // get user information
      const userInfo = await userInfoHelper();
      setUser({
        ...userInfo,
        firstName: capitalizeEachWord(userInfo.firstName),
        lastName: capitalizeEachWord(userInfo.lastName),
      });

      // get routes based on role
      const routes = [
        ...roleRoute[userInfo.role].routes,
        ...roleRoute.common.routes,
      ];

      // filter dash cards based on user role
      let dashCards = [];
      _.map(DASH_CARDS, (card) => {
        const isFound = _.findIndex(routes, ["path", card.to]);
        if (isFound !== -1) {
          // add card if index is not -1
          dashCards.push(card);
        }
      });

      setDashCards(dashCards);
      setLoading(false);
    } catch (error) {
      console.log("loadUserInfo ~ error", error);
      setLoading(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {/* welcome title */}
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="section-body mb-4 mt-3">
            <h4>Welcome {user.firstName}!</h4>
            <small>
              {`Manage all your tasks in this ${capitalizeEachWord(
                user.role
              )} portal.`}
            </small>
          </div>
        </div>
      </div>

      {/* list of dash cards */}
      <div className="row clearfix">
        {_.map(dashCards, (card, index) => (
          <div key={index} className="col-6 col-md-4 col-xl-2">
            <div className="card">
              <div className="card-body ribbon">
                <Link to={card.to} className="my_sort_cut text-muted">
                  <i className={card.icon} />
                  <span>{card.label}</span>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Dashboard;
