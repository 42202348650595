// import all auth related pages here
import Login from "pages/auth/Login";
import ForgotPassword from "pages/auth/ForgotPassword";

// import all dashboard related pages here
import Dashboard from "pages/dashboard";

// import all coupon related pages here
import Coupon from "pages/coupons";

// import all event related pages here
import Event from "pages/events";
import CreateEvent from "pages/events/CreateEvent";
import UpdateEvent from "pages/events/UpdateEvent";
import ChatEvent from "pages/events/ChatEvent";

// import all callout related pages here
import Callout from "pages/callouts";
import CalloutDetails from "pages/callouts/CalloutDetails";

// import all user related pages here
import User from "pages/users";
import UpdateUser from "pages/users/UpdateUser";

// import all notification related pages here
import Notification from "pages/notifications";

export const allRoutes = {
  // all auth routes
  auth: {
    login: {
      path: "/login",
      element: <Login />,
    },
    forgotPassword: {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
  },

  // all dashboard routes
  dashboard: {
    index: {
      path: "/dashboard",
      title: "Dashboard",
      element: <Dashboard />,
    },
  },
  // end of dashboard routes

  // all coupon routes
  coupons: {
    index: {
      path: "/coupons",
      title: "All Coupons",
      element: <Coupon />,
    },
    create: {
      path: "/coupons/create",
      title: "Coupon",
      element: <Coupon />,
    },
  },
  // end of coupon routes

  // all event routes
  events: {
    index: {
      path: "/events",
      title: "All Events",
      element: <Event />,
    },
    create: {
      path: "/events/create",
      title: "Create New Event",
      element: <CreateEvent />,
    },
    update: {
      path: "/events/update",
      title: "Manage an Event",
      element: <UpdateEvent />,
    },
    chat: {
      path: "/events/chat",
      title: "Manage Event Chats",
      element: <ChatEvent />,
    },
  },
  // end of event routes

  // all callout routes
  callouts: {
    index: {
      path: "/callouts",
      title: "All callouts",
      element: <Callout />,
    },
    view: {
      path: "/callouts/view",
      title: "Callout Details",
      element: <CalloutDetails />,
    },
  },
  // end of callout routes

  // all user routes
  users: {
    index: {
      path: "/users",
      title: "All Users",
      element: <User />,
    },
    create: {
      path: "/users/create",
      title: "User",
      element: <User />,
    },
    update: {
      path: "/users/update",
      title: "Manage an User",
      element: <UpdateUser />,
    },
  },
  // end of user routes

  // all notification routes
  notifications: {
    index: {
      path: "/notifications",
      title: "All Notifications",
      element: <Notification />,
    },
  },
  // end of notification routes
};

export const roleRoute = {
  superadmin: {
    routes: [
      // allRoutes.coupons.index,
      // allRoutes.coupons.create,
      allRoutes.events.index,
      allRoutes.events.create,
      allRoutes.events.update,
      allRoutes.events.chat,
      allRoutes.callouts.index,
      allRoutes.callouts.view,
      allRoutes.users.index,
      allRoutes.users.create,
      allRoutes.users.update,
      allRoutes.notifications.index,
    ],
  },
  admin: {
    routes: [
      // allRoutes.coupons.index,
      allRoutes.events.index,
      allRoutes.events.create,
      allRoutes.events.update,
      allRoutes.events.chat,
      allRoutes.callouts.index,
      allRoutes.callouts.view,
      allRoutes.users.index,
      allRoutes.users.update,
      allRoutes.notifications.index,
    ],
  },
  common: {
    routes: [allRoutes.dashboard.index],
  },
};
