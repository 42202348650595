import _ from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import verifiedIcon from "assets/icons/VerifiedShield.svg";
import unverifiedIcon from "assets/icons/UnverifiedShield.svg";
import Table from "components/tables/Table";
import { allRoutes } from "routes";
import { calculateAge, capitalizeEachWord } from "utils/helpers";
import { CREATED_DATE_FORMAT } from "utils/constants";

const UserTable = (props) => {
  // hooks
  const navigate = useNavigate();

  // variables
  const {
    loading,
    users,
    pageNumber = 1,
    pageLimit,
    onPreviousClick,
    onNextClick,
    hasPreviousPage,
    hasNextPage,
  } = props;
  const startOrderNumber = (pageNumber - 1) * pageLimit + 1;

  const onViewClick = (user) => {
    navigate(allRoutes.users.update.path, { state: { userId: user.id } });
  };

  if (users.length === 0 && loading) {
    // show loading
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="mt-20">Getting list of users...</div>
      </div>
    );
  }

  const renderBlockStatus = (user) => {
    if (_.isBoolean(user.block) && user.block) {
      return (
        <span className="text-danger">
          <b>Permanently blocked</b>
        </span>
      );
    } else if (_.isString(user.block)) {
      return (
        <span className="text-warning">
          <b>Temporary blocked</b>
        </span>
      );
    } else {
      return (
        <span className="text-success">
          <b>Active</b>
        </span>
      );
    }
  };

  return (
    <Table
      loading={loading}
      className="mb-30"
      pageNumber={pageNumber}
      showPagination={true}
      header={
        <tr>
          <th className="w40">#</th>
          <th className="w60"></th>
          <th>Full Name</th>
          <th>Email</th>
          <th className="text-center">Age</th>
          <th className="text-center">Role</th>
          <th className="text-center">Status</th>
          <th className="text-center">Created on</th>
          <th className="text-center">Action</th>
        </tr>
      }
      body={users.map((user, index) => {
        return (
          <tr
            key={user.id}
            className="cursor-pointer"
            onClick={() => onViewClick(user)}
          >
            {/* order number */}
            <td>{_.padStart(startOrderNumber + index, 2, "0")}</td>

            {/* user profile */}
            <td className="width45 text-center">
              {user.profileImage?.url ? (
                <img
                  src={user.profileImage?.url}
                  data-toggle="tooltip"
                  data-placement="top"
                  alt="Avatar"
                  className="avatar"
                  data-original-title="Avatar Name"
                />
              ) : (
                <span
                  className="avatar avatar-green text-uppercase"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="Avatar Name"
                >
                  {`${user.firstName[0]}${user.lastName[0]}`}
                </span>
              )}
            </td>

            {/* user name */}
            <td>
              <h6 className="mb-0 d-flex">
                {capitalizeEachWord(`${user.firstName} ${user.lastName}`)}
                <ReactSVG
                  className="ml-1"
                  src={user.verified ? verifiedIcon : unverifiedIcon}
                />
              </h6>
            </td>

            {/* email */}
            <td>
              {user.email ? (
                <>
                  <i className="fe fe-mail mr-2" />
                  <span>{user.email}</span>
                </>
              ) : null}
            </td>

            {/* dob */}
            <td className="text-center">
              {typeof user.dob === "string" ? (
                <span>{calculateAge(user.dob)}</span>
              ) : null}
            </td>

            {/* user role */}
            <td className="text-center text-capitalize">
              {user.role ? (
                <span className="tag tag-success">{user.role}</span>
              ) : null}
            </td>

            {/* account status */}
            <td className="text-center text-capitalize">
              {renderBlockStatus(user)}
            </td>

            {/* created on */}
            <td className="text-center">
              {user.createdAt
                ? moment(user.createdAt).format(CREATED_DATE_FORMAT)
                : null}
            </td>

            {/* action buttons */}
            <td className="text-center">
              <button
                className="btn text-uppercase text-info"
                onClick={() => onViewClick(user)}
              >
                <b>
                  <u>View</u>
                </b>
              </button>
            </td>
          </tr>
        );
      })}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
    />
  );
};

export default UserTable;

const styles = {
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3rem",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
  },
};
