import _ from "lodash";
import React from "react";

const Table = (props) => {
  // variables
  const {
    loading,
    className = "",
    pageNumber = 1,
    header,
    body,
    showPagination = false,
    onPreviousClick,
    onNextClick,
    hasPreviousPage,
    hasNextPage,
  } = props;

  const hasBodyContent = !_.isEmpty(body);
  const shouldShowPagination =
    showPagination && (hasNextPage || pageNumber > 1);

  return (
    <div className={`dimmer ${loading ? "active" : ""} ${className}`}>
      <div className="table-responsive dimmer-content">
        <table className="table table-vcenter table_custom spacing5 border-style mb-0">
          {/* table header */}
          {header ? <thead>{header}</thead> : null}

          {/* table body */}
          <tbody>{body}</tbody>
        </table>

        {!hasBodyContent ? (
          <div className="text-center mt-20">No Results Found</div>
        ) : null}
      </div>

      {/* pagination */}
      {shouldShowPagination ? (
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-end dimmer-content mt-10">
            {/* previous button */}
            <li className="page-item">
              <button
                className="page-link"
                disabled={!hasPreviousPage}
                onClick={onPreviousClick}
              >
                Previous
              </button>
            </li>

            {/* current page number */}
            <li className="page-item active">
              <button className="page-link">{pageNumber}</button>
            </li>

            {/* next button */}
            <li className="page-item">
              <button
                className="page-link"
                disabled={!hasNextPage}
                onClick={onNextClick}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export default Table;
